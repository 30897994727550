<template lang="pug">
  .details
    el-form(
      :model="form"
      ref="EditBikeForm"
      :rules="editBikeRules"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="9")
          el-form-item(
            prop="rfid"
            :label="$t('label.rfid')"
          )
            el-input(
              v-model="form.rfid"
              name="rfid"
            )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="9")
          el-form-item(
            prop="cost_center_id"
            :label="$t('common.state_of_charge')"
          )
            el-select(
              v-model='form.state_of_charge'
              filterable
              :placeholder="$t('placeholder.state_of_charge')"
            )
              el-option(
                v-for='state in states'
                :key='state'
                :label='state'
                :value='state'
              )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="9")
          el-form-item(
            prop="cost_center_id"
            :label="$t('common.bike_type')"
          )
            el-select(
              v-model='form.types'
              filterable
              :placeholder="$t('common.bike_type')"
            )
              el-option(
                v-for='type in types'
                :key='type'
                :label='$t("placeholder." + type)'
                :value='type'
              )
    el-row.text-center
      el-button.dark-blue-btn.pop-up-btn.mt-4(
        @click="handleEditBike('EditBikeForm')"
        :loading="loading"
      ) {{ $t('common.edit') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { bikesViewValidations } from "@utils/formValidations/bikesViewValidations";
import { customValidations } from "@utils/customValidators";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "ViewBikeEditDetails",
  mixins: [bikesViewValidations, customValidations],

  data() {
    return {
      loading: false,
      form: {
        rfid: "",
        state_of_charge: null,
        type: null
      },
      states: [
        'Fully Charged',
        'Medium Charge',
        'Low Battery'
      ],
      types: [
        'mechanical',
        'electrical',
      ],
    }
  },

  computed: {
    ...mapState("bike", ["bike"]),
  },

  created() {
    this.handleGetFormData()
  },

  methods: {
    ...mapActions('bike', ['updateBike']),
    ...mapMutations('bike', ['setShouldFetchBikes']),
    ...mapMutations('dialog', ['hideEditBikeDetailsDialog']),

    handleGetFormData() {
      this.form.rfid = this.bike.rfid;
    },

    handleEditBike(formName) {
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let payload = { ...this.form, id: this.bike.id };

          this.updateBike(payload)
              .then(() => {
                this.hideEditBikeDetailsDialog();
                this.clearForm();
                this.setShouldFetchBikes(true);
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.loading = false;
        }
      });
    },

    clearForm() {
      setObjectValues(this.form, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select{
  width: 100%;
}
</style>
