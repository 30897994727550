import messageUtils from "@utils/messageUtils";
import axios from "axios";

import Jsona from "jsona";
import i18n from "@i18n";
const dataFormatter = new Jsona();

export const state = {
  ticket: null,
  shouldFetchTickets: false
};

export const actions = {
  async getTickets({ commit }, payload) {
    try {
      const response = await axios.get(`/api/customer_cms/v1/tickets${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async getTicket({commit}, id){
    try {
      const response = await axios.get(`/v1/tickets/${id}`);
      return {
        data: dataFormatter.deserialize(response.data)
      };
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  // async updateTicketStatus({ commit }, payload) {
  //   try {
  //     await axios.patch(`/v1/tickets/${payload.id}`, {
  //       status: payload.status,
  //     });

  //     if (payload.status === 'in_progress') {
  //       return messageUtils.showSuccess(i18n.t("tickets.validations.start_work"));
  //     } else if (payload.status === 'resolved') {
  //       return messageUtils.showSuccess(i18n.t("tickets.validations.solved_ticket"));
  //     }
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },
};

export const mutations = {
  setTicket(state, ticket) {
    state.ticket = ticket;
  },

  setShouldFetchTickets(state, value) {
    state.shouldFetchTickets = value;
  },
};
