import i18n from "@i18n";

export const passwordValidationDeleteViewValidations = {
  data() {
    return {
      validation_rules: {
        password: [
          {
            required: true,
            message: i18n.t("common.enter_your_password"),
            trigger: "blur",
          },
        ],
      },
    };
  },
};
