import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress/nprogress";
import routes from "./routes";
import store from "@/src/state/store";
import { showItemDynamically } from "@/src/services/dynamicViews/general";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: "hash",
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  NProgress.start();

  const loggedIn = !!$cookies.get("access_token");
  const access_token = $cookies.get("access_token");
  const refresh_token = $cookies.get("refresh_token");

  if (loggedIn && !store.state.auth.user) {
    try {
      await store.dispatch("auth/sessionUser", { access_token, refresh_token });
    } catch (e) {
      await store.dispatch("auth/refreshToken");
      await store.dispatch("auth/sessionUser", {
        access_token: store.state.auth.access_token,
        refresh_token: store.state.auth.refresh_token,
      });
    }
  }

  // redirect from login/forgot-password if user is authenticated already
  if (
    loggedIn &&
    (routeTo.name === "login" ||
      routeTo.name === "forgot_password" ||
      routeTo.name === "change_password")
  ) {
    next("/docking_stations_map");
  }

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.requiresAuth);

  if (authRequired) {
    if (loggedIn) {
      // if (
      //     store.state.auth.user.selected_cost_center === null &&
      //     checkIfNotSuperAndAdministrator()
      // ) {
      //   let costCenterNames = store.state.auth.user.cost_center_names;

      //   if(!(costCenterNames.length === 1 ||  costCenterNames.every( (val, i, arr) => val === arr[0] ))){
      //     store.state.auth.user.selected_cost_center = costCenterNames[0];
      //     store.commit("dialog/showSwitchCostCenterDialog", {}, { root: true });
      //   }
      // }

      // if (routeTo.name !== "settings") {
        // if (
        //   !store.state.auth.user.password_changed &&
        //   checkIfNotSuperAndAdministrator()
        // ) {
          // next("/tickets");
          // messageUtils.showWarning(i18n.t("common.change_your_password"));
        // }

        // if (
        //   routeTo.meta.requiresSuperAdmin &&
        //   checkIfNotSuperAndAdministrator()
        // ) {
        //   return next("/tickets");
        // }

        // handleGuardsDynamicViews(routeTo, next);
      // }

      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

router.afterEach(NProgress.done);

/**
 * handles the show/hide components & sidebar items based on the data from the API
 */
function handleGuardsDynamicViews(to, next) {
  // handle edge case views
  if (to.meta.resource_name !== undefined) {
    switch (to.name) {
      case "docking_stations_map":
        return proceedToView(to, ["show_statistics"])
          ? next()
          : next("/tickets");

      case "user_edit":
        return proceedToView(to, ["update", "show"])
          ? next()
          : next("/docking_stations_map");

      case "user_view":
        return proceedToView(to, ["show"]) ? next() : next("/docking_stations_map");

      case "role":
      case "role_edit":
        return proceedToView(to, ["show"]) ? next() : next("/docking_stations_map");

      case "inventory":
        return proceedToView(to, ["create"]) ? next() : next("/docking_stations_map");
    }
    // normal views
    proceedToView(to, ["index"]) ? next() : next("/docking_stations_map");
  } else {
    return next();
  }
}

function proceedToView(to, requiredPermissions) {
  return showItemDynamically(store.state.auth.user, to.meta.resource_name, [
    ...requiredPermissions,
  ]);
}

export default router;
