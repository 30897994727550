export const docksViewValidations = {
  data() {
    return {
      addDockingStationRules: {
        name: [
          {
            required: true,
            message: this.$t("common.validations.dock_name"),
            trigger: "blur",
          },
        ],
        serial_number: [
          {
            required: true,
            validator: this.serialNumberValidator,
            trigger: "blur",
          },
        ],
        address_long: [
          {
            required: true,
            validator: this.longitudeValidator,
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: this.$t("docking_stations_list.validations.address"),
            trigger: "blur",
          },
        ],
        address_lat: [
          {
            required: true,
            validator: this.latitudeValidator,
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("docking_stations_list.validations.state"),
            trigger: "blur",
          },
        ],
        cost_center_id: [
          {
            required: true,
            message: this.$t("placeholder.enter_cost_center_id"),
            trigger: "blur",
          },
        ],
        rent_tariff_id: [
          {
            required: true,
            message: this.$t("common.price_plan"),
            trigger: "blur",
          },
        ],
        bays_count: [
          {
            required: true,
            message: this.$t("docking_stations_list.validations.bays_number"),
            trigger: "blur",
          },
        ],
      }
    };
  },
};
