<template lang="pug">
  .transactions-view
    h3 {{ $t('transactions.transactions') }}
    //- .filters.pr-3
    //-   FilterDatePicker(
    //-     v-model="dateRange"
    //-     @onChange="handleGetTransactionsStatistics()"
    //-   )
    //-   //- FilterCostCenterDropdown(
    //-   //-   v-model="selectedCostCenterId"
    //-   //-   @onChange="handleGetTransactionsStatistics()"
    //-   //- )
    //- //- .export-wrapper.d-flex
    //- //-   el-button.dark-blue-btn.export(
    //- //-     v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.TRANSACTION, ['export'])"
    //- //-     @click="handleExportToExcel"
    //- //-   )
    //-     h4 {{ $t('transactions.export') }}
    //- .rides-wrapper
    //-   .actions
    //-     SearchWithButtons(
    //-       v-model="search"
    //-     )
    //-   el-col(:span='24')
    //-     TransactionsViewTable(
    //-       v-loading="loading"
    //-       :transactions="transactions"
    //-     )
    //-     Pagination(
    //-       view="transactions"
    //-       :search="search"
    //-       :dateRange="dateRange"
    //-       @viewData="handleGetTransactions"
    //-       @handleTableLoading="handleLoading"
    //-     )
</template>

<script>
import { mapActions, mapState } from "vuex";
import { filterUtils } from "@utils/filterUtils";
import Statistic from "@components/_shared/Statistic";
import { routerUtils, } from "@utils/router";
import Pagination from "@components/_shared/Pagination";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import Chart from "@components/transactions/Chart";
import { computeDateRangeWithSelectedCostCenterId } from "@utils/generalUtils";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import TransactionsViewTable from "@components/transactions/TransactionsViewTable";

export default {
  mixins: [filterUtils, routerUtils, general],

  components: {
    Statistic,
    Pagination,
    FilterDatePicker,
    FilterCostCenterDropdown,
    SearchWithButtons,
    Chart,
    TransactionsViewTable
  },

  data() {
    return {
      search: "",
      dateRange: [],
      selectedCostCenterId: "",
      transactions: [],
      filteredTransactions: {},
      lastDayTransactions: {},
      loadingStatistics: false,
      loading: false,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.handleGetTransactionsStatistics();
  },

  methods: {
    ...mapActions("transaction", [
      "getTransactions",
      "getTransactionsStatistics",
      "exportTransactions",
    ]),

    handleGetTransactions(data) {
      this.transactions = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },

    async handleGetTransactionsStatistics() {

        this.loadingStatistics = true;


        const statistics = await this.getTransactionsStatistics('');
        this.filteredTransactions = statistics.filtered_transactions;
        this.lastDayTransactions = statistics.last_day_transactions;
        this.loadingStatistics = false;
      
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.export-wrapper {
  .export {
    height: 40px;
    margin: 20px 0 !important;
    width: 124px;
    border-radius: 20px;
    background: $gradient-start !important;
    box-shadow: none !important;

    h4 {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 13px;
    }
  }
}
</style>
