<template lang="pug">
  el-row
    el-col(:span="12")
      el-input(
        v-if="showSearch"
        :placeholder="$t('placeholder.search')"
        v-model="search"
        clearable
      )
    el-col.text-right(
      :span="showSearch ? 12 : 24"
    )
      div.d-flex.align-items-center.justify-content-flex-end
        slot
</template>

<script>
export default {
  name: "SearchWithButtons",

  props: {
    value: {
      type: String,
      default: "",
    },

    showSearch: {
      type: Boolean,
    }
  },

  computed: {
    search: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value.replace('%', String.fromCharCode(0xFF05)))
      },
    },
  },
};
</script>
