<template lang="pug">
  .tickets-view
    h3 {{ $t('smart_cards.smart_cards') }}
    .tickets-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
        )
          el-button.dark-blue-btn(
            @click="handleOpenAddSmartCardDialog"
          )
            h4 {{ $t('common.add') }}
      SmartCardsViewTable(
        v-loading="loading"
        :smartCards="smartCards"
      )
    Pagination(
      v-if="selectedStatus && selectedStatus !== ''"
      :view="'smart_cards'"
      :activeName="activeName"
      :costCenterId="selectedCostCenterId"
      @viewData="handleGetSmartCards"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import FilterStatus from "@components/_shared/FilterStatus";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import SmartCardsViewTable from "@components/cards/SmartCardsViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapMutations, mapState } from "vuex";
import FilterDatePicker from "@components/_shared/FilterDatePicker";

export default {
  mixins: [general],
  components: {
    Pagination,
    FilterCostCenterDropdown,
    SearchWithButtons,
    SmartCardsViewTable,
    FilterStatus,
    FilterDatePicker
  },

  data() {
    return {

      search: "",

      // filters
      dateRange: [],
      selectedCostCenterId: "",
      selectedStatus: "all",
      activeName: 'smart_cards',
      viewName: 'smart_cards',
      smartCards: [],
      loading: false,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {
    ...mapMutations("dialog", ["showAddSmartCardDialog"]),

    handleGetSmartCards(data) {
      this.loading = true;
      this.smartCards = data
      this.loading = false;
    },

    handleLoading(loading) {
      this.loading = loading
    },

    handleOpenAddSmartCardDialog() {
      this.showAddSmartCardDialog();
    },

  },
};
</script>

<style lang="scss" scoped>
.images {
  display: flex;
  align-items: center;

  .img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
  }

  .plus-icon {
    margin-left: 4px;
  }
}
</style>
