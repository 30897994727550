import axios from "axios";
import messageUtils from "@utils/messageUtils";
import Jsona from "jsona";
import i18n from "@i18n";

const dataFormatter = new Jsona();

export const state = {
  dockingStation: null,
  shouldFetchDocks: false,
  qrCode: null,
  shouldFetchDockSettings: false
};

export const actions = {
    async getDocks({ commit }, payload) {
      try {
        const response = await axios.get(`/api/customer_cms/v1/docking_stations${payload}`);
        return {
          data: dataFormatter.deserialize(response.data),
          headers: response.headers,
        };
      } catch (error) {
        messageUtils.showErrors(error);
        console.clear();
        return Promise.reject(error);
      }
    },

    // async getDockingStationSettings({ commit }, payload) {
    //   try {
    //     const response = await axios.get(`/v1/settings${payload.params}&by_docking_station_id=${payload.id}`);
    //     return {
    //       data: dataFormatter.deserialize(response.data),
    //       headers: response.headers,
    //     };
    //   } catch (error) {
    //     messageUtils.showErrors(error);
    //     return Promise.reject(error);
    //   }
    // },

  async getDock({ commit }, id) {
    try {
      const response = await axios.get(`/api/customer_cms/v1/docking_stations/${id}`);
      return dataFormatter.deserialize(response.data)
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  // async addDock({ commit }, payload) {
  //   try {
  //     await axios.post("/v1/docking_stations", {
  //       ...payload,
  //     });
  //     return messageUtils.showSuccess(i18n.t("docking_stations_list.success"));
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },

  // async updateDock({ commit }, payload) {
  //   try {
  //     await axios.patch(`/v1/docking_stations/${payload.id}`, {
  //       ...payload,
  //     });
  //     messageUtils.showSuccess(i18n.t("common.success"));
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },

  // async deleteDock({ commit }, payload) {
  //   try {
  //     await axios.delete(`/v1/docking_stations/${payload}`);
  //     messageUtils.showSuccess(i18n.t("common.success"));
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },
};

export const mutations = {
  setDockingStation(state, dockingStation) {
    state.dockingStation = dockingStation;
  },

  setShouldFetchDocks(state, value) {
    state.shouldFetchDocks = value;
  },

  setShouldFetchDockSettings(state, value) {
    state.shouldFetchDockSettings = value;
  },

  setQrCode(state, value) {
    state.qrCode = value;
  }
};
