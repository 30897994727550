<template lang="pug">
  #app
    SideBar(v-if="!hideSidebar")
    .router-wrapper
      RouterView(
        :key='$route.path'
        :class="{'container': !['login', 'forgot_password', 'change_password', 'change_email'].includes($route.name)}"
      )
    Dialogs
</template>

<script>
import SideBar from "@components/sidebar/SideBar";
import { sidebarBlacklist } from "@utils/router";
import Dialogs from "@components/dialogs/Dialogs";
import { mapActions, mapState } from "vuex";
import { sideMenuMixins } from "@src/mixins/sideMenu";

const checkSidebarVisibility = (currentRouteName) =>
    sidebarBlacklist.includes(currentRouteName);

export default {
  name: "App",
  mixins: [sideMenuMixins],

  components: {
    Dialogs,
    SideBar,
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      hideSidebar: true,
    };
  },

  watch: {
    $route(newVal) {
      this.hideSidebar = checkSidebarVisibility(newVal.name);
    },
  },

  updated() {
    this.toggleSideMenu(localStorage.getItem('isMenuOpen') === 'true')
  },

  methods: {
    ...mapActions("auth", ["sessionUser"]),
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  min-height: 100vh;
}

.router-wrapper {
  transition: 0.4s;
  width: 100%;
  overflow: hidden;
}
</style>
