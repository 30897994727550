import i18n from "@i18n";

// TODO: handle these when server is ready
export const transactionStatuses = {
  pending: {
    message: i18n.t("statuses.transaction.pending"),
    id: 1,
  },
  holding_money: {
    message: i18n.t("statuses.transaction.holding_money"),
    id: 2,
  },
  trying_transaction: {
    message: i18n.t("statuses.transaction.trying_transaction"),
    id: 3,
  },
  stripe_rate_limit_error: {
    message: i18n.t("statuses.transaction.stripe_rate_limit_error"),
    id: 4,
  },
  stripe_connection_error: {
    message: i18n.t("statuses.transaction.stripe_connection_error"),
    id: 5,
  },
  stripe_api_error: {
    message: i18n.t("statuses.transaction.stripe_api_error"),
    id: 6,
  },
  stripe_authentication_error: {
    message: i18n.t("statuses.transaction.stripe_authentication_error"),
    id: 7,
  },
  successfully_paid: {
    message: i18n.t("statuses.transaction.successfully_paid"),
    id: 21,
  },
  successfully_refunded: {
    message: i18n.t("statuses.transaction.successfully_refunded"),
    id: 22,
  },
  successfully_captured_funds: {
    message: i18n.t("statuses.transaction.successfully_captured_funds"),
    id: 23,
  },
  additional_charge_after_capture_funds_successful: {
    message: i18n.t(
      "statuses.transaction.additional_charge_after_capture_funds_successful"
    ),
    id: 24,
  },
  failed_because_of_server_error: {
    message: i18n.t("statuses.transaction.failed_because_of_server_error"),
    id: 41,
  },
  denied_from_mobilpay_system: {
    message: i18n.t("statuses.transaction.denied_from_mobilpay"),
    id: 42,
  },
  insufficient_funds_by_stripe: {
    message: i18n.t("statuses.transaction.insufficient_funds_by_stripe"),
    id: 43,
  },
  authentication_required_by_stripe: {
    message: i18n.t("statuses.transaction.authentication_required_by_stripe"),
    id: 44,
  },
  failed_because_of_invalid_arguments_or_stolen_card_by_stripe: {
    message: i18n.t(
      "statuses.transaction.failed_because_of_invalid_arguments_or_stolen_card_by_stripe"
    ),
    id: 45,
  },
  failed_unknown_reason_by_stripe: {
    message: i18n.t("statuses.transaction.failed_unknown_reason_by_stripe"),
    id: 46,
  },
  canceled_by_admin: {
    message: i18n.t("statuses.transaction.canceled_by_admin"),
    id: 61,
  },

  free_ride: {
    message: i18n.t("statuses.transaction.free_ride"),
    id: 62,
  },
  completed: {
    message: i18n.t("statuses.transaction.succeeded"),
    id: 63,
  },
  require_capture: {
    message: i18n.t("statuses.transaction.require_capture"),
    id: 64,
  },
  requires_confirmation: {
    message: i18n.t("statuses.transaction.requires_confirmation"),
    id: 65,
  },
  requires_action: {
    message: i18n.t("statuses.transaction.requires_action"),
    id: 66,
  },
  requires_capture: {
    message: i18n.t("statuses.transaction.requires_capture"),
    id: 67,
  },
  processing: {
    message: i18n.t("statuses.transaction.processing"),
    id: 68,
  },
  canceled: {
    message: i18n.t("statuses.transaction.canceled"),
    id: 69,
  },
  requires_payment_method: {
    message: i18n.t("statuses.transaction.requires_payment_method"),
    id: 70,
  },
  total_refunded: {
    message: i18n.t("statuses.transaction.total_refunded"),
    id: 71,
  },
  partial_refunded: {
    message: i18n.t("statuses.transaction.partial_refunded"),
    id: 72,
  },
};

// TODO: handle these when server is ready
export const transactionHistoryStatuses = {};

export const ticketsStatuses = {
  in_progress: {
    message: i18n.t("statuses.tickets.in_progress"),
    id: 1,
  },
  resolved: {
    message: i18n.t("statuses.tickets.solved"),
    id: 21,
  },
  unresolved: {
    message: i18n.t("statuses.tickets.unsolved"),
    id: 41,
  },
};

export const ticketsReports = {
  stolen_bike: {
    message: i18n.t("statuses.tickets.stolen_bike"),
    id: 0,
  },
  cannot_unlock_bike: {
    message: i18n.t("statuses.tickets.cant_unlock_bike"),
    id: 3,
  },
  payment_does_not_work: {
    message: i18n.t("statuses.tickets.payment_doesnt_work"),
    id: 7,
  },
  cannot_lock_bike: {
    message: i18n.t("statuses.tickets.cant_lock_bike"),
    id: 2,
  },

  // TODO: to update message
  error_from_station_docking_station_broken: {
    message: i18n.t("statuses.tickets.error_from_inside_station_docking"),
    id: 9,
  },
  broken_qr: {
    message: i18n.t("statuses.tickets.broken_qr"),
    id: 4,
  },
  transaction_error: {
    message: "Transaction Error",
    id: 17,
  },
  suggestions_and_feature_requests: {
    message: i18n.t("statuses.tickets.suggestion_and_features"),
    id: 8,
  },
  error_inconsisten_cost_center_in_db: {
    message: "error inconsistent cost center in db",
    id: 14,
  },
  problem_with_someone_elses_bike: {
    message: i18n.t("statuses.tickets.problem_with_someone_else_bike"),
    id: 6,
  },
  usb_connection_error: {
    message: "usb connection error",
    id: 16,
  },
  error_from_station_single_bay_problem: {
    message: i18n.t("statuses.tickets.error_from_inside_station_bay"),
    id: 10,
  },
  error_from_station_conflict_for_a_bike: {
    message: i18n.t("statuses.tickets.error_from_inside_station_bike_conflict"),
    id: 12,
  },
  error_inactive_station: {
    message: "error inactive station",
    id: 13,
  },
  error_from_station_multiple_bays_problems: {
    message: "error from station multiple bays problems",
    id: 3,
  },
  something_else_or_problem_with_ride: {
    message: i18n.t("statuses.tickets.something_else"),
    id: 5,
  },
  broken_bike: {
    message: i18n.t("statuses.tickets.broken_bike"),
    id: 1,
  },

  // 11: i18n.t("statuses.tickets.error_from_inside_station_multiple_bays"),
  // 12: i18n.t("statuses.tickets.error_from_inside_station_bike_conflict"),
};

export const ridesStatuses = {
  waiting_for_bike_to_be_unlocked: {
    message: i18n.t("statuses.rides.waiting_for_bike_to_be_unlocked"),
    id: 1,
  },
  bike_unlocked: {
    message: i18n.t("statuses.rides.bike_unlocked_waiting_extraction"),
    id: 2,
  },
  in_progress: {
    message: i18n.t("statuses.rides.in_progress"),
    id: 3,
  },
  requested_to_be_canceled: {
    message: i18n.t("statuses.rides.requested_to_be_canceled"),
    id: 4,
  },
  station_hardware_errors: {
    message: i18n.t("statuses.rides.station_hardware_errors"),
    id: 5,
  },
  holding_money_failed_due_to_3d_secure: {
    message: i18n.t("statuses.rides.holding_money_failed_due_to_3d_secure"),
    id: 6,
  },
  completed: {
    message: i18n.t("statuses.rides.completed"),
    id: 21,
  },
  ride_closed: {
    message: i18n.t("statuses.rides.ride_closed"),
    id: 41,
  },
  failed_unlocking_bike_because_of_hardware_error: {
    message: i18n.t("statuses.rides.failed_unlocking_bike"),
    id: 42,
  },
  failed_because_user_did_not_pick_up_bike: {
    message: i18n.t("statuses.rides.failed_user_did_not_pick_up_bike"),
    id: 43,
  },
  canceled_by_reporting_a_problem: {
    message: i18n.t("statuses.rides.cancelled_by_reporting_problem"),
    id: 44,
  },
  canceled_by_admin: {
    message: i18n.t("statuses.rides.cancelled_by_admin"),
    id: 45,
  },
  failed_because_there_is_no_bike_in_this_bay: {
    message: i18n.t("statuses.rides.failed_no_bike_in_this_bay"),
    id: 46,
  },
  failed_because_user_canceled_before_pick_up_bike: {
    message: i18n.t(
      "statuses.rides.failed_user_cancelled_ride_before_pick_bike"
    ),
    id: 47,
  },
  failed_because_bike_is_not_in_the_requested_bay: {
    message: i18n.t(
      "statuses.rides.failed_requested_bike_not_in_requested_bay"
    ),
    id: 48,
  },
  failed_because_station_is_not_responding: {
    message: i18n.t("statuses.rides.failed_station_is_unresponsive"),
    id: 49,
  },
  failed_because_bike_does_not_have_available_status: {
    message: i18n.t(
      "statuses.rides.failed_because_bike_does_not_have_available_status"
    ),
    id: 50,
  },
  failed_beacuse_no_bike_is_linked_to_dock: {
    message: i18n.t("statuses.rides.there_is_no_bike_linked_to_dock"),
    id: 51,
  },
  failed_because_bike_is_not_found_in_database: {
    message: i18n.t("statuses.rides.bike_not_found_in_db"),
    id: 52,
  },
  failed_because_the_bike_from_database_is_not_in_the_right_bay: {
    message: i18n.t("statuses.rides.bike_not_found_in_db"),
    id: 53,
  },
  failed_because_the_bay_does_not_exist: {
    message: i18n.t("statuses.rides.bay_does_not_exist"),
    id: 54,
  },
  ride_canceled_by_db_conflict: {
    message: i18n.t("statuses.rides.ride_canceled_by_db_conflict"),
    id: 55,
  },
  station_is_not_available: {
    message: i18n.t("statuses.rides.ride_canceled_by_db_conflict"),
    id: 56,
  },
  manually_closed_by_operator_with_no_charge: {
    message: i18n.t(
      "statuses.rides.manually_closed_by_operator_with_no_charge"
    ),
    id: 57,
  },
  ride_not_accepted_by_station: {
    message: i18n.t("statuses.rides.ride_not_accepted_by_station"),
    id: 58,
  },
  failed_due_to_payment_error: {
    message: i18n.t("statuses.rides.failed_due_to_payment_error"),
    id: 59,
  },
};

export const flaggedRidesStatuses = {
  21: i18n.t("statuses.flaggedRidesStatuses.alright_no_problems"),
  41: i18n.t("statuses.flaggedRidesStatuses.reported_stolen"),
  42: i18n.t("statuses.flaggedRidesStatuses.reported_broken"),
  43: i18n.t("statuses.flaggedRidesStatuses.reported_other"),
  44: i18n.t("statuses.flaggedRidesStatuses.payment_not_successful"),
  45: i18n.t("statuses.flaggedRidesStatuses.user_stopped_location_on_purpose"),
  46: i18n.t("statuses.flaggedRidesStatuses.rented_for_more_than_7_days"),
};

export const bikesStatuses = {
  unavailable: {
    message: i18n.t("statuses.bikes.unavailable"),
    id: 1,
  },
  under_maintenance: {
    message: i18n.t("statuses.bikes.under_maintenance"),
    id: 2,
  },
  available_in_station: {
    message: i18n.t("statuses.common.available"),
    id: 21,
  },
  in_ride: {
    message: i18n.t("statuses.bikes.in_ride"),
    id: 22,
  },
  reported_stolen_and_missing: {
    message: i18n.t("statuses.bikes.reported_stolen"),
    id: 41,
  },
  previously_stolen_but_now_returned: {
    message: i18n.t("statuses.bikes.stolen_returned_to_station"),
    id: 42,
  },
  reported_missing: {
    message: i18n.t("statuses.bikes.reported_missing"),
    id: 43,
  },
  previously_missing_but_now_returned: {
    message: i18n.t("statuses.bikes.missing_returned_to_station"),
    id: 44,
  },
  reported_broken_but_not_returned: {
    message: i18n.t("statuses.bikes.reported_broken_not_returned"),
    id: 45,
  },
  reported_broken_but_now_returned: {
    message: i18n.t("statuses.bikes.broken_returned"),
    id: 46,
  },
  reported_other: {
    message: i18n.t("statuses.bikes.reported_other"),
    id: 47,
  },
  missing_as_result_of_admin_closing_a_ride: {
    message: i18n.t("statuses.bikes.missing_admin_closed_ride"),
    id: 48,
  },
  returned_as_result_of_admin_closing_a_ride: {
    message: i18n.t("statuses.bikes.bike_returned_previously_missing"),
    id: 49,
  },
  returned_to_station_but_having_a_conflict: {
    message: i18n.t(
      "statuses.bikes.bike_returned_to_station_conflict_in_system"
    ),
    id: 50,
  },
  bike_in_a_defect_bay: {
    message: i18n.t("statuses.bikes.bike_being_in_defected_bay"),
    id: 51,
  },
  to_be_activated: {
    message: i18n.t("statuses.bikes.to_be_activated"),
    id: 61,
  },
  deleted_out_of_the_system: {
    message: i18n.t("statuses.bikes.deleted_get_out_of_system"),
    id: 81,
  },
};

export const bikesMaintenanceStatuses = {
  completed: {
    message: i18n.t("statuses.bikes_maintenance.completed"),
    id: 1,
  },
  partial_rideable: {
    message: i18n.t("statuses.bikes_maintenance.partial_rideable"),
    id: 21,
  },
  partial_non_rideable: {
    message: i18n.t("statuses.bikes_maintenance.partial_non_rideable"),
    id: 41,
  },
};

export const dockingStationsStatuses = {
  under_maintenance: {
    message: i18n.t("common.under_maintenance"),
    id: 1,
  },

  working: {
    message: i18n.t("common.available_m"),
    id: 21,
  },

  not_responding_by_station: {
    message: i18n.t("common.broken"),
    id: 41,
  },

  2: i18n.t("statuses.docking_stations.under_initial_configuration"),
  41: i18n.t("statuses.docking_stations.not_working_responding"),
  42: i18n.t("statuses.docking_stations.not_responding_set_by_server"),
  43: i18n.t("statuses.docking_stations.determines_app_restart"),
};

export const usersAndCustomersStatuses = {
  active: {
    message: i18n.t("statuses.users_and_customers.active"),
    id: 21,
  },

  inactive: {
    message: i18n.t("statuses.users_and_customers.inactive"),
    id: 41,
  },

  deleted: {
    message: i18n.t("statuses.users_and_customers.deleted"),
    id: 61,
  },
};

export const stationCommandsStatuses = {
  1: i18n.t("statuses.stationCommands.waiting_to_be_executed"),
  21: i18n.t("statuses.stationCommands.command_success"),
  22: i18n.t("statuses.stationCommands.command_executed_but_not_took"),
  41: i18n.t("statuses.stationCommands.command_entered_timeout"),
  42: i18n.t("statuses.stationCommands.given_command_not_found_in_rfid"),
  43: i18n.t("statuses.stationCommands.command_given_to_inexistent_bay"),
  44: i18n.t("statuses.stationCommands.command_warns_bike_not_found_local_db"),
  45: i18n.t("statuses.stationCommands.command_warns_bike_not_found_db"),
  46: i18n.t("statuses.stationCommands.command_warns_automatic_mode"),
  47: i18n.t("statuses.stationCommands.command_warns_bike_not_detected"),
  49: i18n.t("statuses.stationCommands.station_not_responding"),
};
