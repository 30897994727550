<template lang="pug">
  .docking-stations-map
    h3 {{ $t('docking_stations_map.docking_stations_map') }}
    .statistics.d-flex
    DockingStationsMap(
    )
</template>

<script>
import DockingStationsMap from "@components/docking_stations/DockingStationsMap.vue";
import { mapActions, mapState } from "vuex";
import Statistic from "@components/docking_stations/Statistic.vue";
import VueApexCharts from 'vue-apexcharts'
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";

export default {
  mixins: [general],

  components: {
    DockingStationsMap,
    Statistic,
    apexchart: VueApexCharts,
  },

  data() {
    return {
      ridesInProgress: 0,
      availableBikes: 0,
      nrOfClients: 0,
      docks: [],
      workingDocks: [],
      underMaintenanceDocks: [],

      // apex settings
      loadedChartInfo: true,
      series: [0, 0],
      chartOptions: {
        chart: {
          width: 280,
          type: 'pie',
          selection: {
            show: false
          }
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          show: false,
        },
        fill: {
          colors: ['#266fca', '#e41e26']
        },
        legend: {
          show: false
        },
        labels: ['working','unavailable'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'center'
            }
          }
        }]
      },

      dynamicViewsConstants
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

};
</script>

<style lang="scss" scoped>
@import "@variables";

.docking-stations-chart {
  align-items: center;
}

.docking-stations-map {
  .custom-legend {
    flex-direction: column;
    min-width: 130px;

    img {
      height: 20px;
    }

    span {
      font-size: 14px;
    }
  }

  .statistics {
    margin: 24px 0;

    .statistic {
      align-items: center;
      width: 244px;
      padding: 18px 25px;
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      margin-right: 24px;
    }
  }
}
</style>
