<template lang="pug">
  el-pagination.text-center.pt-3(
    layout="prev, pager, next"
    :current-page.sync="currentPage"
    :total="totalCount"
    :page-size.sync="pageItems"
    v-if="totalCount > 1"
    @current-change="handleGetData"
  )
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DialogTablePagination",

  props: {
    activeName: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState("bike", ["bike"]),
  },

  data() {
    return {
      currentPage: 1,
      totalCount: 1,
      pageItems: 1,
      handleGetDataLoading: false,
    };
  },

  mounted() {
    this.handleGetData();
  },

  watch: {
    activeName() {
      if (this.activeName === "maintenance_history" || this.activeName === 'bike_history') {
        this.handleGetData();
      }
    },

    bike() {
      this.handleGetData();
    },

    handleGetDataLoading() {
      this.$emit("handleTableLoading", this.handleGetDataLoading);
    },
  },

  methods: {
    ...mapActions("bike", ["getBikeMaintenanceHistory", "getBikeRidesHistory"]),

    async handleGetData() {
      this.handleGetDataLoading = true;
      let params = {
        id: this.bike.id,
        page: this.currentPage,
      };
      const response = await this.computeGetAction(params)
      this.emitDataToView(response);
      this.handleGetDataLoading = false;
    },

    async computeGetAction(params) {
      if (this.activeName === 'maintenance_history') {
        return await this.getBikeMaintenanceHistory(params);
      } else if (this.activeName === 'bike_history') {
        return await this.getBikeRidesHistory(params);
      }
    },

    emitDataToView(response) {
      this.totalCount = Number(response.headers["total-count"]);
      this.pageItems = Number(response.headers["page-items"]);
      this.$emit("viewData", response.data);
    },
  },
};
</script>
