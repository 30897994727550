<template lang="pug">
  .add-user
    el-form(
      :model="form"
      ref="AddUserForm"
      :rules="addUserFormRules"
      @submit.native.prevent
    )
      .tab-view
        el-tabs.mt-4(
          v-model='activeName'
          center style="margin: 16px 0; text-align:center;"
        )
          el-tab-pane(
            :label="$t('label.new_user')"
            name='newUser'
          )
          el-tab-pane(
            :label="$t('label.existing_user')"
            name='existingUser'
          )
      el-row(:gutter="20")
        el-col(
          :span="12"
          v-if="activeName === 'newUser'"
        )
          el-form-item(
            prop="first_name"
            :label="$t('label.first_name')"
          )
            el-input(
              v-model="form.first_name"
              name="first_name"
            )
        el-col(
          :span="12"
          v-if="activeName === 'newUser'"
        )
          el-form-item(
            prop="last_name"
            :label="$t('label.last_name')"
          )
            el-input(
              v-model="form.last_name"
              name="last_name"
            )
        el-col(:span="12")
          el-form-item(
            prop="email"
            :label="$t('common.email_address')"
          )
            el-input(
              v-model="form.email"
              name="email"
            )
        el-col(:span="12")
          el-form-item(
            prop="selectedTabRole"
            :label="$t('label.role')"
          )
            el-input(
              v-model="selectedTabRole"
              name="selectedTabRole"
              disabled
            )
        el-col(
          :span="12"
          v-if="role === roles.ADMINISTRATOR_OPERATOR"
        )
          el-form-item(
            prop="franchise_id"
            :label="$t('common.cost_center')"
          )
            el-select.w-100(
              v-model='form.franchise_id'
              filterable
              allow-create
              :placeholder="$t('common.cost_center')"
            )
              el-option(
                v-for='cost_center in filteredCostCentersForDropdown'
                :key='cost_center.id'
                :label='cost_center.name'
                :value='cost_center.id'
              )
    el-row.text-center
      el-button.dark-blue-btn.mt-5.pop-up-btn(
        @click="handleAddNewUser('AddUserForm')"
        :loading="loading"
      ) {{ $t('dialogs.common.create') }}
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { roles } from "@utils/roles";
import { usersViewValidations } from "@utils/formValidations/usersViewValidations";
import { customValidations } from "@utils/customValidators";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "AddUser",
  mixins: [usersViewValidations, customValidations],

  data() {
    return {
      cost_centers: [],
      activeName: "newUser",
      form: {
        first_name: null,
        last_name: null,
        email: null,
        franchise_id: null,
      },
      loading: false,
      roles,
      setObjectValues,
    };
  },

  watch: {
    activeName() {
      if (this.activeName === "existingUser") {
        this.setObjectValues(this.form, "");
      }
    },
  },

  computed: {
    ...mapState("user", ["selectedTabRole"]),

    ...mapGetters({
      role: "auth/role",
    }),
  },

  methods: {
    ...mapMutations("dialog", ["hideAddNewUserDialog"]),

    ...mapActions({
      signup: "user/signup",
    }),

    handleAddNewUser(formName) {
      this.loading = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          const payload = { ...this.form };
          payload.role_name = this.selectedTabRole;
          this.signup(payload)
            .then(() => {
              this.hideAddNewUserDialog();
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
