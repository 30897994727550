<template lang="pug">
  .info-box-statistic(
    :class="{'full-width': fullWidth, 'fit-content': this.$slots.content}"
    :style="{'justify-content': justifyContent}"
  )
    .info-box-wrapper.d-flex.justify-space-between.w-100
      div.d-flex.align-items-center
        img(
          v-if="icon_name"
          :src="require('@assets/_shared/'+ icon_name +'.svg')"
        )
        .statistics-wrapper.ml-3
          h4.mb-2(:class="icon_name ? 'statistic-header-small' : 'statistic-header-bold'") {{ name }}
          // TODO: handle dinamically the valute
          span.transaction-tariff-ammount(v-if="value && !isMoneyStatistic") {{ value }}
          span.transaction-tariff-ammount(v-if="value && isMoneyStatistic") {{ $n(value, 'currency') }}
          span(v-if="!value") -
      slot(name="content")
</template>

<script>
export default {
  name: "Statistic",
  props: {
    name: {
      type: String,
    },
    value: {
      type: Number | String,
    },
    icon_name: {
      type: String,
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    isMoneyStatistic: {
      type: Boolean,
    },
    justifyContent: {
      type: String,
      default: 'center'
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.statistic-header-small {
  opacity: 0.5;
  color: #061058;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.77px;
  line-height: 22px;
}

.statistic-header-bold {
  color: $dark-blue;
  font-size: 20px !important;
  font-weight: 600;
  letter-spacing: -0.63px;
  line-height: 27px;
  font-family: $avenir-next-bold;
}

.info-box-statistic {
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: 300px;
  background: $white;
  border-radius: 20px;

  margin: 0 20px;
}

.full-width {
  width: 100% !important;
}

.fit-content {
  width: fit-content !important;
}
</style>
