<template lang="pug">
  .rides-view
    h3 {{ $t('rides.rides') }}
    .filters.pr-3
      // filter by date range
      FilterDatePicker(
        v-model="dateRange"
      )

      // filter by status
      FilterStatus(
        :name="$t('common.status_filter')"
        :statuses="ridesStatuses"
        v-model="selectedStatus"
      )
      //- FilterCostCenterDropdown(v-model="selectedCostCenterId")
    .rides-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
        )
      RidesViewTable(
        v-loading="loading"
        :rides="rides"
        :selectedStatus="selectedStatus"
      )
    // TODO: change :activeName to selectedStatus
    Pagination(
      v-if="selectedStatus && selectedStatus !== ''"
      :view="'rides'"
      :activeName="selectedStatus"
      :search="search"
      :dateRange="dateRange"
      @viewData="handleGetRides"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import { filterUtils } from "@utils/filterUtils";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { dateRangeShortcuts, disabledFutureDates } from "@utils/dateRange";
import { routerUtils } from "@utils/router";
import Pagination from "@components/_shared/Pagination";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import RidesViewTable from "@components/rides/RidesViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";
import FilterStatus from "@components/_shared/FilterStatus";

export default {
  mixins: [filterUtils, routerUtils, general],
  components: {
    SearchWithButtons,
    Pagination,
    FilterDatePicker,
    FilterCostCenterDropdown,
    RidesViewTable,
    FilterStatus,
  },

  data() {
    return {
      selectedStatus: "all",
      ridesStatuses: [
        { name: "All", value: "all" },
        { name: "Initiated Rides", value: "waiting_for_bike_to_be_unlocked" },
        { name: "In Progress", value: "in_progress" },
        { name: "Flagged", value: "flagged" },
        { name: "Completed", value: "completed" },
      ],
      search: "",
      dateRange: [],
      selectedCostCenterId: "",
      rides: [],
      pickerOptions: {
        disabledDate(time) {
          return disabledFutureDates(time);
        },
        shortcuts: dateRangeShortcuts,
      },
      loading: false,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    handleGetRides(data) {
      this.rides = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>

<style lang="scss" scoped>
.docks {
  span {
    display: block;
  }
}

.images {
  display: flex;
  align-items: center;

  .img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
  }

  .plus-icon {
    margin-left: 4px;
  }
}
</style>
