export const logsViewValidations = {
  data() {
    return {
      setLogsSizeRules: {
        insert_reserved_log_size: [
          {
            required: true,
            validator: this.logsSizeValidator,
            trigger: "blur",
          }
        ]
      },
    };
  },
};
