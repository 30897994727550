<template lang="pug">
  .details
    el-form(
      :model="form"
      ref="editConfigurableSettingsForm"
      :rules="editConfigurableSettings"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="16")
          el-form-item(
            prop="value"
            :label="$t('label.value')"
          )
            el-input(
              v-model="form.value"
              name="value"
            )
    el-row.text-center
      el-button.dark-blue-btn.pop-up-btn.mt-4(
        @click="handleEditConfSetting('editConfigurableSettingsForm')"
        :loading="loading"
      ) {{ $t('common.edit') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ViewEditConfigurableSettings",

  data() {
    return {
      loading: false,
      form: {
        value: "",
      },
      editConfigurableSettings: {
        value: [
          {
            required: true
          }
        ]
      }
    }
  },

  computed: {
    ...mapState("configSettings", ['configSetting']),
  },

  created() {
    this.handleGetFormData()
  },

  methods: {
    ...mapActions("configSettings", ['updateConfSettings']),
    ...mapMutations('dialog', ['hideEditConfSettingsDialog']),
    ...mapMutations('configSettings', ['setShouldFetchConfSettings']),
    ...mapMutations('dockingStation', ['setShouldFetchDockSettings']),
    ...mapMutations('costCenter', ['setShouldFetchCostCenterSettings']),

    handleGetFormData() {
      this.form = { ...this.configSetting }
    },

    handleEditConfSetting(formName) {
      const form = this.$refs[formName];
      this.loading = true;

      this.updateConfSettings(this.form)
          .then(() => {
            this.hideEditConfSettingsDialog();
            this.setShouldFetchConfSettings(true);
            this.setShouldFetchDockSettings(true);
            this.setShouldFetchCostCenterSettings(true);
          })
          .finally(() => {
            this.loading = false;
          });
    }
  },
};
</script>
