<template lang="pug">
  el-form(
    :model="form"
    ref="EditPasswordSettings"
    :rules="passwordFormRules"
    @submit.native.prevent
  )
    h3.mb-3.mt-5 {{ $t('settings.change_password') }}
    el-row.responsive(:gutter="20")
      el-col(:span="6")
        el-form-item(
          prop="password"
          :label="$t('settings.form.new_password')"
        )
          el-input(
            v-model="form.password"
            type="password"
            name="password"
          )
      el-col(:span="6")
        el-form-item(
          prop="password_confirmation"
          :label="$t('settings.form.confirm_password')"
        )
          el-input(
            v-model="form.password_confirmation"
            type="password"
            name="password_confirmation"
          )
    el-button.dark-blue-btn.mt-4.pop-up-btn.mb-5(
      @click="handleUpdatePasswordSettings('EditPasswordSettings')"
      :loading="loading"
    ) {{ $t('settings.update_password_settings') }}
</template>

<script>
import { mapActions, mapState } from "vuex";
import { settingsViewValidations } from "@/src/utils/formValidations/settingsViewValidations";
import { customValidations } from "@utils/customValidators";

export default {
  name: "ProfileChangeForm",
  mixins: [settingsViewValidations, customValidations],

  data() {
    return {
      form: {
        password: null,
        password_confirmation: null,
      },
      loading: false,
    };
  },

  computed: {
    ...mapState("auth", ["access_token", "user"]),
  },

  methods: {
    ...mapActions({
      changePassword: "user/changePassword",
      updateUserProfile: "auth/updateUserProfile",
      generateChangeEmailLink: "user/generateChangeEmailLink",
      updateProfile: "user/updateProfile",
      logout: 'auth/logout'
    }),

    handleUpdatePasswordSettings(formName) {
      this.loading = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let payload = { ...this.form };

          this.updateProfile(payload).finally(async () => {
            await this.logout()
            this.$router.push('/login')
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .responsive {
    display: flex;
    flex-direction: column !important;
    overflow: scroll;

    .el-col {
      width: 100%;
    }
  }
}
</style>