export const rentTariffViewValidations = {
  data() {
    return {
      addRentTariffRules: {
        name: [
          {
            required: true,
            message: this.$t("common.validations.name"),
            trigger: "blur",
          },
        ],
        initial_fee: [
          {
            required: true,
            message: this.$t("rent_tariffs.validations.fee"),
            trigger: "blur",
          },
        ],
        initial_timeblock: [
          {
            required: true,
            message: this.$t("rent_tariffs.validations.time_block"),
            trigger: "blur",
          },
        ],
        secondary_fee: [
          {
            required: true,
            message: this.$t("rent_tariffs.validations.fee"),
            trigger: "blur",
          },
        ],
        secondary_timeblock: [
          {
            required: true,
            message: this.$t("rent_tariffs.validations.time_block"),
            trigger: "blur",
          },
        ],
      }
    };
  },
};
