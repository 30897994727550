<template lang="pug">
  .set-logs-size
    el-form(
      :model="form"
      ref="SetLogsSizeForm"
      :rules="setLogsSizeRules"
      @submit.native.prevent
    )
      el-row(:gutter="20").d-flex.justify-content-center
        el-col(:span="14")
          el-form-item(
            prop="max_log_size"
            :label="$t('label.select_total_log_size')"
          )
            el-select.w-100(
              v-model='form.max_log_size'
              @change="default_max_logs_size = $event"
              filterable
              :placeholder="$t('placeholder.select_cost_center')"
            )
              el-option(
                v-for='cost_center in logs_sizes'
                :key='cost_center.id'
                :label='cost_center.label'
                :value='cost_center.value'
              )
        el-col(:span="6").align-self-end
          el-form-item(
            prop="insert_reserved_log_size_unit"
            :label="''"
          )
            el-select(
              v-model='form.max_log_size_unit'
              @change="handleLogsSizeUnits"
            )
              el-option(
                v-for='type in volume_types'
                :key='type.id'
                :label='type.value'
                :value='type.value'
              )
      el-row(:gutter="20").d-flex.justify-content-center
        el-col(:span="14")
          el-form-item(
            prop="insert_reserved_log_size"
            :label="$t('label.insert_log_size')"
          )
            el-input-number(
              v-model.number="form.insert_reserved_log_size"
              :controls="false"
              :max="computeMaxInsertSize"
              name="insert_reserved_log_size"
            )
        el-col(:span="6").align-self-end
          el-form-item(
            prop="insert_reserved_log_size_unit"
            :label="''"
          )
            el-select(
              v-model='form.insert_reserved_log_size_unit'
            )
              el-option(
                v-for='type in volume_types'
                :key='type.id'
                :label='type.value'
                :value='type.value'
              )
      el-row(:gutter="20").d-flex.justify-content-center
        el-col(:span="20")
          div {{ $t('logs.free_available_log_size') }}
          div {{ computeFreeAvailableLogsSize(this.form.max_log_size_unit, this.form.insert_reserved_log_size_unit, this.form.insert_reserved_log_size, this.form.max_log_size) }}
      el-row.text-center.pt-3
        el-button.dark-blue-btn.pop-up-btn(
          @click="handleSetLogsSize('SetLogsSizeForm')"
          :loading="loading"
        ) {{ $t('common.save_changes') }}
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { customValidations } from "@utils/customValidators";
import { filterUtils } from "@utils/filterUtils";
import { logsViewValidations } from "@utils/formValidations/logsViewValidations";
import { computeFreeAvailableLogsSize } from '@utils/logs'
import _ from 'lodash'

export default {
  name: "SetLogsSize",
  mixins: [logsViewValidations, customValidations, filterUtils],

  data() {
    return {
      logs_sizes: [
        {id: 1, value: 6, label: "6 GB"},
        {id: 2, value: 10, label: "10 GB"},
        {id: 3, value: 14, label: "14 GB"}
      ],
      volume_types: [
        {id: 1, value: "MB"},
        {id: 2, value: "GB"}
      ],

      default_log_sizes: [],
      form: {
        max_log_size: 6,
        max_log_size_unit: 'MB',
        insert_reserved_log_size: null,
        insert_reserved_log_size_unit: "MB"
      },
      loading: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("log", ["logsSettings"]),

    ...mapGetters("log", ['formattedLogsSettings']),

    computeMaxInsertSize() {
      return this.form.insert_reserved_log_size_unit === 'MB' ? this.form.max_log_size * 1000 : this.form.max_log_size
    },
  },

  created() {
    this.handleGetFormData()
    this.default_log_sizes = _.cloneDeep(this.logs_sizes)
  },

  methods: {
    computeFreeAvailableLogsSize,

    ...mapActions("log", ['updateLogsSettings']),

    ...mapMutations("dialog", ["hideSetLogsSizeDialog"]),

    ...mapMutations("log", ["setShouldFetchStatistics"]),

    clearForm() {
      this.setObjectValues(this.form, null);
    },

    handleGetFormData() {
      this.form = {...this.formattedLogsSettings}
    },

    handleSetLogsSize(formName) {
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.updateLogsSettings({...this.form})
              .then(() => {
                this.setShouldFetchStatistics(true)
                this.hideSetLogsSizeDialog()
              })
              .finally(() => {
                this.loading = false
              })
        }
      });
    },

    handleLogsSizeUnits() {
      this.logs_sizes.forEach((log, i) => {
        if (this.form.max_log_size_unit === 'MB') {
          log.value = this.default_log_sizes[i].value * 1000
          log.label = this.default_log_sizes[i].value * 1000 + ' MB'

          if ((log.value / 1000) === (this.form.max_log_size)) {
            this.form.max_log_size = log.value
          }
        } else if (this.form.max_log_size_unit === 'GB') {
          log.value = this.default_log_sizes[i].value
          log.label = this.default_log_sizes[i].label

          if ((log.value * 1000) === (this.form.max_log_size)) {
            this.form.max_log_size = log.value
          }
        }
      })
    }
  }
  ,
}

</script>

<style lang="scss" scoped>
</style>
