<template lang="pug">
  .login-view
    .form
      .background-wrapper
        img.header-img(src="@assets/auth/header.svg")
        el-row.d-flex.justify-content-center
          el-col(:span="16")
            LoginComponent(v-if="loginEnabled == true")
            SignUpComponent(v-if="loginEnabled == false")
            br
            el-row.text-right
              a(v-if="loginEnabled == false"
                @click="handleLoginButton") {{ $t('auth.use_login') }}
            el-row.text-right
              a(v-if="loginEnabled == true"
                @click="handleSignUpButton") {{ $t('auth.use_signup') }}
            br

            el-row.text-center(v-if="loginEnabled == true") {{ 'sau' }}

            div.signup-buttons(v-if="loginEnabled == true")
              GoogleLoginComponent
              FacebookLoginComponent

            //- div.text-center.mt-5 {{ 'Version: ' + versionText }}

    .background
</template>

<script>
import GoogleLoginComponent from "@components/auth/GoogleLoginComponent";
import FacebookLoginComponent from "@components/auth/FacebookLoginComponent";
import SignUpComponent from "@components/auth/SignUpComponent";
import LoginComponent from "@components/auth/LoginComponent";
import { setObjectValues } from "@utils/generalUtils";
import { mapActions } from "vuex";
import { authenticationViewValidations } from "@utils/formValidations/authenticationViewValidations";
import { customValidations } from "@utils/customValidators";

export default {
  name: "LoginView",
  mixins: [authenticationViewValidations, customValidations],
    components: {
    GoogleLoginComponent,
    FacebookLoginComponent,
    SignUpComponent,
    LoginComponent
  },

  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      },
      // formSignUp: {
      //   first_name: "",
      //   last_name: "",
      //   email_s: "",
      //   password_s: "",
      // },
      loading: false,
      loginEnabled: true,
      setObjectValues
    };
  },

  computed: {
    disabledButton() {
      return this.form.email === "" || this.form.password === "";
    },

    disabledSignUpButton() {
      return this.form.email === "" || this.form.password === "";
    },

    versionText() {
      return process.env.VUE_APP_VERSION;
    }
  },

  methods: {
    ...mapActions({
      login: "auth/login",
    }),

    handleSignUpButton(){
      this.clearForm()
      this.loginEnabled = false;
      
    },

    handleLoginButton(){
      this.clearForm()
      this.loginEnabled = true;
    },



    goToForgotPassword() {
      this.$router.push({ name: "forgot_password" });
    },

    clearForm() {
      this.setObjectValues(this.form, "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.login-view {
  display: flex;

  a {
    color: $dark-blue;
    font-family: "Avenir Next", sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
      flex-direction: column;
      margin-top: 100px;
  }
}

.login-choice span:after, .login-choice:before  {
    content: "";
    border-top: 1px solid #e5e8ed;
}
.signup-buttons {
    margin-top: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
}
</style>
