<template lang="pug">
  .add-bike
    AddBillingAddress(
      v-if="!initCardForm"
      @emitAddCard="handleGetCards"
    )
    AddCardInfo(
      v-if="initCardForm"
    )
</template>

<script>
import AddBillingAddress from "./card/AddBillingAddress";
import AddCardInfo from "./card/AddCardInfo";
import { StripeElementCard  } from '@vue-stripe/vue-stripe';
import { mapActions, mapMutations } from "vuex";
import { bikesViewValidations } from "@utils/formValidations/bikesViewValidations";
import { customValidations } from "@utils/customValidators";
import { filterUtils } from "@utils/filterUtils";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "AddCard",
  mixins: [bikesViewValidations, customValidations, filterUtils],

  data() {
    return {
      initCardForm: false,
    
      stripe: '',
      elements: '',
      card: '',

      intentToken: null,
      token: null,
      cost_centers: [],
      form: {
        serial_number: "",
        rfid: "",
        cost_center_id: "",
        next_maintenance_at: null,
      },
      loading: false,

      setObjectValues
    }
  },
  components: {
    StripeElementCard,
    AddBillingAddress,
    AddCardInfo
  },

  async created() {
    await this.handleShowBillingAddress();
  },

  methods: {
    ...mapActions("costCenter", ["getCostCenters"]),
    ...mapActions("billingAddress", ["getBillingAddresses"]),

    async handleShowBillingAddress(){
      const response = await this.getBillingAddresses("");
      if (response.length > 0)
        this.initCardForm = true;
    },

    handleGetCards(){
      this.initCardForm = true; 
    },

    async handleGetCostCenters() {
      const cost_centers = await this.getCostCenters("");
      this.cost_centers = cost_centers.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-bike {
  padding: 24px 0;

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
