export const state = {
  dataToDelete: null,
  passwordConfirmation: null,

  dialogData: {
    dialogTitle: "",
    dialogMessage: "",
  },

  allowDeletion: false,
  loading: false,
};

export const mutations = {
  setDialogData(state, { dialogTitle, dialogMessage }) {
    state.dialogData.dialogTitle = dialogTitle;
    state.dialogData.dialogMessage = dialogMessage;
  },

  setDataToDelete(state, dataToDelete) {
    state.dataToDelete = dataToDelete;
  },

  setAllowDeletion(state, value) {
    state.allowDeletion = value;
  },

  setPasswordConfirmation(state, password) {
    state.passwordConfirmation = password;
  },

  setLoading(state, value) {
    state.loading = value;
  },
};
