<template lang="pug">
  .view-ticket-details
    .carousel
      .carousel-not-available.d-flex.justify-content-center(v-if="carouselItems.length === 0") {{ $t('dialogs.tickets.images_not_provided') }}
      el-carousel(
        v-else
        :autoplay="false"
        :interval="5000"
        indicator-position="none"
        arrow="never"
      )
        el-carousel-item(
          :key="carouselItems[currentItem]"
          :name="carouselItems[currentItem]"
           v-for="image in ticket.images"
        )
          img(:src="ticket.images[currentImageIndex]" @click="openInNewTab(ticket.images[currentImageIndex])").carousel-img
        i.el-icon-caret-right.arrows.arrow-right(@click="handleImageNavigation('next')")
        i.el-icon-caret-left.arrows.arrow-left(@click="handleImageNavigation('previous')")
    .col
      el-row.center.details-bold(:gutter="50" type="flex" justify="center")

        el-col(:span="10")
          span {{ $t('dialogs.tickets.problem_type') }}

        el-col(:span="5")
          span {{  $t('common.status') }}

      el-row.center(:gutter="50" type="flex" justify="center" style="margin-top: 10px")

        el-col(:span="10")
          span.break-word {{ ticketsStatuses[ticket.status].message }}

        el-col(:span="5")
          .status-wrapper(v-if="inBetween('ticketsStatuses', ticket.status, 41, 60)")
            span.oval.cancelled
            span {{ ticketsStatuses[ticket.status].message }}
          .status-wrapper(v-if="inBetween('ticketsStatuses', ticket.status, 1, 20)")
            span.oval.pending
            span {{ ticketsStatuses[ticket.status].message }}
          .status-wrapper(v-if="inBetween('ticketsStatuses', ticket.status, 21, 40)")
            span.oval.completed
            span {{ ticketsStatuses[ticket.status].message }}
    .col
      el-row.center.details-bold(:gutter="50" type="flex" justify="center")
        el-col(:span="10")
          span {{ $t('label.description') }}
        el-col(:span="5")
          span {{  $t('common.time_of_report') }}

      el-row.center(:gutter="50" type="flex" justify="center" style="margin-top: 10px")
        el-col(:span="10")
          span.break-word {{ ticket.description }}
        el-col(:span="5")
          span {{ computeDateFormat(ticket.timeOfReport) }}
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { ticketsReports, ticketsStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";

export default {
  name: "ViewTicketDetails",
  mixins: [filterUtils, routerUtils],

  data() {
    return {
      currentItem: null,
      carouselItems: [],
      currentImageIndex: 0,
      ticketsStatuses,
      ticketsReports,
      inBetween,
    };
  },

  computed: {
    ...mapState("ticket", ["ticket"]),
  },

  mounted() {
    this.handleInitCarouselItems()
  },

  methods: {
    ...mapMutations("dialog", ["hideViewTicketDetailsDialog"]),

    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },

    handleCustomerClick(ticket){
      this.goToWIthId('customers', ticket.user.id);
      this.hideViewTicketDetailsDialog();
    },

    handleInitCarouselItems() {
      this.carouselItems = this.ticket.images
    },

    handleImageNavigation(direction) {
      if (direction === 'next') {
        const itemsCount = this.carouselItems.length - 1;
        if (this.currentImageIndex < itemsCount) {
          this.currentImageIndex += 1;
        } else {
          this.currentImageIndex = 0;
        }
      } else {
        const itemsCount = this.carouselItems.length - 1;
        if (this.currentImageIndex <= 0) {
          this.currentImageIndex = itemsCount;
        } else {
          this.currentImageIndex -= 1;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.view-ticket-details {
  padding-bottom: 24px;

  .carousel-not-available {
    height: 200px;
    align-items: center;
  }

  .col {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .carousel {
    position: relative;
    margin-top: 16px;
    margin-bottom: 20px;

    .arrows {
      position: absolute;
      font-size: 28px;
      cursor: pointer;
    }

    .arrow-left,
    .arrow-right {
      top: 50%;
      transform: translateY(-50%);
    }

    .arrow-right {
      right: 0;
    }

    .arrow-left {
      left: 0;
    }

    .carousel-img {
      height: 200px;
      width: 270px;
      object-fit: cover;
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    margin: 0;
  }

  .el-carousel__item {
    height: 200px;
    width: 270px;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}
</style>
