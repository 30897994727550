<template lang="pug">
  el-row(v-loading="loading")
    el-col(
      :span="24"
    ).mt-2
      GmapMap.mapWrapper(
        ref="mapRef"
        :center="center"
        :zoom="zoom"
        map-type-id="roadmap"
        style="width: 500px; height: 300px"
      )
</template>

<script>
import customMarker from "@assets/dockingStationsMap/available-location.svg";
import startMarker from "@assets/rideHistory/pin-start.svg";
import endMarker from "@assets/rideHistory/pin-end.svg";
import { gmapApi } from "vue2-google-maps";
import { mapState } from "vuex";
import { getRomaniaCoordsIfLocationIsOff } from "@utils/map";

export default {
  name: "GoogleMaps",
  props: {
    dialogName: {
      type: String,
      required: false,
    },

    rideCoordinates: {
      type: Array
    }
  },

  computed: {
    ...mapState("map", ["coordinates", "changedDialog", "isGoogleLoaded"]),

    google: gmapApi,
  },

  data() {
    return {
      marker: null,
      loading: false,
      zoom: 13,

      markerPosition: {
        lat: null,
        lng: null,
      },

      center: {
        lat: 0,
        lng: 0,
      },
    };
  },

  watch: {
    google() {
      this.initMap()
    },

    coordinates() {
      this.initMap()
    },

    rideCoordinates() {
      this.initMap()
    }
  },

  mounted() {
    if (this.google) {
      this.initMap()
    }
  },

  methods: {
    async initMap() {
      switch (this.dialogName) {
        case "AddCostCenter":
        case "AddDockingStation":
        case "EditCostCenter":
        case "EditDockingStation":
          return this.handleMapMarker()
        case "viewRideDetails":
          return this.handleDrawPathFromCoordinates()
      }
    },

    handleDrawPathFromCoordinates() {
      if (this.rideCoordinates.length > 0) {
        const mappedRideCoordinates = this.rideCoordinates.map(coords => {
          return {
            lat: Number(coords.latitude),
            lng: Number(coords.longitude)
          }
        })

        const flightPath = new this.google.maps.Polyline({
          path: mappedRideCoordinates,
          geodesic: true,
          strokeColor: "#2564f0",
          strokeWeight: 5,
        });

        this.$refs.mapRef.$mapPromise.then((map) => {
          // pan to the start coordinate
          map.panTo({
            lat: mappedRideCoordinates[0].lat,
            lng: mappedRideCoordinates[0].lng,
          });

          flightPath.setMap(map);

          // add start/end markers
          new this.google.maps.Marker({
            position: {
              lat: mappedRideCoordinates[0].lat,
              lng: mappedRideCoordinates[0].lng,
            },
            map: map,
            icon: startMarker,
            draggable: false,
          });
          new this.google.maps.Marker({
            position: {
              lat: mappedRideCoordinates[mappedRideCoordinates.length - 1].lat,
              lng: mappedRideCoordinates[mappedRideCoordinates.length - 1].lng,
            },
            map: map,
            icon: endMarker,
            draggable: false,
          });
        })
      }
    },

    async handleMapMarker() {
      const location = await this.getUserLocation();

      if (!this.coordinates.lat) {
        this.center.lat = location.coords.latitude;
        this.center.lng = location.coords.longitude;
        this.markerPosition.lat = location.coords.latitude;
        this.markerPosition.lng = location.coords.longitude;
      } else {
        this.center.lat = this.coordinates.lat;
        this.center.lng = this.coordinates.lng;
        this.markerPosition.lat = this.coordinates.lat;
        this.markerPosition.lng = this.coordinates.lng;
      }

      let geocoder = new this.google.maps.Geocoder();

      const geocodePosition = (pos) => {
        geocoder.geocode(
            {
              latLng: pos,
            },
            (responses) => {
              if (responses && responses.length > 0) {
                this.$emit(
                    "markerDragEnd",
                    responses[0].formatted_address,
                    this.markerPosition
                );
              } else {
                this.$message({
                  type: "warning",
                  message: this.$t("map.cannot_determine_address"),
                });
              }
            }
        );
      };

      this.$refs.mapRef.$mapPromise.then((map) => {
        if (this.marker) {
          this.google.maps.event.clearInstanceListeners(this.marker);
          this.marker.setMap(null);
          this.marker = null;
        }

        this.marker = new this.google.maps.Marker({
          position: {
            lat: this.markerPosition.lat,
            lng: this.markerPosition.lng,
          },
          map: map,
          icon: customMarker,
          draggable: true,
        });
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.panTo({
            lat: this.markerPosition.lat,
            lng: this.markerPosition.lng,
          });

          this.loading = false;
        });

        this.google.maps.event.addListener(this.marker, "dragend", () => {
          this.markerPosition.lat = this.marker.getPosition().lat();
          this.markerPosition.lng = this.marker.getPosition().lng();
          geocodePosition(this.marker.getPosition());
        });
      });
    },

    async getUserLocation() {
      let location = null;
      await navigator.geolocation.getCurrentPosition(currentBrowserLocation => {
        location = currentBrowserLocation
      })

      // if location is not working or turned off
      const romaniaCoords = await getRomaniaCoordsIfLocationIsOff()
      if (romaniaCoords) {
        location = romaniaCoords
        if (!this.coordinates.lat) {
          this.zoom = 7
        }
      }

      return location;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.mapWrapper {
  width: 100% !important;
  height: 340px !important;
  margin: 0 auto;
  margin-bottom: 32px;
}
</style>
