<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='rides'
  )
    el-table-column(
      prop='start_at'
      :label="$t('label.starting_date')"
    )
      template(slot-scope='scope')
        span.pre-line {{ computeDateFormat(scope.row.start_at) }}
    el-table-column.pre-line(
      prop='start_docking_station_name'
      :label="$t('label.start_dock_end_dock')"
    )
      template(slot-scope='scope')
        .docks.pre-line
          span(v-if="!scope.row.start_docking_station_name") -
          span(v-else) {{ scope.row.start_docking_station_name + "/ \n" }}

          span(v-if="!scope.row.end_docking_station_name") -
          span(v-else) {{ scope.row.end_docking_station_name }}
    el-table-column(
      prop='duration_seconds'
      :label="$t('common.ride_duration')"
    )
      template(slot-scope='scope') {{ computeRideDuration(scope.row.duration_seconds) }}
    el-table-column(
      prop='price'
      :label="$t('common.ride_price')"
    )
      template(slot-scope='scope')
        span.pre-line {{ $n(scope.row.price, 'currency') + "/ \n" + $n(scope.row.already_paid_ammount, 'currency') }}
    el-table-column(
      prop='status'
      :label="$t('common.ride_status')"
    )
      template(slot-scope='scope')
        .status-wrapper.pre-line(
          v-if="inBetween('ridesStatuses', scope.row.status, 21, 40)"
        )
          .status
            span.oval.completed
            span {{ ridesStatuses[scope.row.status].message }}
          span {{ computePaidStatus(scope.row.price, scope.row.already_paid_ammount) }}

        .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 41, 60)")
          .status
            span.oval.cancelled
            span {{ ridesStatuses[scope.row.status].message }}
          span {{ computePaidStatus(scope.row.price, scope.row.already_paid_ammount) }}

        .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 1, 20)")
          .status
            span.oval.pending
            span {{ ridesStatuses[scope.row.status].message }}
    el-table-column(
      prop='flag'
      :label="$t('common.flagged')"
    )
      template(slot-scope='scope')
        span.pre-line.break-word(v-if="scope.row.flag !== 'no_problems'") 🚩 {{ scope.row.flag }}
        span(v-else) --
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        img.cursor-pointer.ml-2(
         
          src="@assets/preview.svg"
          @click="handleViewRideDetails(scope.row)"
        )

</template>

<script>
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { flaggedRidesStatuses, ridesStatuses } from "@utils/statuses";
import { mapActions, mapMutations, mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "RidesViewTable",
  mixins: [filterUtils, routerUtils, general],

  props: {
    rides: {
      type: Array
    },

    selectedStatus: {
      type: String,
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      ridesStatuses,
      flaggedRidesStatuses,
      inBetween,
      dynamicViewsConstants
    }
  },

  methods: {
    ...mapActions("dockingStation", ["getDock"]),

    ...mapMutations("dialog", ["showViewRideDetailsDialog", "showForceStopRideDialog"]),

    ...mapMutations("ride", ["setRide"]),

    handleViewRideDetails(ride) {
      this.showViewRideDetailsDialog();
      this.setRide(ride);
    },
  }
}
</script>

<style lang="scss" scoped>
.status-wrapper {
  display: flex;
  flex-direction: column;

  & > span {
    margin-left: 22px;
  }

  .status {
    display: flex;
    align-items: center;
  }

  span {
    display: block;
  }
}
</style>
