import axios from "axios";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  smartCard: null,
  shouldFetchSmartCards: false,
};

export const actions = {
  async getSmartCards({ commit }) {
    try {
      const response = await axios.get(`/api/customer_cms/v1/card_shipping_addresses`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  // async getBike({ commit }, id) {
  //   try {
  //     const response = await axios.get(`/v1/bikes/${id}`);
  //     commit("setBike", dataFormatter.deserialize(response.data));

  //     return dataFormatter.deserialize(response.data);
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },

  async addSmartCard({ commit }, payload) {
    try {
      await axios.post("/api/customer_cms/v1/card_shipping_addresses", {
        ...payload,
      });
      return messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      // console.clear();
      return Promise.reject(error);
    }
  },

  async deleteSmartCard({ commit }, payload) {
    try {
      await axios.delete(`/api/customer_cms/v1/card_shipping_addresses/${payload.computedQueriesUrl}`, {
        data: { password_confirmation: payload.password_confirmation },
      });
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  // async updateBike({ commit }, payload) {
  //   try {
  //     await axios.patch(`/v1/bikes/${payload.id}`, {
  //       ...payload,
  //     });
  //     return messageUtils.showSuccess(i18n.t("common.success"));
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },

};

export const mutations = {
  setBike(state, smartCard) {
    state.smartCard = smartCard;
  },

  setShouldFetchSmartCards(state, value) {
    state.shouldFetchSmartCards = value;
  },
};
