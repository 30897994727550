import i18n from "@i18n";

export const cardViewValidations = {
  data() {
    return {
      checkAddCard: {
        card: [
          {
            required: true,
            // validator: this.cardValidator,
            trigger: "blur",
          },
        ],
      },

    };
  },
};
