<template lang="pug">
  .docking-station-details-modal
    el-table(
      :data="bays"
      v-loading="loading"
      :gutter="0"
      center
      height="250"
      style="width: 100%;"
    )
      el-table-column(
        prop="''"
        label=""
        width="100"
      )
      el-table-column(
        prop="number"
        :label="$t('common.bay_number')"
        :span="8"
      )
      el-table-column(
        prop="status"
        :label="$t('common.bay_status')"
        :span="8"
      )
      el-table-column(
        prop="bike.serial_number"
        :label="$t('common.bike_number')"
        :span="8"
      )
        template(slot-scope='scope')
          span(v-if="scope.row.bike && scope.row.bike.serial_number") {{ scope.row.bike.serial_number }}
          span(v-else) -
      // TODO: to update status with colors
      el-table-column(
        prop="bike.status_object.label"
        :label="$t('common.bike_status')"
        :span="8"
      )
        template(slot-scope='scope')
          span(v-if="scope.row.bike && scope.row.bike.status_object.label") {{ scope.row.bike.status_object.label }}
          span(v-else) -
      el-table-column(
        prop="qr_code"
        :label="$t('common.qr_code')"
        :span="8"
      )
        template(v-slot='scope')
          el-button.dark-blue-btn(
            @click="handleQrCodeClick(scope.row.qr_code)"
          ) {{ $t('common.see_qr_code') }}
</template>

<script>

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ViewDockingStation",

  data() {
    return {
      bays: [],
      loading: false,
      seeQrCode: false
    };
  },

  computed: {
    ...mapState('dockingStation', ['dockingStation']),
  },

  async created() {
    this.loading = true;
    const dock = await this.getDock(this.dockingStation.id);
    this.loading = false
    this.bays = dock.bays
  },

  methods: {
    ...mapActions('dockingStation', ['getDock']),
    ...mapMutations('dockingStation', ['setQrCode']),
    ...mapMutations('dialog', ['showQrCodeDialog']),

    handleQrCodeClick(qrCode){
      this.setQrCode(qrCode);
      this.showQrCodeDialog();
    }
  }
};
</script>

<style lang="scss" scoped>
.view-docking-station-details {
  h4 {
    color: white;
    font-family: Montserat, sans-serif;
    font-weight: 600;
    letter-spacing: 0.12px;
    line-height: 19px;
  }
}
</style>
