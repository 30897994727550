<template lang="pug">
  .assign-bikes
    el-row.d-flex.justify-content-center
      el-col(:span="18").title
        span {{ $t('inventory.select_a_cost_center') }}
    el-form(
      :model="form"
      ref="AssignBikesForm"
      :rules="assignBikesRules"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center
        el-col(:span="11")
          el-form-item(
            prop="costCenterID"
            :label="$t('common.cost_center_name')"
          )
            el-select.w-100(
              v-model='form.costCenterID'
              filterable
              :placeholder="$t('placeholder.select_cost_center')"
            )
              el-option(
                v-for='cost_center in cost_centers'
                :key='cost_center.id'
                :label='cost_center.name'
                :value='cost_center.id'
              )
      el-row.d-flex.justify-content-center
        el-col(:span="11")
          el-form-item(
            prop="maintenanceDate"
            :label="$t('common.first_maintenance_date')"
          )
            el-date-picker.date-picker(
              v-model="form.maintenanceDate"
              type="date"
              :placeholder="$t('placeholder.pick_date')"
              :pickerOptions="pickerOptions"
              :default-value="new Date()"
            )
      el-row.text-center
        el-button.dark-blue-btn.pop-up-btn(
          @click="handleAssign('AssignBikesForm')"
          :loading="loading"
        ) {{ $t('dialogs.inventory.assign') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { bikesViewValidations } from "@utils/formValidations/bikesViewValidations";
import { customValidations } from "@utils/customValidators";
import { setObjectValues } from "@utils/generalUtils";
import { disabledPastDates } from "@utils/dateRange";
import { filterUtils } from "@utils/filterUtils";

export default {
  name: "AssignBikes",
  mixins: [bikesViewValidations, customValidations, filterUtils],

  data() {
    return {
      cost_centers: [],
      form: {
        next_maintenance_at: null,
        cost_center_id: null,
      },
      loading: false,
      setObjectValues,
      pickerOptions: {
        disabledDate(time) {
          return disabledPastDates(time);
        },
      },
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("bike", ["selectedBikes"]),
  },

  mounted() {
    this.handleGetCostCenters();
  },

  methods: {
    ...mapActions("costCenter", ["getCostCenters"]),

    ...mapActions("bike", ["assignBikes"]),

    ...mapMutations("dialog", ["hideViewAssignCostCenterDialog"]),

    ...mapMutations("bike", ["setShouldFetchBikes"]),

    clearForm() {
      this.setObjectValues(this.form, null);
    },

    async handleGetCostCenters() {
      const cost_centers = await this.getCostCenters("");
      this.cost_centers = cost_centers.data;
    },

    handleAssign(formName) {
      this.loading = true;
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          const params = {
            bike_ids: this.selectedBikes.map((bike) => Number(bike.id)),
            cost_center_id: Number(this.form.costCenterID),
            next_maintenance_at: this.computeDateFormat(
              this.form.maintenanceDate
            ),
          };
          this.assignBikes(params)
            .then(() => {
              this.clearForm();
              this.setShouldFetchBikes(true);
              this.hideViewAssignCostCenterDialog();
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.assign-bikes {
  padding: 24px 0;

  .title {
    padding-bottom: 24px;
  }
}
</style>
