import store from "@state/store";

export const roles = {
  SUPER_ADMINISTRATOR: "super_administrator",
  ADMINISTRATOR: "administrator",
  TECHNICIAN: "technician",
  ACCOUNTANT: "accountant",
  ADMINISTRATOR_OPERATOR: "administrator_operator",
  TECHNICIAN_OPERATOR: "technician_operator",
  ACCOUNTANT_OPERATOR: "accountant_operator",
  CUSTOMER: "customer"
};

export const checkIfNotSuperAndAdministrator = () => {
  return roles 
  // ![roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(
  //   (r) => store.state.auth.user.roles.map((role) => role.name).indexOf(r) >= 0
  // );
};
