<template lang="pug">
  .dialog
    .view-password-validation-delete-dialog
      el-dialog(
        :title="dialogData.dialogTitle"
        center
        :visible.sync='isViewPasswordValidationDelete'
        width='567px'
        :before-close='hideViewPasswordValidationDeleteDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        PasswordValidationDelete

    .view-start-maintenance-dialog
      el-dialog(
        :title="$t('dialog.bike_start_maintenance')"
        center
        :visible.sync='isViewStartBikeMaintenanceVisible'
        width='567px'
        :before-close='hideStartBikeMaintenanceDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        StartMaintenance

    .view-transaction-details-dialog
      el-dialog(
        :title="$t('dialog.payment_actions')"
        center
        :visible.sync='isViewPaymentActionsDialogVisible'
        width='567px'
        :before-close='hideViewPaymentActionDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        PaymentActions

    .view-contact-details-dialog
      el-dialog(
        :title="$t('dialog.contact_details')"
        center
        :visible.sync='isViewCustomerDetailsDialogVisible'
        width='575px'
        :before-close='hideCustomerDetailsDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ContactDetails

    .view-device-info-dialog
      el-dialog(
        :title="$t('dialog.device_info')"
        center
        :visible.sync='isViewCustomerDeviceInfoDialogVisible'
        width='1020px'
        :before-close='hideCustomerDeviceInfoDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        DeviceInfo

    .view-transaction-details-dialog
      el-dialog(
        :title="$t('dialog.transaction_details')"
        center
        :visible.sync='isViewTransactionDetailsDialogVisible'
        width='1020px'
        :before-close='hideViewTransactionDetailsDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        TransactionDetails

    .view-bike-maintenance
      el-dialog(
        v-if="isViewBikeMaintenanceDialogVisible"
        :title="$t('dialog.bike_maintenance')"
        center
        :visible.sync='isViewBikeMaintenanceDialogVisible'
        width='905px'
        :before-close='hideViewBikeMaintenanceDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ViewBikeMaintenance

    .view-add-new-bike-dialog
      el-dialog(
        :title="$t('dialog.add_new_bike')"
        center
        :visible.sync='isViewAddBikeDialogVisible'
        width='760px'
        :before-close='hideAddBikeDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddBike

    .view-add-bulk-dialog
      el-dialog(
        :title="$t('dialog.add_bikes_bulk')"
        center
        :visible.sync='isAddBulkDialogVisible'
        width='560px'
        :before-close='hideAddBulkDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddBulk

    .view-assign-bike-dialog
      el-dialog(
        :title="$t('dialog.assign_bikes_to_cost_center')"
        center
        :visible.sync='isViewAssignCostCenterDialogVisible'
        width='560px'
        :before-close='hideViewAssignCostCenterDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AssignBikes

    .view-add-bike-dialog
      el-dialog(
        :title="$t('common.add_bike')"
        center
        :visible.sync='isAddBikeInventoryDialogVisible'
        width='560px'
        :before-close='hideAddBikeInventoryDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddBikeInventory

    .view-ride-details-dialog
      el-dialog(
        :title="$t('dialog.ride_details')"
        center
        :visible.sync='isViewRideDetailsDialogVisible'
        width='820px'
        @open="createMap"
        :before-close='destroyRidesMap'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ViewCustomerRideDetails

    .view-customer-tickets-details-dialog
      el-dialog(
        v-if="isViewCustomerTicketsDialogVisible"
        :title="$t('dialog.customer_tickets')"
        center
        :visible.sync='isViewCustomerTicketsDialogVisible'
        width='1100px'
        :before-close='hideViewCustomerTickets'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ViewCustomerTickets

    .view-ticket-details-dialog
      el-dialog(
        :title="$t('dialog.view_ticked_details')"
        center
        :visible.sync='isViewTicketDialogVisible'
        width='808px'
        :before-close='hideViewTicketDetailsDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ViewTicketDetails

    .add-cost-center-dialog
      el-dialog.custom-map-dialog(
        v-if="isAddCostCenterDialogVisible"
        :title="$t('dialog.add_new_cost_center')"
        center
        :visible.sync='isAddCostCenterDialogVisible'
        width='940px'
        :before-close='hideAddCostCenterDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddCostCenter

    .edit-cost-center-dialog
      el-dialog.custom-map-dialog(
        v-if="isEditCostCenterDialogVisible"
        :title="$t('dialog.edit_cost_center')"
        center
        :visible.sync='isEditCostCenterDialogVisible'
        width='940px'
        :before-close='hideEditCostCenterDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        EditCostCenter

    .create-new-tariff-dialog
      el-dialog(
        :title="$t('dialog.create_new_tariff')"
        center
        :visible.sync='isAddRentTariffDialogVisible'
        width='940px'
        :before-close='hideAddRentTariffDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddRentTariff

    .view-rent-tariff-details-dialog
      el-dialog(
        :title="$t('dialog.docking_stations_with_price_plan')"
        center
        :visible.sync='isViewRentTariffDialogVisible'
        width='850px'
        :before-close='hideViewRentTariffDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ViewRentTariffsDetails

    .add-docking-station-dialog
      el-dialog.custom-map-dialog(
        v-if="isAddDockingStationDialogVisible"
        :title="$t('dialog.create_new_docking_station')"
        center
        :visible.sync='isAddDockingStationDialogVisible'
        width='940px'
        :before-close='hideAddDockingStationDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddDockingStation

    .edit-docking-station-dialog
      el-dialog.custom-map-dialog(
        v-if="isEditDockingStationDialogVisible"
        :title="$t('dialog.edit_docking_station')"
        center
        :visible.sync='isEditDockingStationDialogVisible'
        width='940px'
        :before-close='hideEditDockingStationDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        EditDockingStation

    .view-docking-station-dialog
      el-dialog(
        v-if="isViewDockDetailsDialogVisible"
        :title="$t('dialog.view_docking_station')"
        center
        :visible.sync='isViewDockDetailsDialogVisible'
        width='940px'
        :before-close='hideViewDockDetailsDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ViewDockingStation

    .view-bike-details-dialog
      el-dialog(
        :title="$t('dialog.view_bike_details')"
        center
        :visible.sync='isViewBikeDetailsDialogVisible'
        width='940px'
        :before-close='hideViewBikeDetailsDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ViewBikeDetails

    .view-bike-edit-details-dialog
      el-dialog(
        v-if="isEditBikeDetailsDialogVisible"
        :title="$t('dialog.view_bike_edit_details')"
        center
        :visible.sync='isEditBikeDetailsDialogVisible'
        width='940px'
        :before-close='hideEditBikeDetailsDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        EditBikeDetails

    .add-user-modal
      el-dialog(
        :title="$t('dialog.add_new_user')"
        center
        :visible.sync='isAddNewUserDialogVisible'
        width='946px'
        :before-close='hideAddNewUserDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddUser

    .edit-user-modal
      el-dialog(
        :title="$t('dialog.edit_user')"
        center
        :visible.sync='isEditUserDialogVisible'
        width='946px'
        :before-close='hideEditUserDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        EditRoleModal
    .switch-cost-center-modal
      el-dialog(
        :title="$t('dialog.switch_cost_center')"
        v-if="isSwitchCostCenterDialogVisible"
        center
        :visible.sync='isSwitchCostCenterDialogVisible'
        width='442px'
        :before-close='hideSwitchCostCenterDialog'
        :show-close="user && user.selected_cost_center !== null"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        SwitchCostCenter
    .set-logs-size-modal
      el-dialog(
        :title="$t('logs.set_logs_size')"
        v-if="isSetLogsSizeDialogVisible"
        center
        :visible.sync='isSetLogsSizeDialogVisible'
        width='542px'
        :before-close='hideSetLogsSizeDialog'
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        SetLogsSize
    .edit-conf-settings-modal
      el-dialog(
        :title="$t('dialog.edit_conf_settings')"
        v-if="isEditConfSettingsVisible"
        center
        :visible.sync='isEditConfSettingsVisible'
        width='542px'
        :before-close='hideEditConfSettingsDialog'
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        EditConfigurableSettings
    .force-stop-ride-modal
      el-dialog(
        :title="$t('dialog.force_stop_ride')"
        v-if="isForceStopDialogVisible"
        center
        :visible.sync='isForceStopDialogVisible'
        width='542px'
        :before-close='hideForceStopRideDialog'
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ForceStopRide
    .force-stop-ride-modal
      el-dialog(
        :title="$t('common.qr_code')"
        v-if="isQrCodeVisible"
        center
        :visible.sync='isQrCodeVisible'
        width='542px'
        :before-close='hideQrCodeDialog'
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        QrPopUp

    .add-card-dialog
      el-dialog(
        v-if="isAddCardDialogVisible"
        :title="$t('dialog.add_new_card')"
        center
        :visible.sync='isAddCardDialogVisible'
        width='940px'
        :before-close='hideAddCardDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddCard

    .add-card-dialog
      el-dialog(
        v-if="isAddSmartCardDialogVisible"
        :title="$t('dialog.add_new_card')"
        center
        :visible.sync='isAddSmartCardDialogVisible'
        width='940px'
        :before-close='hideAddSmartCardDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        AddSmartCard

    .add-card-dialog
      el-dialog(
        v-if="isConfirmedOperation"
        :title="$t('dialog.add_new_card')"
        center
        :visible.sync='isConfirmedOperation'
        width='940px'
        :before-close='hideConfirmOperationDialog'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ConfirmOperation
    .add-card-dialog
      el-dialog(
        v-if="isConfirmPhoneNumberDialogVisible"
        :title="$t('dialog.validate_phone_number')"
        center
        :visible.sync='isConfirmPhoneNumberDialogVisible'
        width='570px'
        :before-close='hideConfirmPhoneNumberDialogVisible'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ConfirmPhoneNumber
    .add-card-dialog
      el-dialog(
        v-if="isConfirmEmailAddressDialogVisible"
        :title="$t('dialog.validate_email_address')"
        center
        :visible.sync='isConfirmEmailAddressDialogVisible'
        width='570px'
        :before-close='hideConfirmEmailAddressDialogVisible'
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      )
        ConfirmEmailAddress
</template>

<script>
import { bus } from "@src/main";
import { mapMutations, mapState } from "vuex";
import AddCostCenter from "./templates/AddCostCenter.vue";
import EditCostCenter from "./templates/EditCostCenter.vue";
import AddDockingStation from "./templates/AddDockingStation.vue";
import EditDockingStation from "./templates/EditDockingStation.vue";
import ViewBikeDetails from "./templates/ViewBikeDetails.vue";
import AddUser from "./templates/AddUser";
import AddRentTariff from "./templates/AddRentTariff.vue";
import ViewRentTariffsDetails from "./templates/ViewRentTariffsDetails.vue";
import ViewTicketDetails from "./templates/ViewTicketDetails.vue";
import ViewCustomerTickets from "./templates/ViewCustomerTickets.vue";
import ViewCustomerRideDetails from "./templates/ViewRideDetails.vue";
import AddBikeInventory from "./templates/AddBikeInventory.vue";
import AssignBikes from "./templates/AssignBikes.vue";
import AddBulk from "./templates/AddBulk.vue";
import ContactDetails from "./templates/ContactDetails.vue";
import DeviceInfo from "./templates/DeviceInfo.vue";
import TransactionDetails from "./templates/ViewTransactionDetails.vue";
import PaymentActions from "./templates/ViewPaymentActions.vue";
import AddBike from "./templates/AddBike.vue";
import ViewBikeMaintenance from "./templates/ViewBikeMaintenance.vue";
import StartMaintenance from "./templates/StartMaintenance.vue";
import PasswordValidationDelete from "./templates/PasswordValidationDelete.vue";
import SwitchCostCenter from "@components/dialogs/templates/SwitchCostCenter";
import SetLogsSize from "@components/dialogs/templates/SetLogsSize";
import EditBikeDetails from "@components/dialogs/templates/EditBikeDetails";
import EditConfigurableSettings from "@components/dialogs/templates/EditConfigurableSettings";
import ForceStopRide from "@components/dialogs/templates/ForceStopRide";
import ViewDockingStation from "@components/dialogs/templates/ViewDockingStation"
import QrPopUp from "@components/dialogs/templates/QrPopUp";
import AddCard from "./templates/AddCard.vue";
import AddSmartCard from "./templates/AddSmartCard.vue";
import ConfirmOperation from "@components/dialogs/templates/ConfirmOperation";
import ConfirmPhoneNumber from "@components/dialogs/templates/ConfirmPhoneNumber";
import ConfirmEmailAddress from "@components/dialogs/templates/ConfirmEmailAddress";

export default {
  name: "Dialogs",
  components: {
    QrPopUp,
    AddCostCenter,
    AddUser,
    EditDockingStation,
    AddDockingStation,
    ViewBikeDetails,
    EditCostCenter,
    AddRentTariff,
    ViewRentTariffsDetails,
    ViewTicketDetails,
    ViewCustomerTickets,
    ViewCustomerRideDetails,
    AddBikeInventory,
    AssignBikes,
    AddBulk,
    ContactDetails,
    DeviceInfo,
    TransactionDetails,
    PaymentActions,
    AddBike,
    ViewBikeMaintenance,
    StartMaintenance,
    PasswordValidationDelete,
    SwitchCostCenter,
    SetLogsSize,
    EditBikeDetails,
    EditConfigurableSettings,
    ForceStopRide,
    ViewDockingStation,
    AddCard, 
    AddSmartCard,
    ConfirmOperation,
    ConfirmPhoneNumber,
    ConfirmEmailAddress
  },

  computed: {
    ...mapState("dialog", [
      "isViewStartBikeMaintenanceVisible",
      "isViewCustomerDetailsDialogVisible",
      "isViewCustomerDeviceInfoDialogVisible",
      "isViewPaymentActionsDialogVisible",
      "isViewTransactionDetailsDialogVisible",
      "isViewRideDetailsDialogVisible",
      "isViewCustomerTicketsDialogVisible",
      "isViewTicketDialogVisible",
      "isAddCostCenterDialogVisible",
      "isAddRentTariffDialogVisible",
      "isViewRentTariffDialogVisible",
      "isEditCostCenterDialogVisible",
      "isAddDockingStationDialogVisible",
      "isEditDockingStationDialogVisible",
      "isViewBikeDetailsDialogVisible",
      "isAddBulkDialogVisible",
      "isViewBikeMaintenanceDialogVisible",
      "isAddBikeInventoryDialogVisible",
      "isViewAddBikeDialogVisible",
      "isViewAssignCostCenterDialogVisible",
      "isViewUserDialogVisible",
      "isAddNewUserDialogVisible",
      "isEditUserDialogVisible",
      "isViewPasswordValidationDelete",
      "isSwitchCostCenterDialogVisible",
      "isSetLogsSizeDialogVisible",
      "isEditBikeDetailsDialogVisible",
      "isEditConfSettingsVisible",
      "isForceStopDialogVisible",
      "isViewDockDetailsDialogVisible",
      "isQrCodeVisible",
      "isAddCardDialogVisible",
      "isAddSmartCardDialogVisible",
      "isConfirmedOperation",
      "isConfirmPhoneNumberDialogVisible",
      "isConfirmEmailAddressDialogVisible"
      
    ]),

    ...mapState("passwordValidationDelete", ["dataToDelete", "dialogData"]),
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", [
      "hideStartBikeMaintenanceDialog",
      "hideCustomerDetailsDialog",
      "hideCustomerDeviceInfoDialog",
      "hideViewPaymentActionDialog",
      "hideViewTransactionDetailsDialog",
      "hideAddBulkDialog",
      "hideViewRideDetailsDialog",
      "hideViewCustomerTickets",
      "hideViewTicketDetailsDialog",
      "hideAddCostCenterDialog",
      "hideEditCostCenterDialog",
      "hideAddRentTariffDialog",
      "hideViewRentTariffDialog",
      "hideAddDockingStationDialog",
      "hideEditDockingStationDialog",
      "hideViewBikeDetailsDialog",
      "hideViewBikeMaintenanceDialog",
      "hideAddBikeInventoryDialog",
      "hideAddBikeDialog",
      "hideViewAssignCostCenterDialog",
      "hideViewUserDialog",
      "hideAddNewUserDialog",
      "hideEditUserDialog",
      "hideViewPasswordValidationDeleteDialog",
      "hideSwitchCostCenterDialog",
      "hideSetLogsSizeDialog",
      "hideEditBikeDetailsDialog",
      'hideEditConfSettingsDialog',
      "hideForceStopRideDialog",
      "hideViewDockDetailsDialog",
      "hideQrCodeDialog",
      "hideAddCardDialog",
      "hideAddSmartCardDialog",
      "hideConfirmOperationDialog",
      "hideConfirmPhoneNumberDialogVisible",
      "hideConfirmEmailAddressDialogVisible"
    ]),

    destroyRidesMap() {
      bus.$emit("destroy-map");

      this.hideViewRideDetailsDialog();
    },

    createMap() {
      bus.$emit("create-map");
    },
  },
};
</script>

<style lang="scss">
.view-customer-tickets-details-dialog {
  .el-dialog__body {
    padding: 0 !important;
  }

  .el-dialog--center .el-dialog__body {
    padding: 0 !important;
  }

  .dialog {
    max-height: 500px;
  }
}
</style>
