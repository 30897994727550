<template lang="pug">
  .bike-maintenance-history
    el-table(
      :data="maintenance_history"
      v-loading="loading"
      :gutter="0"
      center
      height="250"
      style="width: 100%;"
    )
      el-table-column(
        width="120"
      )
      el-table-column(
        prop="description"
        :label="$t('label.description')"
      )
      el-table-column(
        prop="created_at"
        :label="$t('label.date')"
      )
        template(slot-scope='scope')
          span {{ computeDateFormat(scope.row.created_at) }}
      el-table-column(
        prop="technician"
        :label="$t('label.technician')"
      )
        template(slot-scope='scope')
          span(v-if="scope.row.technician") {{ scope.row.technician.email }}
          span(v-else) -
      el-table-column(
        prop="status"
        :label="$t('label.maintenance')"
      )
        template(slot-scope='scope')
          .status-wrapper.d-flex.align-items-center(v-if="inBetween('bikesMaintenanceStatuses', scope.row.status, 1, 20)")
            span.oval.completed
            span {{ bikesMaintenanceStatuses[scope.row.status].message }}
          .status-wrapper.d-flex.align-items-center(v-if="inBetween('bikesMaintenanceStatuses', scope.row.status, 21, 40)")
            span.oval.pending
            span {{ bikesMaintenanceStatuses[scope.row.status].message }}
          .status-wrapper.d-flex.align-items-center(v-if="inBetween('bikesMaintenanceStatuses', scope.row.status, 41, 60)")
            span.oval.unavailable
            span {{ bikesMaintenanceStatuses[scope.row.status].message }}
    DialogTablePagination(
      :activeName="activeName"
      @viewData="handleGetData"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import { bikesMaintenanceStatuses, bikesStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import DialogTablePagination from "@components/_shared/DialogTablePagination";

export default {
  name: "BikeMaintenancesHistory",
  mixins: [filterUtils],
  components: { DialogTablePagination },

  props: {
    activeName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      maintenance_history: [],
      loading: false,
      bikesStatuses,
      bikesMaintenanceStatuses,
      inBetween,
    };
  },

  methods: {
    handleGetData(data) {
      this.maintenance_history = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>

<style lang="scss">
.status-wrapper {
  .status {
    margin-left: 5px;
  }
}
</style>
