<template lang="pug">
  div
    button.google-signup.cursor-pointer(@click="handleLogin")
      span.buttonText
        svg(xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" aria-hidden="true")
          g(fill="none" fill-rule="evenodd")
            path(fill="#4285F4" d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z")
            path(fill="#34A853" d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z")
            path(fill="#FBBC05" d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z")
            path(fill="#EA4335" d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z")
      span.buttonText Continue with Google
</template>

<script>
import { googleSdkLoaded } from "vue3-google-login";
import GoogleLogin from "vue-google-login";
import { mapActions } from "vuex";

export default {
  name: "GoogleLoginComponent",

  components: {
    GoogleLogin,
  },

  methods: {
    ...mapActions("auth", ["loginGoogle"]),

    handleLogin() {
      googleSdkLoaded(google => {
        google.accounts.oauth2
          .initTokenClient({
            client_id: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
            scope: "email",
            scope: "https://www.googleapis.com/auth/userinfo.email",
            redirect_uri: process.env.VUE_APP_GOOGLE_AUTH_REDIRECT_URI,
            
            callback: response => {
              if (response.access_token) {
                this.loginGoogle(response.access_token)
                  .then(() => {
                    this.$router.push('/docking_stations_map');
                  })

              }
            }
          })
          .requestAccessToken();
      });

    },

  }
};
</script>

<style>
.google-signup {
  color: #031b4e;
  background: #f2f8ff;
  border: 1px solid rgba(0,105,255,.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-block;
  margin-top: 0;
  padding: 15px;
  position: inherit;
}

.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 8px;
  margin-left: 5px;
  font-family: 'Roboto', sans-serif;
}

.google-signup:hover {
  background-color: #e8f0fe;
}
</style>
