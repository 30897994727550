import messageUtils from "@utils/messageUtils";
import axios from "axios";
import i18n from "@i18n";

export const state = {
  paymentTransaction: null,
};

export const actions = {
  async retryPayment({ commit }, transaction_id) {
    try {
      await axios.post("/v1/transactions/capture_payment", {
        transaction_id,
      });
      return messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async refundPayment({ commit }, payload) {
    try {
      await axios.post("/v1/transactions/refund_payment", {
        ...payload,
      });
      return messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  setTransaction(state, payload) {
    state.paymentTransaction = payload;
  },
};
