<template lang="pug">
  .details
    el-row.center.details-bold(
      :gutter="50"
      type="flex"
      justify="center"
    )
      el-col(:span="5")
        span {{ $t('common.status') }}

      el-col(:span="5")
        span {{ $t('common.serial_number') }}

      el-col(:span="5")
        span {{  $t('label.rfid') }}

    el-row.center(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="5")
        span.break-word {{ bikesStatuses[bike.status].message }}

      el-col(:span="5")
        span.break-word {{ bike.serial_number}}

      el-col(:span="5")
        span.break-word {{ bike.rfid}}

    el-row.center.details-bold(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 40px"
    )
      el-col(:span="5")
        span {{ $t('common.docking_station') }}

      el-col(:span="5")
        span {{ $t('common.cost_center_name') }}

      el-col(:span="5")
        span {{  $t('common.last_customer') }}

    el-row.center(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="5")
        // TODO: don't forget about this
        span.break-word {{ bike.docking_station_id }}

      el-col(:span="5")
        span.break-word {{ bike.cost_center && bike.cost_center.name }}

      el-col(:span="5")
        // TODO: don't forget about this
        span.break-word {{ bike.last_customer }}

    el-row(
      :gutter="50"
      center
      style="margin-top: 32px; margin-bottom: 16px; text-align:center;"
    )
      el-col
        button.begin-maintenance-btn.cursor-pointer(
          v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['start_maintenance'])"
          :class="(bike.status === 'in_ride' || bike.status === 'available_in_station') ? 'gray-btn' : 'dark-blue-btn'"
          :disabled="bike.status === 'in_ride' || bike.status === 'available_in_station'"
          @click="handleStartMaintenance(bike)"
        )
          div(v-if="bike.status !== 'under_maintenance'") {{ $t('dialogs.bikes.begin_maintenance') }}
          div(v-if="bike.status === 'under_maintenance'") {{ $t('dialogs.bikes.continue_maintenance') }}
</template>

<script>
import { bikesStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { mapActions, mapMutations, mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "Details",
  mixins: [filterUtils, general],

  data() {
    return {
      bikesStatuses,
      inBetween,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("bike", ["bike"]),
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapActions("bike", ["startMaintenance"]),

    ...mapMutations("dialog", [
      "showViewRideDetailsDialog",
      "showViewBikeMaintenanceDialog",
      "hideViewBikeDetailsDialog",
    ]),

    ...mapMutations("ride", ["setRide"]),

    ...mapMutations("bike", ["setBike", "setShouldFetchBikes"]),

    goToCustomer(id) {
      // TODO: this will be done in future branches
    },

    async goToRide(id) {
      // TODO: this will be done in future branches
    },

    handleStartMaintenance(bike) {
      if (bike.status === "under_maintenance") {
        this.showViewBikeMaintenanceDialog();
        this.setBike(bike);
        this.hideViewBikeDetailsDialog();
      } else {
        this.startMaintenance(bike.id).then(() => {
          this.setBike(bike);
          this.showViewBikeMaintenanceDialog();
          this.hideViewBikeDetailsDialog();
          this.setShouldFetchBikes(true);
        });
      }
    },
  },
};
</script>
