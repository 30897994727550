<template lang="pug">
  .customer-tickets-view
    .customer-tickets-wrapper
      el-row
        el-col(:span='24')
          el-table(
            style='width: 100%;'
            header-cell-class-name='table-header'
            cell-class-name='pl-4'
            :data='tickets'
            v-loading="loadingTable"
          )
            el-table-column(
              prop='status'
              :label="$t('common.status')"
              min-width="80px"
            )
              template(slot-scope='scope')
                .status-wrapper(v-if="inBetween('ticketsStatuses', scope.row.status, 41, 60)")
                  span.oval.cancelled
                  span {{ ticketsStatuses[scope.row.status].message }}
                .status-wrapper(v-if="inBetween('ticketsStatuses', scope.row.status, 1, 20)")
                  span.oval.pending
                  span {{ ticketsStatuses[scope.row.status].message }}
                .status-wrapper(v-if="inBetween('ticketsStatuses', scope.row.status, 21, 40)")
                  span.oval.completed
                  span {{ ticketsStatuses[scope.row.status].message }}
            el-table-column(
              prop='status_type'
              :label="$t('label.type')"
              min-width="120px"
            )
              template(slot-scope='scope')
                span {{ ticketsReports[scope.row.status_type].message }}
            el-table-column(
              prop='description'
              :label="$t('label.description')"
              min-width="150px"
            )
              template(slot-scope='scope')
                el-tooltip.item(
                  v-if="scope.row.description && scope.row.description.length > MAX_FIELD_LENGTH"
                  effect="dark"
                  :content="scope.row.description"
                  placement="bottom-end"
                )
                  span {{ trimTableField(scope.row.description) }}
                span(v-if="scope.row.description && scope.row.description.length <= MAX_FIELD_LENGTH") {{ scope.row.description }}
            el-table-column(
              prop='actions'
              :label="$t('label.actions')"
              width="450px"
            )
              template(slot-scope='scope')
                .d-flex(:key="actionsButtonKey")
                  img.cursor-pointer.mr-2(
                    src="@assets/preview.svg"
                    @click="handleViewTicketDetails(scope.row)"
                  )
                  el-button.custom-table-btn.mr-2(
                    v-if="scope.row.status === 'unresolved'"
                    v-loading="isActionButtonsLoading(scope.row, 'loadingStartWorkBtn')"
                    :disabled="isActionButtonsLoading(scope.row, 'loadingStartWorkBtn') || isActionButtonsLoading(scope.row, 'loadingSolveTicketBtn')"
                    @click="startWork(scope.row, 'startWork')"
                  ) {{ $t('tickets.start_work') }}
                  el-button.custom-table-btn-dark.mr-2(
                    v-if="(scope.row.status === 'unresolved' || scope.row.status === 'in_progress')"
                    v-loading="isActionButtonsLoading(scope.row, 'loadingSolveTicketBtn')"
                    :disabled="isActionButtonsLoading(scope.row, 'loadingStartWorkBtn') || isActionButtonsLoading(scope.row, 'loadingSolveTicketBtn')"
                    @click="solveTicket(scope.row, 'solveTicket')"
                  ) {{ $t('tickets.solve') }}
    Pagination(
      :view="'customerTickets'"
      @viewData="handleGetTickets"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  filterUtils,
  inBetween,
  MAX_FIELD_LENGTH,
} from "@utils/filterUtils";
import { ticketsStatuses, ticketsReports } from "@utils/statuses";
import Pagination from "@components/_shared/Pagination";

export default {
  mixins: [filterUtils],
  components: { Pagination },

  data() {
    return {
      users: [],
      search: "",
      tickets: [],

      actionsButtonKey: 0,
      loadingStartWorkBtn: [],
      loadingSolveTicketBtn: [],

      MAX_FIELD_LENGTH,
      ticketsStatuses,
      ticketsReports,
      inBetween,
      loadingTable: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("customer", ["shouldFetchCustomer"]),
  },


  methods: {
    ...mapMutations("dialog", [
      "showViewTicketDetailsDialog",
      "hideViewCustomerTickets",
    ]),

    ...mapMutations("customer", ["setShouldFetchCustomerTickets"]),

    ...mapActions("email", ["sendEmailOnSolvedTicket"]),

    ...mapActions("customer", ["getCustomerTickets", "getCustomer"]),

    ...mapActions("ticket", ['updateTicketStatus']),

    async handleGetTickets(data) {
      this.tickets = data
      this.tickets.forEach((ticket, index) => {
        this.loadingStartWorkBtn[index] = {
          isLoading: false,
          id: ticket.id
        }

        this.loadingSolveTicketBtn[index] = {
          isLoading: false,
          id: ticket.id
        }
      })
    },

    handleLoading(loading) {
      this.loadingTable = loading
    },

    isActionButtonsLoading(ticket, btnType) {
      let indexOfBtn = this._data[btnType].findIndex(btn => btn.id === ticket.id)
      return this._data[btnType][indexOfBtn].isLoading
    },

    handleViewTicketDetails(ticket) {
      this.hideViewCustomerTickets();
      this.$router.push("/tickets");
      this.showViewTicketDetailsDialog();
      this.setTicket(ticket);
    },

    async startWork(ticket) {
      let indexOfBtn = this.loadingStartWorkBtn.findIndex(btn => btn.id === ticket.id)
      this.loadingStartWorkBtn[indexOfBtn].isLoading = true
      this.actionsButtonKey += 1

      let params = {...ticket, status: 'in_progress'}
      this.updateTicketStatus(params)
          .finally(() => {
            this.loadingStartWorkBtn[indexOfBtn].isLoading = false
            this.actionsButtonKey += 1

            this.setShouldFetchCustomerTickets(true)
          })
    },

    solveTicket(ticket) {
      let indexOfBtn = this.loadingSolveTicketBtn.findIndex(btn => btn.id === ticket.id)
      this.loadingSolveTicketBtn[indexOfBtn].isLoading = true
      this.actionsButtonKey += 1

      let params = {...ticket, status: 'resolved'}
      this.updateTicketStatus(params)
          .finally(() => {
            this.loadingSolveTicketBtn[indexOfBtn].isLoading = false
            this.actionsButtonKey += 1

            this.setShouldFetchCustomerTickets(true)
          })
    },
  },
};
</script>

<style lang="scss" scoped>
.tickets-view {
  margin-top: 0 !important;

  .tickets-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}

.images {
  display: flex;
  align-items: center;

  .img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
  }

  .plus-icon {
    margin-left: 4px;
  }
}
</style>
