import i18n from "@i18n";

export const disabledPastDates = (time) => {
  return time.getTime() < Date.now() - 8.64e7; // 8.64e7 is number of milliseconds in a day
};

export const disabledFutureDates = (time) => {
  return time.getTime() > Date.now();
};

export const dateRangeShortcuts = [
  {
    text: i18n.t("common.last_week"),
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: i18n.t("common.last_month"),
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit("pick", [start, end]);
    },
  },
];
