<template lang="pug">
  // normal tickets
  el-menu-item(
    index='/tickets'
  )
    .img-wrapper
      img(src="@src/assets/sidebar/tickets.svg")
    router-link.sub-menu-link(to="/tickets")
      span  {{ $t('sidebar.tickets') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";

export default {
  name: "TicketsItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants,
      checkIfNotSuperAndAdministrator
    }
  }
}
</script>
