import axios from "axios";
import Jsona from "jsona";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

const dataFormatter = new Jsona();

export const actions = {
  async getRoles({ commit }) {
    try {
      const response = await axios.get("/v1/roles");
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async createRole({ commit }, payload) {
    try {
      const response = await axios.post("/v1/roles", {
        ...payload,
      });
      return response.data.data;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async deleteRole({ commit }, payload) {
    try {
      await axios.delete(`/v1/roles/${payload}`);
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getAllPermissions({ commit }) {
    try {
      const response = await axios.get("/v1/permissions");
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getAllPermissionsByRoleIds({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/permissions/${payload}`);
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async assignPermissions({ commit }, payload) {
    try {
      await axios.post("/v1/role_permissions/assign_permissions", {
        ...payload,
      });
      return messageUtils.showSuccess(i18n.t("roles.assigned_permissions"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getRole({ commit }, id) {
    try {
      const response = await axios.get(`/v1/roles/${id}`);
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};
