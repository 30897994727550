<template lang="pug">
  .settings-view
    h3 {{ $t('settings.settings') }}
    ProfileDataForm
    //- PasswordChangeForm
</template>

<script>
import ProfileDataForm from "@/src/components/settings/ProfileDataForm";
import PasswordChangeForm from "@components/settings/PasswordChangeForm";

export default {
  name: "SettingsView",
  components: { ProfileDataForm, PasswordChangeForm },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.avatar {
  width: 146px;
  height: 146px;
  object-fit: cover;
}

.settings-btn {
  border-radius: 7px !important;
  background: $tab-view-color !important;
}
</style>
