<template lang="pug">
  .view-transaction-details
    el-row.center.details-bold(:gutter="50" type="flex" justify="center")
      el-col(:span="10")
        span {{ $t('common.invoice_date') }}

      el-col(:span="5")
        span {{  $t('common.cost_center') }}

    el-row.center(:gutter="50" type="flex" justify="center" style="margin-top: 10px")
      el-col(:span="10")
        span {{ computeDateFormat(ride.start_at) }}

      el-col(:span="5")
        span {{ ride.start_docking_station.name }}

    el-row.center.details-bold(:gutter="50" type="flex" justify="center" style="margin-top: 40px")
      el-col(:span="5")
        span {{ $t('common.ride_duration') }}

      el-col(:span="5")
        span {{  $t('common.ride_price_paid') }}
      el-col(:span="5")
        span {{  $t('common.status') }}

    el-row.center(:gutter="50" type="flex" justify="center" style="margin-top: 10px")
      el-col(:span="5")
        span {{ computeRideDuration(ride.duration_seconds) }}

      el-col(:span="5")
        span {{ $n(ride.price, 'currency') + "/ \n" + $n(ride.already_paid_ammount, 'currency')  }}
      el-col(:span="5")
        .status-wrapper
          span {{ ride.status }}
        // TODO: handle these when status is resolved
        //.status-wrapper(v-if="inBetween('transactionsStatuses', transaction.status,  21, 40)")
        //  span.oval.completed
        //  span {{ transactionStatuses[transaction.status].message }}
        //
        //.status-wrapper(v-if="inBetween('transactionsStatuses', transaction.status, 41, 61)")
        //  span.oval.cancelled
        //  span {{ transactionStatuses[transaction.status].message }}
        //
        //.status-wrapper(v-if="inBetween('transactionsStatuses', transaction.status, 1, 20)")
        //  span.oval.pending
        //  span {{ transactionStatuses[transaction.status].message }}

    el-row(
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.TRANSACTION, ['show_history'])"
      :gutter="50" center style="margin: 24px 0; text-align:center;"
    )
      el-col
        span.font-weight-bolder {{ $t('transactions.transactions_history') }}

    .transactions-history-table
      el-table(
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.TRANSACTION, ['show_history'])"
        :data="transactionHistory"
        v-loading="loadingTable"
        :gutter="0"
        center
        height="250"
        style="width: 100%;"
      )
        el-table-column(
          width="50"
        )
        el-table-column(
          type="index"
          :index="indexMethod"
          :label="$t('transactions.transaction_number')"
        )
        el-table-column(
          prop="dateAndTime"
          :label="$t('transactions.date_and_time')"
        )
          template(v-slot='scope')
            span.pre-line {{ computeDateFormat(scope.row.created_at) }}
        el-table-column(
          prop="price"
          :label="$t('transactions.price')"
        )
          template(v-slot='scope')
            span {{ $n(scope.row.amount, 'currency') }}
        //el-table-column(
        //  prop="description"
        //  :label="$t('dialogs.transactions.error_details')"
        //)
        //  template(v-slot='scope')
        //    span(v-if="scope.row.description") {{ scope.row.description }}
        //    span(v-else) -
        el-table-column(
          prop="status"
          :label="$t('common.status')"
        )
          template(v-slot='scope')
            .status-wrapper
              span {{ transactionStatuses[scope.row.status].message }}
            // TODO: handle these when server is ready
            //.status-wrapper(v-if="inBetween('transactionHistoryStatuses', scope.row.status, 62, 63)")
            //  span.oval.completed
            //  span {{ transactionHistoryStatuses[scope.row.status].message }}
            //.status-wrapper(v-if="inBetween('transactionHistoryStatuses', scope.row.status, 64, 68)")
            //  span.oval.pending
            //  span {{ transactionHistoryStatuses[scope.row.status].message }}
            //.status-wrapper(v-if="inBetween('transactionHistoryStatuses', scope.row.status, 70, 72)")
            //  span.oval.unavailable
            //  span {{ transactionHistoryStatuses[scope.row.status].message }}
        //el-table-column(
        //  prop="type"
        //  :label="$t('label.type')"
        //)
        //  template(v-slot='scope')
        //    span {{ scope.row.type }}
        el-table-column(
          prop='actions'
          :label="$t('label.actions')"
        )
          //template(v-slot='scope')
          //  img.cursor-pointer.ml-2(
          //    v-if=`computePaymentActions(scope.row) &&
          //          (showItemDynamically(user, dynamicViewsConstants.resource_names.TRANSACTION, ['refund_payment']) ||
          //          showItemDynamically(user, dynamicViewsConstants.resource_names.TRANSACTION, ['capture_payment']))
          //    `
          //    src="@assets/transactions/force-payment.svg"
          //    @click="showViewPaymentActionDialog(); setTransaction(scope.row);"
          //  )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { transactionHistoryStatuses, transactionStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "ViewTransactionDetails",
  mixins: [filterUtils, routerUtils, general],

  data() {
    return {
      transactionHistory: [],
      transactionStatuses,
      transactionHistoryStatuses,
      inBetween,
      loadingTable: false,
      dynamicViewsConstants,
      customerObject: []
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState('transaction', ['transaction']),
    ...mapState('ride', ['ride']),
    ...mapState('customer', ['customer']),

    customerName() {
      return this.customerObject.first_name + " " + this.customerObject.last_name;
    },
  },

  async created() {
    await this.handleGetCustomer();
    await this.handleGetTransactionHistory()
  },

  methods: {
    ...mapMutations("dialog", [
      "showViewTransactionDetailsDialog",
      "hideViewTransactionDetailsDialog",
      "showViewPaymentActionDialog",
    ]),

    ...mapMutations("transaction", ["setTransaction"]),

    ...mapMutations("payments", ["setTransaction"]),

    ...mapActions("transaction", ["getTransactionsForARide"]),
    ...mapActions("customer", ["getCustomer"]),

    computePaymentActions(transaction) {
      let showBtn = false;

      if (transaction.type === "charge") {
        showBtn = true;
      }
      if (
          transaction.type === "refund" &&
          inBetween(null, transaction.status, 41, 60)
      ) {
        showBtn = true;
      }

      return showBtn;
    },

    // async handleGetTransactionHistory() {
    //   // TODO: remove this when testing is done
    //   // const history = await this.getTransactionHistory(this.transaction.id)
    //   const history = {
    //     "charge": {
    //       "type": "charge",
    //       "amount": 152,
    //       "amount_captured": 152,
    //       "amount_refunded": 75,
    //       "date": 1645035725,
    //       "status": "succeeded"
    //     },
    //     "refunds": [
    //       {
    //         "type": "refund",
    //         "amount": 45,
    //         "date": 1645079333,
    //         "status": "succeeded"
    //       },
    //       {
    //         "type": "canceled",
    //         "amount": 30,
    //         "date": 1645079194,
    //         "status": "succeeded"
    //       }
    //     ]
    //   }
    //
    //   // map the history into an array
    //   let mappedHistory = []
    //   let orderedMappedTransactionsByDate = []
    //
    //   // push the initial transaction
    //   mappedHistory.push(history.charge)
    //   mappedHistory = [...mappedHistory, ...history.refunds]
    //
    //   // order by date
    //   orderedMappedTransactionsByDate = _.orderBy(mappedHistory, 'date')
    //
    //   this.transactionHistory = orderedMappedTransactionsByDate
    // },

    async handleGetTransactionHistory() {
      let orderTransactionsByDate = [];
      this.history  = await this.getTransactionsForARide(this.ride.id);

      // order by date
      orderTransactionsByDate = _.orderBy(this.history, 'created_at');
      this.transactionHistory = orderTransactionsByDate;
    },

    async handleGetCustomer() {
      this.customerObject = await this.getCustomer(this.customer);
    },

    indexMethod(index) {
      return index + 1;
    }
  },
};
</script>

<style lang="scss" scoped>
.status-wrapper {
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    word-break: break-word;
  }

  .status {
    margin-left: 5px;
  }
}
</style>
