<template lang="pug">
  .bike-history
    el-table.bike_history_table(
      :data="bike_history"
      v-loading="loading"
      :gutter="0"
      center
      height="250"
      style="width: 100%;"
    )
      el-table-column(
        width="120"
      )
      el-table-column(
        width="180"
        prop="created_at"
        :label="$t('label.date')"
      )
        template(slot-scope='scope')
          span {{ computeDateFormat(scope.row.created_at) }}
      el-table-column(
        width="320"
        prop="customer"
        :label="$t('common.last_customer')"
      )
        template(slot-scope='scope')
          span.text-underline.cursor-pointer(@click="goToWIthId('customers', scope.row.customer.id); hideViewBikeDetailsDialog(); ") {{ scope.row.customer.first_name + " " + scope.row.customer.last_name }}
      el-table-column(
        prop="id"
        :label="$t('label.last_ride')"
      )
        template(slot-scope='scope')
          // TODO: handle opening on click the view ride details
          span.text-underline.cursor-pointer(@click="scope.row.id") {{ scope.row.id }}
    DialogTablePagination(
      :activeName="activeName"
      @viewData="handleGetData"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import DialogTablePagination from "@components/_shared/DialogTablePagination";
import { filterUtils } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { mapMutations } from "vuex";

export default {
  name: "BikeHistory",
  components: { DialogTablePagination },
  mixins: [filterUtils, routerUtils],

  props: {
    activeName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      bike_history: [],
    };
  },

  methods: {
    ...mapMutations("dialog", [
      "hideViewPasswordValidationDeleteDialog",
      "hideViewBikeDetailsDialog"
    ]),

    handleGetData(data) {
      this.bike_history = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>
