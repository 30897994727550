export const CostCentersViewValidations = {
  data() {
    return {
      addCostCenterRules: {
        name: [
          {
            required: true,
            message: this.$t("common.name"),
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            message: this.$t("common.company"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            validator: this.emailValidator,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: this.phoneNumberValidator,
            trigger: "blur",
          },
        ],
        comission: [
          {
            required: true,
            message: this.$t("cost_centers.validations.commissionFee"),
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: this.$t("cost_centers.validations.address"),
            trigger: "blur",
          },
        ],
        currency: [
          {
            required: true,
            message: this.$t("cost_centers.validations.select_currency"),
            trigger: "blur",
          },
        ],
        payment_processor: [
          {
            required: true,
            message: this.$t("cost_centers.validations.select_payment"),
            trigger: "blur",
          }
        ],
        unique_registration_code: [
          {
            required: false,
            validator: this.$t("cost_centers.validations.enter_cui"),
            trigger: "blur",
          }
        ],
        trade_register_number: [
          {
            required: false,
            validator: this.$t("cost_centers.validations.enter_commercial_register"),
            trigger: "blur",
          }
        ],
        bank_name: [
          {
            required: false,
            message: this.$t("cost_centers.validations.bank_not_selected"),
            trigger: "blur",
          }
        ],
      },
    };
  },
};
