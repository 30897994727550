<template lang="pug">
  .tickets-view
    h3 {{ $t('tickets.tickets') }}
    .filters
      // filter by date range
      FilterDatePicker(
        v-model="dateRange"

      )

      // filter by status
      FilterStatus(
        :name="$t('common.status_filter')"
        :statuses="ticketsStatuses"
        v-model="selectedStatus"
      )

      //filter by type
      //- FilterStatus(
      //-   :name="$t('common.type_filter')"
      //-   :statuses="handleGetArrayFromTypeStatuses()"
      //-   v-model="selectedType"
      //- )
      //- FilterCostCenterDropdown(v-model="selectedCostCenterId")

    .tickets-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
        )
      TicketsViewTable(
        v-loading="loadingTable"
        :tickets="tickets"
        :selectedStatus="selectedStatus"
      )
    // TODO: to update prop name activName to selectedStatus after all tabs will be deleted
    Pagination(
      v-if="selectedStatus && selectedStatus !== ''"
      :view="'tickets'"
      :dateRange="dateRange"
      :activeName="selectedStatus"
      :search="search"
      :statusType="selectedType"
      @viewData="handleGetTickets"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import FilterStatus from "@components/_shared/FilterStatus";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import TicketsViewTable from "@components/tickets/TicketsViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import { ticketsReports } from "@utils/statuses";

export default {
  mixins: [general],
  components: {
    Pagination,
    FilterCostCenterDropdown,
    SearchWithButtons,
    TicketsViewTable,
    FilterStatus,
    FilterDatePicker
  },

  data() {
    return {
      ticketsStatuses: [
        { name: "All", value: 'all' },
        { name: "Unresolved", value: 'unresolved' },
        { name: "In Progress", value: 'in_progress' },
        { name: "Resolved", value: 'resolved' },
      ],
      search: "",

      // filters
      dateRange: [],
      selectedCostCenterId: "",
      selectedStatus: "all",
      selectedType: "all",

      tickets: [],
      loadingTable: false,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    handleGetTickets(data) {
      this.tickets = data
    },

    handleLoading(loading) {
      this.loadingTable = loading
    },

    handleGetArrayFromTypeStatuses() {
      let arrayOfTypeStatuses = Object.keys(ticketsReports).map(key => {
        return {
          name: key,
          value: key
        }
      })

      arrayOfTypeStatuses.unshift({ name: 'All', value: 'all' })
      return arrayOfTypeStatuses
    }
  },
};
</script>

<style lang="scss" scoped>
.images {
  display: flex;
  align-items: center;

  .img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
  }

  .plus-icon {
    margin-left: 4px;
  }
}
</style>
