<template lang="pug">
  .add-billing-address
    el-form.login-form(
      :model="form"
      ref="SignUpForm"
      :rules="signUpViewValidations"
      @submit.native.prevent
      @keyup.enter.native="handleSignUp('SignUpForm')"
    )
      //- h2 {{ $t('auth.signUp') }}
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="12")
          el-form-item.mt-3(
            prop="last_name"
            :label="$t('common.customer_last_name')"
          )
            el-input(
              v-model="form.last_name"
              name="last_name"
            )
        el-col(:span="12")
          el-form-item.mt-3(
            prop="first_name"
            :label="$t('common.customer_first_name')"
          )
            el-input(
              v-model="form.first_name"
              name="first_name"
            )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col
          el-form-item.mt-3(
            prop="email"
            :label="$t('common.email_address')"
          )
            el-input(
              v-model="form.email"
              name="email"
            )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col
          el-form-item(
            prop="password"
            :label="$t('label.password')"
          )
            el-input(
              v-model="form.password"
              :show-password="true"
              name="password"
              type="password"
            )

    el-row.text-center
      el-button.dark-blue-btn(
        @click="handleSignUp('SignUpForm')"
        :disabled="disabledSignUpButton"
        :loading="loading"
      ) {{ $t('auth.signUp') }}
                

</template>

<script>

import { mapActions, mapMutations } from "vuex";
import { setObjectValues } from "@utils/generalUtils";
import { authenticationViewValidations } from "@utils/formValidations/authenticationViewValidations";
import { customValidations } from "@utils/customValidators";

export default {
  name: "SignUpComponent",
  mixins: [authenticationViewValidations, customValidations],

  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      },
      loading: false,
      setObjectValues
    }
  },
  computed: {
    disabledSignUpButton() {
      return this.form.email === "" || this.form.password === "" || this.form.first_name === "" || this.form.last_name === "";
    },
  },

  methods: {

    ...mapActions({
      signUp: "auth/signUp",
    }),

    handleSignUp(formName) {
      this.loading = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          const payload = this.form;
          this.signUp(payload)
              .then(() => {
                this.$router.push('/docking_stations_map');
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.loading = false;
        }
      });
    },

    clearForm() {
      this.setObjectValues(this.form, "");
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.login-view {
  display: flex;

  a {
    color: $dark-blue;
    font-family: "Avenir Next", sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
      flex-direction: column;
      margin-top: 100px;
  }
}

.add-billing-address {
  padding: 24px 0;

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
