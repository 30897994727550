<template lang="pug">
  .view-payment-actions.d-flex
    el-button.dark-blue-btn.view-tickets-btn(
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.TRANSACTION, ['capture_payment'])"
      @click="tryPaymentAgain"
      :disabled="disabledRetryAgainBtn"
      :class="{'disabled-btn': disabledRetryAgainBtn}"
      :loading="loadingTryAgain"
    )
      span(v-if="transaction.type === transactionsConstants.REFUND_TYPE && transaction.status === transactionsConstants.FAILED_BECAUSE_OF_SERVER_ERROR") {{ $t('dialogs.transactions.try_refund_again') }}
      span(v-else) {{ $t('dialogs.transactions.try_payment_again') }}
    .refund(v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.TRANSACTION, ['refund_payment'])")
      el-button.dark-blue-btn.view-tickets-btn(
        @click="refund(transactionsConstants.PARTIAL_REFUND)"
        :loading="loadingPartialRefund"
        :disabled="disabledPartialRefundBtn"
        :class="{'disabled-btn': disabledPartialRefundBtn}"
      ) {{ $t('dialogs.transactions.partial_refund') }}
      el-input-number(
        :controls="false"
        v-model="partialRefundAmount"
        :max="paymentTransaction.amount_captured - paymentTransaction.amount_refunded"
        :min="0"
        name="partialRefund"
      )
      span.maxim-refund {{ $t('transactions.input_maxim') }}: {{ paymentTransaction.amount_captured - paymentTransaction.amount_refunded }}
    el-button.dark-blue-btn.view-tickets-btn(
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.TRANSACTION, ['refund_payment'])"
      @click="refund(transactionsConstants.FULL_REFUND)"
      :loading="loadingFullRefund"
      :disabled="disabledFullRefundBtn"
      :class="{'disabled-btn': disabledFullRefundBtn}"
    ) {{ $t('dialogs.transactions.full_refund') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { inBetween } from "@utils/filterUtils";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants, transactionsConstants } from "@src/constants";

export default {
  name: "ViewPaymentActions",
  mixins: [general],

  data() {
    return {
      partialRefundAmount: 0,

      inBetween,
      loadingTryAgain: false,
      loadingPartialRefund: false,
      loadingFullRefund: false,
      disabledRetryAgainBtn: false,
      disabledPartialRefundBtn: false,
      disabledFullRefundBtn: false,
      dynamicViewsConstants,
      transactionsConstants
    };
  },

  computed: {
    ...mapState("transaction", ["transaction"]),
    ...mapState("payments", ["paymentTransaction"]),
    ...mapState("auth", ["user"]),
  },

  created() {
    this.computeRetryAgainBtn();
    this.computeRefundButtons();
  },

  methods: {
    ...mapMutations("dialog", ["hideViewPaymentActionDialog"]),
    ...mapActions("payments", ["retryPayment", "refundPayment"]),

    computeRefundButtons() {
      if (
        this.transaction.type === transactionsConstants.REFUND_TYPE &&
        inBetween("transactionsStatuses", 41, 60)
      ) {
        this.disabledPartialRefundBtn = true;
        this.disabledFullRefundBtn = true;
      }

      if (
        this.paymentTransaction.amount_captured -
          this.paymentTransaction.amount_refunded <
        this.paymentTransaction.amount_captured
      ) {
        this.disabledFullRefundBtn = true;
      }

      if (
        this.paymentTransaction.amount_captured ===
        this.paymentTransaction.amount_refunded
      ) {
        this.disabledFullRefundBtn = true;
        this.disabledPartialRefundBtn = true;
      }
    },

    computeRetryAgainBtn() {
      this.disabledRetryAgainBtn =
        this.transaction.status === transactionsConstants.SUCCESSFULLY_PAID_STATUS;

      if (
        this.transaction.status === transactionsConstants.SUCCESSFULLY_PAID_STATUS &&
        this.paymentTransaction.amount_captured ===
          this.paymentTransaction.amount_refunded
      ) {
        this.disabledRetryAgainBtn = true;
      }
    },

    async refund(refundType) {
      // TODO: to be tested when server is ready
      let params = {
        transaction_id: this.transaction.id,
        amount: 0
      }

      if (refundType === transactionsConstants.PARTIAL_REFUND) {
        this.loadingPartialRefund = true
        params.amount = this.partialRefundAmount

        this.refundPayment(params)
          .finally(() => {
            this.loadingPartialRefund = false
          })

      } else if (refundType === transactionsConstants.FULL_REFUND) {
        this.loadingFullRefund = true
        params.amount = this.paymentTransaction.amount_captured - this.paymentTransaction.amount_refunded

        this.refundPayment(params)
            .finally(() => {
              this.loadingFullRefund = false
            })
      }
    },

    async tryPaymentAgain() {
      // TODO: to be tested when server is ready
      await this.retryPayment(this.transaction.id)
    },
  },
};
</script>

<style lang="scss" scoped>
.view-payment-actions {
  flex-direction: column;
  align-items: center;

  .maxim-refund {
    margin-left: 20px;
  }

  .el-button + .el-button {
    margin-left: 0;
  }

  .refund {
    .el-input-number {
      margin-left: 20px;
      width: 80px;
    }
  }

  button {
    padding: 0 18.5px !important;
    line-height: 22px;
    height: 40px;
    margin-bottom: 24px;

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }
}
</style>
