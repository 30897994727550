import axios from "axios";
import messageUtils from "@utils/messageUtils";

export const actions = {
  async sendEmailOnSolvedTicket({ commit }, email) {
    try {
      await axios.post("/sendEmailOnSolvedTicket", { emailAddress: email });
    } catch (error) {
      messageUtils.showErrors(error.response.data.message);
    }
  },
};
