<template lang="pug">
  .date-filter
    span.filter-title {{ $t('common.begin_end_date') }}
    el-date-picker(
      v-model="dateRange"
      type="datetimerange"
      align="right"
      format="dd/MM/yyyy HH:mm"
      :start-placeholder="$t('common.start_date')"
      :end-placeholder="$t('common.end_date')"
      :picker-options="pickerOptions"
      :default-value="new Date()"
      @change="emitChangeEvent"
    )
</template>

<script>
import { dateRangeShortcuts, disabledFutureDates } from "@utils/dateRange";

export default {
  name: "FilterDatePicker",

  props: {
    value: {
      type: Array,
      default: "",
    },
  },

  computed: {
    dateRange: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return disabledFutureDates(time);
        },
        shortcuts: dateRangeShortcuts,
      },
    };
  },

  methods: {
    emitChangeEvent(values) {
      this.$emit('onChange', values)
    }
  }
};
</script>
