<template lang="pug">
  .cards-view
    h3 {{ "Carduri" }}
    .cards-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
        )
          el-button.dark-blue-btn(
            @click="handleOpenAddCardDialog"
          )
            h4 {{ $t('common.add') }}
      CardsViewTable(
        v-loading="loading"
        :cards="cards"
      )
    Pagination(
      :view="'cards'"
      @viewData="handleGetCards"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import CardsViewTable from "@components/cards/CardsViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapMutations, mapState } from "vuex";

export default {
  name: "CardsView",
  mixins: [general],

  components: {
    Pagination,
    SearchWithButtons,
    CardsViewTable,
  },

  data() {
    return {
      search: "",
      // filters
      dateRange: [],
      selectedCostCenterId: "",
      selectedType: "all",

      cards: [],
      loading: false,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {
    ...mapMutations("dialog", ["showAddCardDialog"]),

    handleGetCards(data){
      this.loading = true;
      this.cards = data.data;
      this.loading = false;
    },

    handleLoading(loading) {
      this.loading = loading;
    },

    handleOpenAddCardDialog() {
      this.showAddCardDialog();
    },
  },
};
</script>

