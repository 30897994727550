<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='transactions'
  )
    el-table-column(
      prop='start_at'
      :label="$t('common.invoice_date')"
    )
      template(slot-scope='scope')
        span.date-field {{ computeDateFormat(scope.row.start_at) }}xs
    //- el-table-column(
    //-   prop='start_docking_station_name'
    //-   :label="$t('common.cost_center')"
    //- )
    //-   template(slot-scope='scope')
    //-     // TODO: handle opening dialogs for the selected franchise
    //-     span.text-underline.cursor-pointer(@click="goToWIthId('franchises', scope.row.cost_center.id)") {{ scope.row.start_docking_station.name }}
    el-table-column(
      prop='duration_seconds'
      :label="$t('common.ride_duration')"
    )
      template(slot-scope='scope') {{ computeRideDuration(scope.row.duration_seconds) }}
    el-table-column(
      prop='price'
      :label="$t('common.ride_price_paid')"
    )
      template(slot-scope='scope') {{ $n(scope.row.price, 'currency') + "/ \n" + $n(scope.row.already_paid_ammount, 'currency') }}
    el-table-column(
      prop='status'
      :label="$t('common.status')"
    )
      template(slot-scope='scope')
        .status-wrapper.d-flex.align-items-center
          span {{ transactionStatuses[scope.row.status]? transactionStatuses[scope.row.status].message : '-' }}
        //.status-wrapper.d-flex.align-items-center(v-if="inBetween('transactionsStatuses', scope.row.status, 1, 20)")
        //  span.oval.pending
        //  span {{ transactionStatuses[scope.row.status] ? transactionStatuses[scope.row.status].message : '-' }}
        //
        //.status-wrapper.d-flex.align-items-center(v-if="inBetween('transactionsStatuses', scope.row.status, 21, 40)")
        //  span.oval.completed
        //  span {{ transactionStatuses[scope.row.status] ? transactionStatuses[scope.row.status].message : '-'}}
        //
        //.status-wrapper.d-flex.align-items-center(v-if="inBetween('transactionsStatuses', scope.row.status, 41, 61)")
        //  span.oval.cancelled
        //  span {{ transactionStatuses[scope.row.status] ? transactionStatuses[scope.row.status].message : '-' }}
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        img.cursor-pointer.ml-2(
          src="@assets/preview.svg"
          @click="handleViewTransactionDetails(scope.row)"
        )
</template>

<script>
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { transactionStatuses } from "@utils/statuses";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: 'TransactionsViewTable',
  mixins: [filterUtils, routerUtils, general],

  props: {
    transactions: {
      type: Array
    }
  },

  data() {
    return {
      transactionStatuses,
      inBetween,
      dynamicViewsConstants,
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {

    ...mapMutations("dialog", [
      "showViewTransactionDetailsDialog",
    ]),

    ...mapMutations("ride", ["setRide"]),
    ...mapMutations("customer", ["setCustomer"]),
    ...mapActions("dockingStation", ["getDock"]),

    handleViewTransactionDetails(ride) {
      this.showViewTransactionDetailsDialog();
      this.setRide(ride);
      this.setCustomer(ride.customer_id);
    },
  }
}
</script>
