import axios from "axios";
import router from "../router/index";
import store from "../state/store";

export default function axiosSetUp() {
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
  axios.interceptors.request.use(
    function (config) {
      // TODO: handle edge cases new URL structure, to be uncommented in the next release
      // if (config.url === '/sessions/token') {
      //   config.baseURL = process.env.VUE_APP_API_BASE_URL;
      // } else {
      //   config.baseURL = process.env.VUE_APP_API_BASE_URL + '/api/cms';
      // }

      const token = $cookies.get("access_token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        error.config.url !== "/v1/profiles/show_current" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        await store.dispatch("auth/refreshToken");
        return axios(originalRequest);
      } else if (
        error.response.status === 400 &&
        router.history.current.path !== "/login"
      ) {
        store.commit("auth/logout");
        await router.replace("/login");
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
}
