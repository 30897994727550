<template lang="pug">
  .add-billing-address
    el-form(
      :model="form"
      :rules="checkAddSmartCard"
      ref="AddBillingAddressForm"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center(:gutter="30")
        span {{ $t('smart_cards.recipient_information.recipient_information') }}
      div(style="border-top: 2px solid ;border-image: linear-gradient(to right,#ffffff,#F0F3F8,#ffffff)1 0 0 0 ;")
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="9")
          el-form-item(
            prop="full_name"
            :label="$t('smart_cards.recipient_information.name')"
          )
            el-input(
              readonly="readonly"
              disabled="disabled"
              v-model="customerFullName"
              name="full_name"
            )
        el-col(:span="9")
          el-form-item(
            prop="email"
            :label="$t('smart_cards.recipient_information.email')"
          )
            el-input(
              readonly="readonly"
              disabled="disabled"
              v-model="customerEmail"
              name="email"
            )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="9")
          el-form-item(
            prop="phone_number"
            :label="$t('smart_cards.recipient_information.phone_number')"
          )
            el-input(
              readonly="readonly"
              disabled="disabled"
              v-model="customerPhoneNumber"
              name="phone_number"
            )
        el-col(:span="9")
      el-row.d-flex.justify-content-center(:gutter="30")
        span {{ $t('smart_cards.add_shipping_address.add_shipping_address') }}
      div(style="border-top: 2px solid ;border-image: linear-gradient(to right,#ffffff,#F0F3F8,#ffffff)1 0 0 0 ;")
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="6")
          el-form-item(
            prop="county"
            :label="$t('smart_cards.add_shipping_address.county')"
          )
            el-input(
              :placeholder="$t('common.validations.shipping_address.placeholder_county')"
              v-model="form.county"
              name="county"
            )
        el-col(:span="7")
          el-form-item(
            prop="city"
            :label="$t('smart_cards.add_shipping_address.city')"
          )
            el-input(
              :placeholder="$t('common.validations.shipping_address.placeholder_city')"
              v-model="form.city"
              name="city"
            )
        el-col(:span="5")
          el-form-item(
            prop="postal_code"
            :label="$t('smart_cards.add_shipping_address.postal_code')"
          )
            el-input(
              type="number"
              :placeholder="$t('common.validations.shipping_address.placeholder_postal_code')"
              v-model.number="form.postal_code"
              name="postal_code"
              @keydown.native.space.prevent
              onkeypress='return event.charCode >= 48 && event.charCode <= 57'
            )
              el-col(:span="6")
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="8")
          el-form-item(
            prop="country"
            :label="$t('smart_cards.add_shipping_address.country')"
          )
            el-input(
              :placeholder="$t('common.validations.shipping_address.placeholder_country')"
              v-model="form.country"
              name="country"
            )
        el-col(:span="5")
          el-form-item(
            prop="cost_center_id"
            :label="$t('common.cost_center_name')"
          )
            el-select(
              v-model='form.cost_center_id'
              filterable
              :placeholder="$t('smart_cards.add_shipping_address.select_cost_center')"
              )
              el-option(
                v-for='value in cost_centers'
                :key='value.id'
                :label='value.name'
                :value='value.id'
              )
        el-col(:span="5")
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="18")
          el-form-item(
            prop="address_first_line"
            :label="$t('smart_cards.add_shipping_address.address_first_line')"
          )
            el-input(
              :placeholder="$t('common.validations.shipping_address.placeholder_address_first_line')"
              v-model="form.address_first_line"
              name="address_first_line"
            )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="18")
          el-form-item(
            prop="address_second_line"
            :label="$t('smart_cards.add_shipping_address.address_second_line')"
          )
            el-input(
              :placeholder="$t('common.validations.shipping_address.placeholder_address_second_line')"
              v-model="form.address_second_line"
              name="address_second_line"
            )


    el-row.text-center
      el-button.dark-blue-btn.pop-up-btn.mt-4(
        @click="handleAddSmartCard('AddBillingAddressForm')"
        :loading="loading"
      ) {{ $t('smart_cards.add_shipping_address.create_shipping_address_request') }}

</template>

<script>
import AddBillingAddress from "./card/AddBillingAddress";
import { mapActions, mapMutations } from "vuex";
import { setObjectValues } from "@utils/generalUtils";
import { smartCardViewValidations } from "@utils/formValidations/shippingAddressViewValidations";

export default {
  name: "AddSmartCard",
  mixins: [smartCardViewValidations],


  data() {
    return {
      initCardForm: false,

      card: '',

      intentToken: null,
      token: null,
      cost_centers: [],
      form: {
        full_name: "",
        email: "",
        phone_number: "",
        country: "",
        county: "",
        city: "",
        postal_code: "",
        address_first_line:"",
        address_second_line:"",
        cost_center_id: null
      },
      customerFullName: '',
      customerEmail: '',
      customerPhoneNumber: '',
      loading: false,
      setObjectValues
    }
  },
  components: {
    AddBillingAddress
  },

  async created() {
    await this.handleCustomerData();
    await this.handleGetCostCenters();
  },

  methods: {
    ...mapActions("costCenter", ["getCostCenters"]),
    ...mapActions("user", ["showCurrent"]),
    ...mapActions("smartCard", ["addSmartCard"]),
    ...mapMutations("dialog", ["hideAddSmartCardDialog"]),
    ...mapMutations("smartCard", ["setSmartCard", 'setShouldFetchSmartCards']),

    onlyNumeric() {
      this.inputNumber = this.inputNumber.replace(/[^0-9]/g, '');
    },

    async handleCustomerData(){
      const response = await this.showCurrent("");
      const customer = response.data.attributes

      this.customerFullName = `${customer.last_name} ${customer.first_name}`
      this.customerEmail = customer.email
      this.customerPhoneNumber = customer.phone_number

      this.form.full_name = `${customer.last_name} ${customer.first_name}`
      this.form.email = customer.email
      this.form.phone_number = customer.phone_number

    },

    handleAddSmartCard(formName){
      this.loading= true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if(valid) {
            this.form.postal_code = this.form.postal_code.toString();
            let params = { ...this.form };

            this.addSmartCard(params)    
              .then(() => {
                this.clearForm();
                this.setShouldFetchSmartCards(true);
              })
              .catch((error) => {
                // console.log(error)
              })
              .finally(() => {
                this.loading = false;
                this.handleCloseAddCardDialog();
              });
        } else {
          this.loading = false;
        }

      });
    },

    handleCloseAddCardDialog(){
      this.hideAddSmartCardDialog();
    },

    clearForm() {
      this.setObjectValues(this.form, "");
    },

    async handleGetCostCenters() {
      const response = await this.getCostCenters();
      for (let i = 0; i < response.data.length; i++) {
        if(response.data[i].attributes.is_smart_card_enabled == true)
        this.cost_centers.push({
          id: response.data[i].attributes.id,
          name: response.data[i].attributes.name
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-bike {
  padding: 24px 0;

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}

</style>
