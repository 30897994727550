<template lang="pug">
  .view-rent-tariff-details
    el-row.pb-3.px-5
      el-descriptions(direction="vertical")
        el-descriptions-item(:label="$t('label.initial_fee_initial_duration')") {{ $n(rentTariff.initial_fee, 'currency') }} / {{ rentTariff.initial_timeblock }}min.
        el-descriptions-item(:label="$t('label.secondary_fee_secondary_duration')") {{ $n(rentTariff.secondary_fee, 'currency') }} / {{ rentTariff.secondary_timeblock }}min.
    .rent-tariff-details-modal
      el-table(
        :data="filteredDocks"
        :gutter="0"
        center
        height="250"
        style="width: 100%;"
      )
        el-table-column(
          prop="''"
          label=""
          width="100"
        )
        el-table-column(
          prop="name"
          :label="$t('common.dock_name')"
          :span="8"
        )
        el-table-column(
          prop="franchiseID"
          :label="$t('common.cost_center_name')"
          :span="8"
        )
        el-table-column(
          prop="availableBikesNumber"
          :label="$t('dialogs.rent_tariffs.bikes_available')"
          :span="8"
        )
        el-table-column(
          prop="workingStation"
          :label="$t('common.status')"
          :span="8"
        )
          template(slot-scope='scope')
            .status-wrapper.d-flex(v-if="inBetween(scope.row.status, 21, 40)")
              span.oval.completed
              span {{ dockingStationsStatuses[scope.row.status] }}

            .status-wrapper.d-flex(v-if="inBetween(scope.row.status, 41, 60)")
              span.oval.unavailable
              span {{ dockingStationsStatuses[scope.row.status] }}
            .status-wrapper.d-flex(v-if="inBetween(scope.row.status, 1, 20)")
              span.oval.unavailable
              span {{ dockingStationsStatuses[scope.row.status] }}
</template>

<script>
import { mapState } from "vuex";
import { dockingStationsStatuses } from "@utils/statuses";
import { inBetween } from "@utils/filterUtils";

export default {
  name: "ViewRentTariffDetails",

  data() {
    return {
      docks: [],
      filteredDocks: [], // TODO: fill this when server is ready
      dockingStationsStatuses,
      inBetween,
    };
  },

  computed: {
    ...mapState("rentTariff", ["rentTariff"]),
  },
};
</script>

<style lang="scss" scoped>
.view-bike-details {
  .details-bold .el-col span {
    font-weight: bolder;
  }
}

.rent-tariff-details-modal {
  .el-dialog__body {
    padding: 0;
  }

  .status-wrapper {
    align-items: center;
  }
}
</style>
