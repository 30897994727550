<template lang="pug">
    .view-device-info-details
      div(v-for="info in deviceInfo.data")
        el-row.center.details.mt-4(:gutter="50" type="flex" justify="center")
          el-col
              span {{ $t('common.device_model') }}
          el-col
              span {{ $t('common.device_type') }}
          el-col
              span {{ $t('common.device_build') }}
          el-col
              span {{ $t('common.device_os') }}
        el-row.center.text-normal(:gutter="50" type="flex" justify="center" style="margin-top: 10px")
          el-col
            span(v-if="info.attributes.device_attributes.model_type") {{ info.attributes.device_attributes.model }}
            span(v-else) -
          el-col
            span(v-if="info.attributes.device_attributes.model_type") {{ info.attributes.device_attributes.model_type }}
            span(v-else) -
          el-col
            span(v-if="info.attributes.device_attributes") {{ info.attributes.device_uuid }}
            span(v-else) -
          el-col
            span(v-if="info.attributes.device_attributes.os_version") {{ info.attributes.device_attributes.os_version }}
            span(v-else) -
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ViewDeviceInfo",

  computed: {
    ...mapState("customer", ["deviceInfo"]),
  }
};
</script>
