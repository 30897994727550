<template lang="pug">
  .add-billing-address
    el-form.login-form(
      :model="form"
      ref="LoginForm"
      :rules="loginViewValidations"
      @submit.native.prevent
      @keyup.enter.native="handleLogin('LoginForm')"
    )
      //- h3 {{ $t('auth.login') }}
      el-form-item.mt-3(
        prop="email"
        :label="$t('common.email_address')"
      )
        el-input(
          v-model="form.email"
          name="email"
        )
      el-form-item.mt-3(
        prop="password"
        :label="$t('label.password')"
      )
        el-input(
          v-model="form.password"
          :show-password="true"
          name="password"
          type="password"
        )
      el-row.text-right
        a(@click="goToForgotPassword") {{ $t('auth.forgot_password') }}
    el-row.text-center
      el-button.dark-blue-btn(
        @click="handleLogin('LoginForm')"
        :disabled="disabledButton"
        :loading="loading"
      ) {{ $t('auth.login') }}
                

</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { setObjectValues } from "@utils/generalUtils";
import { authenticationViewValidations } from "@utils/formValidations/authenticationViewValidations";
import { customValidations } from "@utils/customValidators";

export default {
  name: "LoginComponent",
  mixins: [authenticationViewValidations, customValidations],

  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      },
      loading: false,
      setObjectValues
    }
  },
  computed: {
    disabledButton() {
      return this.form.email === "" || this.form.password === "";
    },
  },

  methods: {
    ...mapActions({
      login: "auth/login",
    }),

    handleLogin(formName) {
      this.loading = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          const payload = this.form;
          this.login(payload)
              .then(() => {
                this.$router.push('/docking_stations_map');
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.loading = false;
        }
      });
    },

    clearForm() {
      this.setObjectValues(this.form, "");
    },

    goToForgotPassword() {
      this.$router.push({ name: "forgot_password" });
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.login-view {
  display: flex;

  a {
    color: $dark-blue;
    font-family: "Avenir Next", sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
      flex-direction: column;
      margin-top: 100px;
  }
}

// .add-billing-address {
  // padding: 24px 0;

  // .el-date-editor.el-input,
  // .el-date-editor.el-input__inner {
  //   width: 100%;
  // }
// }
</style>
