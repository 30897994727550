import axios from "axios";
import messageUtils from "@utils/messageUtils";

import Jsona from "jsona";
import i18n from "@i18n";

const dataFormatter = new Jsona();

export const state = {
  configSetting: {},
  shouldFetchConfSettings: false,
};

export const actions = {
  async getAllConfigSettings({ commit }, params) {
    try {
      const response = await axios.get(`/v1/settings${params}&is_global=true`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateConfSettings({ commit }, payload) {
    try {
      await axios.patch(`/v1/settings/${payload.id}`, {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  setConfigSetting(state, payload) {
    state.configSetting = payload;
  },

  setShouldFetchConfSettings(state, value) {
    state.shouldFetchConfSettings = value;
  },
};
