<template lang="pug">
  .add-bulk
    el-form(
      :model="form"
      ref="AddBulkForm"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center
        el-col(:span="18").title
          span {{ $t('dialogs.inventory.add_bikes_message') }}
          .template_message-wrapper
            span {{ $t('dialogs.inventory.download_template_message') }}
            a.doc-example.d-flex(
              :href="xlsxDownload"
              target="_blank"
            )
              img(src="@src/assets/inventory/download.svg")
              span xlsx
            a.doc-example.d-flex(
              :href="cvsDownload"
              target="_blank"
            )
              img(src="@src/assets/inventory/download.svg")
              span cvs
          .upload-wrapper.d-flex
            label(
              for="file-upload"
            )
              img(src="@src/assets/inventory/upload.svg").cursor-pointer
              span.file-name {{ uploadFileName }}
            input(
              @change="selectFile"
              id="file-upload"
              type="file"
            )
      el-row.text-center
        el-button.pop-up-btn(
          :class="form.file ? 'dark-blue-btn' : 'gray-btn'"
          @click="handleAddBulk('AddBulkForm')"
          :loading="loading"
        ) {{ $t('common.add_bikes') }}
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "AddBulk",

  data() {
    return {
      xlsxDownload:
        "https://firebasestorage.googleapis.com/v0/b/ciclo-deployed.appspot.com/o/bulkBikesTemplates%2FGOOD_XLSX-EXAMPLE-2.xlsx?alt=media&token=c6b4147b-0d21-4766-94cf-551815317863",
      cvsDownload:
        "https://firebasestorage.googleapis.com/v0/b/ciclo-deployed.appspot.com/o/bulkBikesTemplates%2FGOOD_XLSX-EXAMPLE-2.csv?alt=media&token=b06c7144-59fa-4b57-951c-519293e7eba3",
      form: {
        file: undefined,
      },
      uploadFileName: this.$t("dialogs.inventory.upload_file"),
      loading: false,
    };
  },

  methods: {
    ...mapMutations("dialog", ["hideAddBulkDialog"]),

    ...mapActions("bike", ["addBikesBulk"]),

    ...mapMutations("bike", ["setShouldFetchBikes"]),

    clearForm() {
      let fileInput = document.querySelector("#file-upload");
      fileInput.value = "";
      this.form.file = undefined;
      this.uploadFileName = this.$t("dialogs.inventory.upload_file");
    },

    selectFile(e) {
      if (e.target.files[0] === undefined) {
        this.form.file = undefined;
        this.uploadFileName = this.$t("dialogs.inventory.upload_file");
        return;
      }

      this.form.file = e.target.files[0];
      const file = document.querySelector("#file-upload");
      this.uploadFileName = file.value
        .split("\\")
        [file.value.split("\\").length - 1].toString();
    },

    isFileTypeValid() {
      let allowedTypes = /(\.xlsx|\.csv)$/i;
      return allowedTypes.exec(this.form.file.name);
    },

    handleAddBulk(formName) {
      this.loading = true;
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          if (this.form.file !== undefined) {
            if (this.isFileTypeValid()) {
              const formData = new FormData();
              formData.append("file", this.form.file, this.form.file.name);
              formData.append("name", this.form.file.name);
              this.addBikesBulk(formData)
                .then(() => {
                  this.loading = false;
                  this.hideAddBulkDialog();
                  this.setShouldFetchBikes(true);
                  this.clearForm();
                })
                .finally(() => {
                  this.loading = false;
                });
            } else {
              this.$message({
                type: "warning",
                message: this.$t("dialogs.inventory.invalid_filename"),
              });
              this.clearForm();
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        } else {
          this.clearForm();
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-bulk {
  padding: 24px 0;

  .doc-example {
    align-items: center;
    height: 20px;
    width: 54px;
    border-radius: 3px;
    margin-right: 5px;
    justify-content: center;
    text-decoration: none;

    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }

    span {
      font-size: 13px;
    }
  }

  .el-button {
    line-height: 22px;
    height: 40px;
    width: fit-content;
    padding: 0 30px !important;
  }

  .title > span {
    display: block;
    margin-bottom: 12px;
  }

  .title {
    text-align: center;
    word-break: break-word;

    .template_message-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
    }

    .template_message-wrapper > span {
      margin: 0 5px;
    }

    .upload-wrapper {
      margin-bottom: 25px;
      justify-content: center;
      flex-direction: column;

      .file-name {
        font-weight: 600;
        text-decoration: underline;
        line-height: 25px;
        margin-top: 5px;
      }

      span {
        display: block;
      }

      input {
        display: none;
      }
    }
  }
}
</style>
