export const sideMenuMixins = {
  methods: {
    toggleSideMenu(isMenuOpen) {
      let pageContainer = document.querySelector('.router-wrapper');
      let menuList = document.querySelector('.el-menu-wrapper');
      let arrow = document.querySelector('.nav-arrow');

      let restrictedPages = [
        'forgot_password',
        'login'
      ]

      if (pageContainer) {
        if (!restrictedPages.includes(this.$route.name)) {
          menuList.style.visibility = isMenuOpen ? 'visible' : 'hidden';
          document.querySelector('.nav-profile-wrapper').style.visibility = isMenuOpen ? '' : 'hidden';
          arrow.style.visibility = 'visible';
          pageContainer.style.marginLeft = isMenuOpen ? '350px' : '40px';
          pageContainer.style.width = isMenuOpen ? 'calc(100% - 320px)' : 'calc(100% - 110px)'
        } else {
          pageContainer.style.marginLeft = '0'
          pageContainer.style.width = '100%'
        }
      }
    }
  }
}
