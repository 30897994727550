<template lang="pug">
  .pagination
    el-pagination.mt-3.mb-3.pages-custom-pagination(
      background
      layout="sizes, prev, pager, next"
      :current-page.sync="currentPage"
      :total="totalCount"
      :page-sizes="[10, 50, 100]"
      :page-size="pageSize"
      v-if="totalCount >= 1"
      @current-change="handleGetData"
      @size-change="handleChangePageSize"
    )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import _ from "lodash";

export default {
  props: {
    view: {
      type: String,
      required: true,
    },

    activeName: {
      type: String,
      required: false,
      default: null,
    },

    search: {
      type: String,
      required: false,
    },

    dateRange: {
      type: Array,
      required: false,
    },

    costCenterId: {
      type: String,
      required: false,
    },

    statusType: {
      type: String,
      required: false,
    }
  },

  computed: {
    ...mapState("passwordValidationDelete", ["loading", "allowDeletion"]),
    ...mapState("bike", ["shouldFetchBikes"]),
    ...mapState("costCenter", ["shouldFetchCostCenters", "shouldFetchCostCenterSettings", "costCenter"]),
    ...mapState("rentTariff", ["shouldFetchTariffs"]),
    ...mapState("dockingStation", ["shouldFetchDocks", "shouldFetchDockSettings", "dockingStation"]),
    ...mapState("ticket", ["shouldFetchTickets"]),
    ...mapState("smartCard", ["shouldFetchSmartCards"]),
    ...mapState("card", ["shouldFetchCards"]),
    ...mapState("customer", ["shouldFetchCustomers", "customer", "shouldFetchCustomerTickets"]),
    ...mapState("log", ["shouldFetchLogs"]),
    ...mapState("configSettings", ["shouldFetchConfSettings"])
  },

  watch: {
    activeName() {
      this.currentPage = 1;
      this.handleGetData();
    },

    search() {
      this.searchDebounce();
    },

    costCenterId() {
      this.handleGetData();
    },

    statusType() {
      this.handleGetData()
    },

    handleGetDataLoading() {
      this.$emit("handleTableLoading", this.handleGetDataLoading);
    },

    loading() {
      if (this.loading === false && this.allowDeletion === true) {
        this.handleGetData();
      }
    },

    dateRange() {
      this.handleGetData();
    },

    // TODO: refactor these in the next tech debt
    shouldFetchBikes() {
      if (this.shouldFetchBikes) {
        this.handleGetData();
        this.setShouldFetchBikes(false);
      }
    },

    shouldFetchCostCenters() {
      if (this.shouldFetchCostCenters) {
        this.handleGetData();
        this.setShouldFetchCostCenters(false);
      }
    },

    shouldFetchTariffs() {
      if (this.shouldFetchTariffs) {
        this.handleGetData();
        this.setShouldFetchTariffs(false);
      }
    },

    shouldFetchDocks() {
      if (this.shouldFetchDocks) {
        this.handleGetData();
        this.setShouldFetchDocks(false);
      }
    },

    shouldFetchTickets() {
      if (this.shouldFetchTickets) {
        this.handleGetData();
        this.setShouldFetchTickets(false);
      }
    },

    shouldFetchCards(){
      if(this.shouldFetchCards) {
        this.handleGetData();
        this.setShouldFetchCards(false);
      }
    },

    shouldFetchSmartCards(){
       if(this.shouldFetchSmartCards) {
        this.handleGetData();
        this.setShouldFetchSmartCards(false);
      }
      
    },

    shouldFetchCustomers() {
      if(this.shouldFetchCustomers) {
        this.handleGetData();
        this.setShouldFetchCustomers(false);
      }
    },

    shouldFetchCustomerTickets() {
      if (this.shouldFetchCustomerTickets) {
        this.handleGetData();
        this.setShouldFetchCustomerTickets(false);
      }
    },

    shouldFetchLogs() {
      if (this.shouldFetchLogs) {
        this.handleGetData();
        this.setShouldFetchLogs(false);
      }
    },

    shouldFetchCostCenterSettings(){
      if (this.shouldFetchCostCenterSettings) {
        this.handleGetData();
        this.setShouldFetchCostCenterSettings(false);
      }
    },

    shouldFetchDockSettings(){
      if(this.shouldFetchDockSettings){
        this.handleGetData();
        this.setShouldFetchDockSettings(false);
      }
    },

    shouldFetchConfSettings(){
      if(this.shouldFetchConfSettings){
        this.handleGetData();
        this.setShouldFetchConfSettings(false);
      }
    },
  },

  data() {
    return {
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      handleGetDataLoading: false,
    };
  },

  mounted() {
    this.pageSize = Number(localStorage.getItem('pageSize')) || 10
    this.handleGetData();
  },

  methods: {
    ...mapMutations("bike", ["setShouldFetchBikes"]),
    ...mapMutations("card", ["setShouldFetchCards"]),
    ...mapMutations("costCenter", ["setShouldFetchCostCenters"]),
    ...mapMutations("rentTariff", ["setShouldFetchTariffs"]),
    ...mapMutations("dockingStation", ["setShouldFetchDocks", "setShouldFetchDockSettings"]),
    ...mapMutations("ticket", ["setShouldFetchTickets"]),
    ...mapMutations("smartCard", ["setShouldFetchSmartCards"]),
    
    ...mapMutations('customer', ["setShouldFetchCustomers"]),
    ...mapMutations('log', ["setShouldFetchLogs"]),
    ...mapMutations('configSettings', ["setShouldFetchConfSettings"]),

    ...mapActions("card", ["getCards"]),
    ...mapActions("costCenter", ["getCostCenters", "getCostCenterSettings"]),
    ...mapActions("user", ["getUsers"]),
    ...mapActions("bike", ["getBikes", "getBikesInventory"]),
    ...mapActions("rentTariff", ["getTariffs"]),
    ...mapActions("dockingStation", ["getDocks", "getDockingStationSettings"]),
    ...mapActions("ride", ["getRides", "getAllRides"]),
    ...mapActions("customer", ["getCustomers", "getCustomerRideHistory", "getCustomerTickets"]),
    ...mapActions("ticket", ['getTickets']),
    ...mapActions('smartCard', ['getSmartCards']),
    ...mapActions("log", ['getLogs']),
    ...mapActions("transaction", ['getTransactions']),
    ...mapActions("configSettings", ['getAllConfigSettings']),

    computedParams() {
      let computedParams = `?page=${this.currentPage}`;

      // if a tab is selected
      if (this.activeName && this.activeName !== "") {
        if (this.view === "users") {
          computedParams += `&by_role_name=${this.activeName}`;
        }

        if (this.view === "rides") {
          if (this.activeName !== "all") {
            this.activeName === 'flagged' ? computedParams += `&is_flagged=true` : computedParams += `&by_status=${this.activeName}`
          }
        } else if (this.activeName !== "all") { // OTHER VIEWS: BIKES, TICKETS
          computedParams += `&by_status=${this.activeName}`;
        }
      }

      if (this.search) {
        computedParams += `&by_keyword=${this.search}`;
      }

      if (this.costCenterId) {
        computedParams += `&by_cost_center_id=${this.costCenterId}`;
      }

      // by status type - tickets
      if (this.statusType && this.statusType !== 'all') {
        computedParams += `&by_status_type=${this.statusType}`
      }

      // add page size
      computedParams += `&page_size=${this.pageSize}`

      if (this.dateRange && this.dateRange.length > 0) {
        let dateRangeComputed = {
          start_date: this.dateRange[0],
          end_date: this.dateRange[1],
        };

        computedParams += `&by_date_range[start_date]=${dateRangeComputed.start_date}&by_date_range[end_date]=${dateRangeComputed.end_date}`;
      }

      // ADDITIONAL CUSTOM PARAMETERS, ex: {params, id}
      // for the endpoints with more complex params
      if (this.view === 'customerRideHistory' || this.view === 'customerTickets') {
        return {
          params: computedParams,
          id: this.$route.params.id
        }
      } else {
        // for the endpoints with just params
        return { params: computedParams };
      }
    },

    async handleGetData() {
      this.handleGetDataLoading = true;
      let computedParams = this.computedParams();

      const response = await this.filteredGetActionByView(computedParams);
      this.emitDataToView(response);

      this.handleGetDataLoading = false;
    },

    async filteredGetActionByView(computedParams) {
      switch (this.view) {
        case "cards":
          return await this.getCards(computedParams.params);
        case "users":
          return await this.getUsers(computedParams.params);
        case "cost_centers":
          return await this.getCostCenters(computedParams.params);
        case "bikes":
          return await this.getBikes(computedParams.params);
        case "inventory":
          return await this.getBikesInventory(computedParams.params);
        case "tariffs":
          return await this.getTariffs(computedParams.params)
        case "docksList":
          return await this.getDocks(computedParams.params)
        case "rides":
          return await this.getRides(computedParams.params)
        case "customers":
          return await this.getCustomers(computedParams.params)
        case "tickets":
          return await this.getTickets(computedParams.params)
        case "smart_cards":
          return await this.getSmartCards(computedParams.params)
        case "logs":
          return await this.getLogs(computedParams.params)
        case "transactions":
          return await this.getTransactions(computedParams.params)
        case "cost_center_settings":
          let costPayload = {
            id: this.costCenter.id,
            params: computedParams.params
          };
          return await this.getCostCenterSettings(costPayload);
        case "dock_station_settings":
          let dockPayload = {
            id: this.dockingStation.id,
            params: computedParams.params
          };
          return await this.getDockingStationSettings(dockPayload);
        case "admin_settings":
          return await this.getAllConfigSettings(computedParams.params);

          // ADDITIONAL CUSTOM PARAMETERS, ex: {params, id}
        case "customerRideHistory":
          return await this.getCustomerRideHistory(computedParams)
        case "customerTickets":
          return await this.getCustomerTickets(computedParams)
      }
    },

    emitDataToView(response) {
      let data = response.data;
      this.totalCount = Number(response.headers["total-count"]);

      this.$emit("viewData", data);
    },

    searchDebounce: _.debounce(function () {
      this.currentPage = 1;
      this.handleGetData();
    }, 300),

    async handleChangePageSize(newPageSize) {
      localStorage.setItem('pageSize', newPageSize)

      this.pageSize = newPageSize;
      await this.handleGetData()
    }
  },
};
</script>
