export const getRomaniaCoordsIfLocationIsOff = () => {
  return navigator.permissions.query({name: 'geolocation'})
    .then((permissionStatus) => {
      if (permissionStatus.state !== 'granted') {
        return {
          coords: {
            latitude: 45.9432,
            longitude: 24.9668,
          }
        }
      }
    })
}
