<template lang="pug">
  div
    img(
      width="500"
      height="500"
      :src="handleQrCode"
  )
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "QrPopUp",

  data() {
    return {
      seeQrCode: false
    }
  },

  computed:{
    ...mapState("dockingStation", ["qrCode"]),

    handleQrCode(){
      let qrCode = this.qrCode.substring(38);
      return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(qrCode)}`;
    }
  },
}
</script>