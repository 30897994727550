import i18n from "@i18n";

export const billingAddressViewValidations = {
  data() {
    return {
      checkAddBillingAddress: {
        first_name: [
          {
            required: true,
            message: i18n.t("common.validations.billing_address.enter_first_name"),
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: i18n.t("common.validations.billing_address.enter_last_name"),
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: i18n.t("common.validations.billing_address.enter_country"),
            trigger: "blur",
          },
        ],
        county: [
          {
            required: true,
            message: i18n.t("common.validations.billing_address.enter_county"),
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: i18n.t("common.validations.billing_address.enter_city"),
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: i18n.t("common.validations.billing_address.enter_address"),
            trigger: "blur",
          },
        ],
        postal_code: [
          {
            required: true,
            message: i18n.t("common.validations.billing_address.enter_postal_code"),
            trigger: "blur",
          },
        ],
      },

    };
  },
};
