import DockingStationsMapView from "@views/DockingStationsMapView";
import TicketsView from "@views/TicketsView";
import CardsView from "@views/CardsView";
import TransactionsView from "@views/TransactionsView";
import RidesView from "@views/RidesView";
import SettingsView from "@views/SettingsView";
import LoginView from "@views/auth/LoginView";
import SmartCardsView from "@views/SmartCardsView";
import { dynamicViewsConstants } from "@src/constants";
import ForgotPasswordView from "@views/auth/ForgotPasswordView.vue";
import ChangePasswordView from "@views/auth/ChangePasswordView";

export default [
  {
    path: "/",
    redirect: { name: "tickets" },
    requiresAuth: false,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/docking_stations_map",
    name: "docking_stations_map",
    component: DockingStationsMapView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.DOCKING_STATION,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/tickets",
    name: "tickets",
    component: TicketsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.TICKET,
      requiresSuperAdmin: false,
    },
  },

  {
    path: "/cards",
    name: "cards",
    component: CardsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.CARD,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/smart_cards",
    name: "smart_cards",
    component: SmartCardsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.SMART_CARD,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/transactions",
    name: "transactions",
    component: TransactionsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.TRANSACTION,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/rides",
    name: "rides",
    component: RidesView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.RIDE,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/forgot_password",
    name: "forgot_password",
    component: ForgotPasswordView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/change_password",
    name: "change_password",
    component: ChangePasswordView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
    },
  },
];
