import _ from "lodash";
import store from "@state/store";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";

// MIXINS
export const currencyOptions = {
  data() {
    return {
      currencyOptions: [
        {
          label: this.$t("label.euro.label"),
          value: this.$t("label.euro.value"),
        },
        {
          label: this.$t("label.usd.label"),
          value: this.$t("label.usd.value"),
        },
        {
          label: this.$t("label.ron.label"),
          value: this.$t("label.ron.value"),
        },
        {
          label: this.$t("label.chz.label"),
          value: this.$t("label.chz.value"),
        },
        {
          label: this.$t("label.gbp.label"),
          value: this.$t("label.gbp.value"),
        },
      ],

      paymentOptions: [
        {
          label: this.$t("label.stripe.label"),
          value: this.$t("label.stripe.value"),
        },
        {
          label: this.$t("label.eu_platesc.label"),
          value: this.$t("label.eu_platesc.value"),
        },
      ]
    };
  },
};

export const oneYearFromNow = new Date(
  new Date().setFullYear(new Date().getFullYear() + 1)
);

// set obj. to a new set of values
export const setObjectValues = (obj, val) => {
  if (obj) {
    Object.keys(obj).forEach((k) => {
      obj[k] = val;
    });
  }
};

export const chartOptions = {
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        position: "top", // top, center, bottom
      },
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: true,
    formatter(val, { seriesIndex }) {
      if (seriesIndex === 0 || seriesIndex === 2) {
        // first and last cols
        return `${val} RON`;
      } else {
        // middle col
        return `${val} Ore`;
      }
    },
    offsetY: -20,
    style: {
      fontSize: "12px",
      colors: ["#304758"],
    },
  },
  stroke: {
    show: true,
    width: 3,
    colors: ["transparent"],
  },

  xaxis: {
    categories: [],
  },
  yaxis: {
    title: {
      text: "$",
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter(val, { series, seriesIndex, dataPointIndex, w }) {
        if (seriesIndex === 1) {
          const duration = val * 60000;

          let seconds = Math.floor((duration / 1000) % 60);
          let minutes = Math.floor((duration / (1000 * 60)) % 60);
          let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

          hours = hours < 10 ? `0${hours}` : hours;
          minutes = minutes < 10 ? `0${minutes}` : minutes;
          seconds = seconds < 10 ? `0${seconds}` : seconds;

          return `${hours}h:${minutes}m:${seconds}s`;
        }
        return `${val}`;
      },
    },
  },
};

export const upperCaseFirstLetters = (string) => {
  return _.startCase(_.toLower(string));
};

export const computeSelectedCostCenterId = (selectedCostCenterId) => {
  if (checkIfNotSuperAndAdministrator()) {
    return `?by_cost_center_id=${store.state.auth.user.selected_cost_center.id}`;
  } else {
    return selectedCostCenterId !== ""
      ? `?by_cost_center_id=${selectedCostCenterId}`
      : "";
  }
};

export const computeDateRange = (dateRange) => {
  return dateRange && dateRange.length > 0
    ? `by_date_range[start_date]=${dateRange[0]}&by_date_range[end_date]=${dateRange[1]}`
    : "";
};

export const computeDateRangeWithSelectedCostCenterId = (
  selectedCostCenterId,
  dateRange
) => {
  const computedCostCenterId =
    computeSelectedCostCenterId(selectedCostCenterId);
  const computedDateRange = computeDateRange(dateRange);

  return computedCostCenterId !== ""
    ? computedCostCenterId + `&${computedDateRange}`
    : `?${computedDateRange}`;
};

export const joinValuesWithComma = (valuesArray) => {
  return valuesArray.join(", ");
};
