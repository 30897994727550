<template lang="pug">
  div
    img(:src="require('@assets/dockingStationsMap/'+ icon_name +'.svg')")
    .statistic-info
      h1 {{ data }}
      span {{ text }}
</template>

<script>
export default {
  props: ["data", "text", "icon_name"],
};
</script>

<style lang="scss" scoped>
@import "@variables";

.statistic-info {
  margin-left: 16px;

  h1 {
    font-size: 32px !important;
    line-height: 44px;
    font-family: $avenir-next-regular;
  }

  span {
    color: #7f7f7f;
  }
}
</style>
