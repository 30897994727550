<template lang="pug">
  div
    el-menu-item(
      route index='/docking_stations_map'
    )
      template(slot='title')
        router-link.sub-menu-link(to="/docking_stations_map")
          .img-wrapper
            img(src="@src/assets/sidebar/stations.svg")
          span  {{ $t('sidebar.docking_stations_map') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";


export default {
  name: "DockingStationsMapItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants
    }
  }
}
</script>
