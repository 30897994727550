<template lang="pug">
  el-table(
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='tickets'
  )
    el-table-column(
      prop='status'
      :label="$t('common.status')"

    )
      template(slot-scope='scope')
        .status-wrapper.d-flex.align-items-center(v-if="inBetween('ticketsStatuses', scope.row.status, 41, 60)")
          span.oval.cancelled
          span {{ ticketsStatuses[scope.row.status].message }}
        .status-wrapper.d-flex.align-items-center(v-if="inBetween('ticketsStatuses', scope.row.status, 1, 20)")
          span.oval.pending
          span {{ ticketsStatuses[scope.row.status].message }}
        .status-wrapper.d-flex.align-items-center(v-if="inBetween('ticketsStatuses', scope.row.status, 21, 40)")
          span.oval.completed
          span {{ ticketsStatuses[scope.row.status].message }}
    el-table-column(
      prop='status_type'
      :label="$t('label.type')"
    )
      template(slot-scope='scope')
        span(v-if="ticketsReports[scope.row.ticket_type_object.label]") {{ ticketsReports[scope.row.ticket_type_object.label].message }}
        span(v-else="") {{ scope.row.ticket_type_object.label }}
    el-table-column(
      prop='created_at'
      :label="$t('common.time_of_report')"
    )
      template(slot-scope='scope')
        span {{ computeDateFormat(scope.row.created_at) }}
    el-table-column(
      prop='description'
      :label="$t('label.description')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.description && scope.row.description.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.description"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.description) }}
        span(v-if="scope.row.description && scope.row.description.length <= MAX_FIELD_LENGTH") {{ scope.row.description }}
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
      width="360px"
    )
      template(slot-scope='scope')
        .d-flex(:key="actionsButtonKey")
          img.cursor-pointer.mr-2(
           
            src="@assets/preview.svg"
            @click="handleViewTicketDetails(scope.row)"
          )
</template>

<script>
import { filterUtils, inBetween, MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { ticketsReports, ticketsStatuses } from "@utils/statuses";
import { routerUtils } from "@utils/router";
import { mapActions, mapMutations, mapState } from "vuex";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";

export default {
  name: "TicketsViewTable",
  mixins: [filterUtils, routerUtils, general],

  props: {
    tickets: {
      type: Array
    },

    selectedStatus: {
      type: String,
    }
  },

  watch: {
    tickets() {
    }
  },

  data() {
    return {
      actionsButtonKey: 0,
      loadingStartWorkBtn: [],
      loadingSolveTicketBtn: [],

      MAX_FIELD_LENGTH,
      ticketsStatuses,
      ticketsReports,
      inBetween,
      trimTableField,
      dynamicViewsConstants
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", ["showViewTicketDetailsDialog"]),
    ...mapMutations("ticket", ["setTicket", 'setShouldFetchTickets']),

    computeStartWorkButtonAction(ticket) {
      return this.selectedStatus === 'unresolved' || ticket.status === 'unresolved'
    },

    computeSolveButtonAction(ticket) {
      return (this.selectedStatus === 'in_progress' || this.selectedStatus === 'unresolved') ||
          (ticket.status === 'unresolved' || ticket.status === 'in_progress')
    },

    isActionButtonsLoading(ticket, btnType) {
      let indexOfBtn = this._data[btnType].findIndex(btn => btn.id === ticket.id)
      return this._data[btnType][indexOfBtn].isLoading
    },

    handleViewTicketDetails(ticket) {
      this.setTicket(ticket);
      this.showViewTicketDetailsDialog();
    },

  }
}
</script>
