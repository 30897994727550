<template lang="pug">
  el-menu.el-menu-wrapper.scroll-el-menu(
    :class="{'closed-menu': !isMenuOpen}"
    :router="false"
    :default-active="activeLink"
    :collapse-transition="false"
    mode="vertical"
    v-if="computeSideBar"
    ref="sidebar"
  )
    NavProfileComponent(
      :isMenuOpen="isMenuOpen"
      @toggleMenuOpen="handleToggleMenuOpen"
    )
    div.menu-list
    DockingStationsMapItem
    TicketsItem
    //- TransactionsItem
    RidesItem
    CardsItem
    SmartCardsItem
    SettingsItem

</template>

<script>
import { mapState } from "vuex";
import NavProfileComponent from "../_shared/NavProfileComponent.vue";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import { sideMenuMixins } from "@src/mixins/sideMenu";
import DockingStationsMapItem from "@components/sidebar/DockingStationsMapItem";
import TicketsItem from "@components/sidebar/TicketsItem";
import TransactionsItem from "@components/sidebar/TransactionsItem";
import RidesItem from "@components/sidebar/RidesItem";
import SettingsItem from "@components/sidebar/SettingsItem";
import CardsItem from "@components/sidebar/CardsItem";
import SmartCardsItem from "@components/sidebar/SmartCardsItem";

export default {
  mixins: [general, sideMenuMixins],

  components: {
    SettingsItem,
    RidesItem,
    TransactionsItem,
    TicketsItem,
    DockingStationsMapItem,
    NavProfileComponent,
    CardsItem,
    SmartCardsItem
  },

  data() {
    return {
      isMenuOpen: true,
      activeLink: this.$route.path,
      dynamicViewsConstants,
    };
  },

  methods: {
    handleToggleMenuOpen(isMenuOpen) {
      this.isMenuOpen = isMenuOpen;
      this.toggleSideMenu(isMenuOpen);

      localStorage.setItem("isMenuOpen", this.isMenuOpen);
    },
  },

  created() {
    localStorage.setItem("isMenuOpen", this.isMenuOpen);
  },

  computed: {
    ...mapState("auth", ["user"]),

    computeSideBar() {
      return (
        // this.user &&
        this.activeLink !== "/set-password" &&
        this.activeLink !== "/change_email"
      );
    },
  },

  watch: {
    $route(newVal) {
      this.activeLink = newVal.path;
    },
  },
};
</script>

<style lang="scss">
/* sub menu with link */
.sub-menu-link {
  width: 100%;
  text-decoration: none;
  display: inline-block;
}
</style>
