import axios from "axios";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  bike: null,
  selectedBikes: [],
  shouldFetchBikes: false,
};

export const actions = {
  async getBikes({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/bikes${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getBikesInventory({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/inventory_bikes${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getBike({ commit }, id) {
    try {
      const response = await axios.get(`/v1/bikes/${id}`);
      commit("setBike", dataFormatter.deserialize(response.data));

      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getBikeMaintenanceHistory({ commit }, payload) {
    try {
      const response = await axios.get(
        `/v1/bikes/${payload.id}/show_history_maintenances?page=${payload.page}`
      );
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getBikeRidesHistory({ commit }, payload) {
    try {
      const response = await axios.get(
        `/v1/bikes/${payload.id}/show_history_rides?page=${payload.page}`
      );
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async addBike({ commit }, payload) {
    try {
      await axios.post("/v1/bikes", {
        ...payload,
      });
      return messageUtils.showSuccess(i18n.t("common.bike_added"));
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async addBikeToInventory({ commit }, payload) {
    try {
      await axios.post("/v1/inventory_bikes", {
        ...payload,
      });
      return messageUtils.showSuccess(i18n.t("common.bike_added"));
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async updateBike({ commit }, payload) {
    try {
      await axios.patch(`/v1/bikes/${payload.id}`, {
        ...payload,
      });
      return messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async addBikesBulk({ commit }, file) {
    try {
      await axios.post("/v1/bikes/bulk_insert", file);
      return messageUtils.showSuccess(i18n.t("dialogs.inventory.bikes_added"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async assignBikes({ commit }, payload) {
    try {
      await axios.post("/v1/bikes/assign_cost_center", {
        ...payload,
      });
      return messageUtils.showSuccess(i18n.t("dialogs.inventory.bikes_added"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async switchBikeStatus({ commit }, payload) {
    try {
      return await axios.patch(`/v1/bikes/${payload.id}/update_status`, {
        ...payload,
      });
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async startMaintenance({ commit }, id) {
    try {
      await axios.post(`/v1/bikes/${id}/start_maintenance`);
      return messageUtils.showSuccess(i18n.t("common.maintenance_started"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async finishMaintenance({ commit }, payload) {
    try {
      await axios.post(`/v1/bikes/${payload.id}/finish_maintenance`, {
        ...payload,
      });
      return messageUtils.showSuccess(i18n.t("common.maintenance_started"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async reassignTechnician({ commit }, payload) {
    try {
      await axios.post(`/v1/bikes/${payload.id}/reassign_technician`, {
        ...payload,
      });
      return messageUtils.showSuccess(i18n.t("bikes.assigned_technician"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async deleteBike({ commit }, payload) {
    try {
      await axios.delete(`/v1/bikes/${payload}`);
      messageUtils.showSuccess(i18n.t("common.bike_was_deleted"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getUsersByRole({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/users/?by_role_name=${payload}`);
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  setBike(state, bike) {
    state.bike = bike;
  },

  setSelectedBikes(state, selectedBikes) {
    state.selectedBikes = selectedBikes;
  },

  setShouldFetchBikes(state, value) {
    state.shouldFetchBikes = value;
  },
};
