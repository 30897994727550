<template lang="pug">
  .add-billing-address
    el-form(
      :model="form"
      :rules="checkAddBillingAddress"
      ref="AddBillingAddressForm"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="9")
          el-form-item(
            prop="last_name"
            :label="$t('common.billing_address.last_name')"
          )
            el-input(
              v-model="form.last_name"
              name="last_name"
              @keydown.native.space.prevent
            )
        el-col(:span="9")
          el-form-item(
            prop="first_name"
            :label="$t('common.billing_address.first_name')"
          )
            el-input(
              v-model="form.first_name"
              name="first_name"
              @keydown.native.space.prevent
            )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="6")
          el-form-item(
            prop="country"
            :label="$t('common.billing_address.country')"
          )
            el-input(
              v-model="form.country"
              name="country"
              @keydown.native.space.prevent
            )
        el-col(:span="6")
          el-form-item(
            prop="county"
            :label="$t('common.billing_address.county')"
          )
            el-input(
              v-model="form.county"
              name="county"
              @keydown.native.space.prevent
            )
        el-col(:span="6")
          el-form-item(
            prop="city"
            :label="$t('common.billing_address.city')"
          )
            el-input(
              v-model="form.city"
              name="city"
              @keydown.native.space.prevent
            )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="12")
          el-form-item(
            prop="address"
            :label="$t('common.billing_address.address')"
          )
            el-input(
              v-model="form.address"
              name="address"
              @keydown.native.space.prevent
            )
        el-col(:span="6")
          el-form-item(
            prop="postal_code"
            :label="$t('common.billing_address.postal_code')"
          )
            el-input(
              v-model.number="form.postal_code"
              name="postal_code"
              @keydown.native.space.prevent
            )

    el-row.text-center
      el-button.dark-blue-btn.pop-up-btn.mt-4(
        @click="handleAddBillingAddress('AddBillingAddressForm')"
        :loading="loading"
      ) {{ $t('common.billing_address.add_billing_address') }}

</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { setObjectValues } from "@utils/generalUtils";
import { billingAddressViewValidations } from "@utils/formValidations/billingAddressViewValidations";

export default {
  name: "AddBillingAddress",
  mixins: [billingAddressViewValidations],

  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        country: "",
        county: "",
        city: "",
        address: "",
        postal_code: ""
      },
      loading: false,
      setObjectValues
    }
  },

  methods: {
    ...mapActions("billingAddress", ["addBillingAddress"]),
    ...mapMutations("dialog", ["hideAddCardDialog"]),

    handleAddBillingAddress(formName){
      this.loading= true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if(valid) {
            this.form.postal_code = this.form.postal_code.toString();
            let params = { ...this.form };

            this.addBillingAddress(params)    
              .then(() => {
                this.clearForm();
                this.$emit('emitAddCard')
              })
              .catch((error) => {
                // console.log(error)
              })
              .finally(() => {
                this.loading = false;
                // this.handleCloseAddCardDialog();
              });
        } else {
          this.loading = false;
        }
        
      });

    },

    clearForm() {
      this.setObjectValues(this.form, "");
    },

    handleCloseAddCardDialog() {
      this.hideAddCardDialog();
    },

  },
};
</script>

<style lang="scss" scoped>

.add-billing-address {
  padding: 24px 0;

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
