<template lang="pug">
  .add-bike
    el-form(
      :model="form"
      ref="AddBikeForm"
      :rules="addBikeRules"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="9")
          el-form-item(
            prop="serial_number"
            :label="$t('common.serial_number')"
          )
            el-input(
              v-model="serialNumber"
              name="serial_number"
              @keydown.native.space.prevent
            )
        el-col(:span="9")
          el-form-item(
            prop="rfid"
            :label="$t('label.rfid')"
          )
            el-input(
              v-model="rfid"
              name="rfid"
              @keydown.native.space.prevent
            )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="9")
          el-form-item(
            prop="cost_center_id"
            :label="$t('common.cost_center_name')"
          )
            el-select(
              v-model='form.cost_center_id'
              filterable
              :placeholder="$t('placeholder.select_cost_center')"
            )
              el-option(
                v-for='cost_center in cost_centers'
                :key='cost_center.id'
                :label='cost_center.name'
                :value='cost_center.id'
              )
        el-col(:span="9")
          el-form-item(
            prop="next_maintenance_at"
            :label="$t('common.first_maintenance_date')"
          )
            el-date-picker.date-picker(
              v-model="form.next_maintenance_at"
              type="date"
              :placeholder="$t('placeholder.pick_date')"
              :pickerOptions="pickerOptions"
              format="dd/MM/yyyy"
              :default-value="new Date()"
            )
    el-row.text-center
      el-button.dark-blue-btn.pop-up-btn.mt-4(
        @click="handleAddBike('AddBikeForm')"
        :loading="loading"
      ) {{ $t('common.add_bike') }}
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { disabledPastDates } from "@utils/dateRange";
import { bikesViewValidations } from "@utils/formValidations/bikesViewValidations";
import { customValidations } from "@utils/customValidators";
import { filterUtils } from "@utils/filterUtils";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "AddBike",
  mixins: [bikesViewValidations, customValidations, filterUtils],

  data() {
    return {
      cost_centers: [],
      form: {
        serial_number: "",
        rfid: "",
        cost_center_id: "",
        next_maintenance_at: null,
      },
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return disabledPastDates(time);
        },
      },
      setObjectValues
    }
  },

  created() {
    this.handleGetCostCenters();
  },

  computed: {
    serialNumber: {
      get: function () {
        return this.form.serial_number;

      },
      set: function (newValue) {
        this.form.serial_number = newValue.replace(/[^[0-9_-]/g, '');
      }
    },

    rfid: {
      get: function () {
        return this.form.rfid;

      },
      set: function (newValue) {
        this.form.rfid = newValue.replace(/[^a-f0-9 ]/g, '').toLowerCase();
      }
    },
  },

  methods: {
    ...mapActions("costCenter", ["getCostCenters"]),
    ...mapMutations("dialog", ["hideAddBikeDialog"]),
    ...mapMutations("bike", ["setShouldFetchBikes"]),
    ...mapActions("bike", ["addBike"]),

    clearForm() {
      this.setObjectValues(this.form, "");
    },

    async handleGetCostCenters() {
      const cost_centers = await this.getCostCenters("");
      this.cost_centers = cost_centers.data;
    },

    handleAddBike(formName) {
      this.loading = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let params = { ...this.form };

          this.addBike(params)
              .then(() => {
                this.clearForm();
                this.hideAddBikeDialog();
                this.setShouldFetchBikes(true);
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-bike {
  padding: 24px 0;

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
