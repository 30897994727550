<template lang="pug">

  .cost-centers-filter
    span.filter-title {{ name }}
    el-select(
      v-model='selectedStatus'
      filterable
      :placeholder="$t('placeholder.select_status')"
      @change="emitChangeEvent"
    )
      el-option(
        v-for='status in statuses'
        :key='status.name'
        :label='status.name'
        :value="status.value"
      )
</template>

<script>
// (v-if="computeStatusFilter")
import { mapState } from "vuex";
import { roles } from "@utils/roles";
import store from "@state/store";

export default {
  name: "FilterStatus",

  props: {
    name: {
      type: String,
      default: ""
    },

    statuses: {
      type: Array,
      default: []
    },

    value: {
      type: String,
      default: "",
    },
  },

  computed: {
    selectedStatus: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState("auth", ["user"]),

    computeStatusFilter() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },
  },

  data() {
    return {
      costCenters: [],
    };
  },

  methods: {
    emitChangeEvent(values) {
      this.$emit('onChange', values)
    }
  },
};
</script>
