<template lang="pug">
  el-table(
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='cards'
  )
    el-table-column(
      prop='status'
      :label="'Ultimele 4 cifre'"
    )
      template(slot-scope='scope')
        span {{ "**** **** **** " + scope.row.last_four }} 
        img.cursor-pointer.ml-1(
          v-if='isSelected(scope.row.default) == true '
          src="@assets/actions/select-default.svg")
          
    el-table-column(
      prop='status_type'
      :label="'Dată expirare'"
    )
      template(slot-scope='scope')
        span {{ scope.row.exp_month + '/' + scope.row.exp_year }}

    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(v-slot='scope')
        .grid-buttons
          img.cursor-pointer.ml-1(
            src="@assets/actions/delete.svg"
            title="Ștergere card"

            @click="handleDeleteCardDialog(scope.row)"
          )
          img.cursor-pointer.ml-1(
            src="@assets/actions/edit.svg"
            title="Selectare card"
            v-if='isSelected(scope.row.default) == false'
            @click="handleSelectDefaultCardDialog(scope.row)"
          )
</template>

<script>
import { filterUtils, inBetween, MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { ticketsReports, ticketsStatuses } from "@utils/statuses";
import { routerUtils } from "@utils/router";
import { mapActions, mapMutations, mapState } from "vuex";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import ConfirmOperation from "@components/dialogs/templates/ConfirmOperation";

export default {
  name: "CardsViewTable",
  mixins: [filterUtils, routerUtils, general],

  props: {
    cards: {
      type: Array
    },

    selectedStatus: {
      type: String,
    }
  },

  components: {
    ConfirmOperation
  },

  watch: {
    cards() {
    }
  },

  data() {
    return {
      actionsButtonKey: 0,
      loadingStartWorkBtn: [],
      loadingSolveTicketBtn: [],

      MAX_FIELD_LENGTH,
      ticketsStatuses,
      ticketsReports,
      inBetween,
      trimTableField,
      dynamicViewsConstants,
      isConfirmedOperation: true
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", ["showConfirmOperationDialog", "hideConfirmOperationDialog"]),
    ...mapMutations("card", ["setShouldFetchCards"]),
    ...mapActions("card", ["deleteCard", "selectDefaultCard", "getCards"]),

    isSelected(status){
      if (status)
        return true;
      return false;
    },

    async handleDeleteCardDialog(card) {
      // this.showConfirmOperationDialog();
      await this.deleteCard(card.id)      
        .then( async() => {
          if (card.default == true) {
            const response = await this.getCards();
            if (response.length > 0) {
              await this.selectDefaultCard(response[0].id);
            }
          }   
        })
        .finally(() => {
          this.setShouldFetchCards(true);
        });
    },

    async handleSelectDefaultCardDialog(card) {
      await this.selectDefaultCard(card.id);
    },
  }
}
</script>
