<template lang="pug">
  .add-bike-inventory
    el-form(
      :model="form"
      ref="AddBikeForm"
      :rules="addBikeInventoryRules"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center
        el-col(:span="11")
          el-form-item(
            prop="serial_number"
            :label="$t('common.serial_number')"
          )
            el-input(
              v-model="serialNumber"
              name="serial_number"
            )
      el-row.d-flex.justify-content-center
        el-col(:span="11")
          el-form-item(
            prop="rfid"
            :label="$t('label.rfid')"
          )
            el-input(
              v-model="rfid"
              name="rfid"
            )
    el-row.text-center
      el-button.dark-blue-btn.pop-up-btn(
        @click="handleAddBike('AddBikeForm')"
        :loading="loading"
      ) {{ $t('common.add_bike') }}
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { bikesViewValidations } from "@utils/formValidations/bikesViewValidations";
import { customValidations } from "@utils/customValidators";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "AddBikeInventory",
  mixins: [bikesViewValidations, customValidations],

  data() {
    return {
      form: {
        serial_number: "",
        rfid: "",
      },
      loading: false,
      setObjectValues,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("bike", ["shouldFetchBikes"]),

    ...mapGetters({
      role: "auth/role",
    }),

    serialNumber: {
      get: function () {
        return this.form.serial_number;

      },
      set: function (newValue) {
        this.form.serial_number = newValue.replace(/[^[0-9_-]/g, '');
      }
    },

    rfid: {
      get: function () {
        return this.form.rfid;

      },
      set: function (newValue) {
        this.form.rfid = newValue.replace(/[^a-f0-9 ]/g, '').toLowerCase();
      }
    },
  },

  methods: {
    ...mapMutations("dialog", ["hideAddBikeInventoryDialog"]),

    ...mapActions("bike", ["addBikeToInventory"]),

    ...mapMutations("bike", ["setShouldFetchBikes"]),

    clearForm() {
      setObjectValues(this.form, "");
    },

    handleAddBike(formName) {
      this.loading = true;
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let params = { ...this.form };

          this.addBikeToInventory(params)
              .then(() => {
                this.clearForm();
                this.hideAddBikeInventoryDialog();
                this.setShouldFetchBikes(true);
              })
              .finally(() => {
                this.clearForm();
                this.loading = false;
              });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-bike-inventory {
  padding: 24px 0;
}
</style>
