<template lang="pug">
  .forms
    el-form(
      :model="form"
      ref="EditProfileSettings"
      :rules="profileDataFormRules"
      @submit.native.prevent
    )
      el-row.mt-4.responsive(:gutter="20")
        el-col(:span="6")
          el-form-item(
            prop="first_name"
            :label="$t('label.first_name')"
          )
            el-input(
              v-model="form.first_name"
              name="name"
            )
        el-col(:span="6")
          el-form-item(
            prop="last_name"
            :label="$t('label.last_name')"
          )
            el-input(
              v-model="form.last_name"
              name="last_name"
            )
      el-row.mt-4.responsive(:gutter="20")
        el-col(:span="6")
          el-form-item(
            prop="phone_number"
            :label="$t('common.phone_number')"
          )
            .status-wrapper.d-flex.align-items-center(
              style="margin-top: 14px"
              v-if="phone_confirmation == false")
              span.oval.cancelled
            el-input(
              v-model="form.phone_number"
              name="phone_number"
            )

          el-row.text-right.text-underline.font-weight-bolder
            a(v-if="phone_confirmation == false && user.phone_number != null"
              @click="handleConfirmPhoneNumber"
              :loading="loadingConfirmPhoneNumber") {{ $t('settings.confirm_phone_number') }}

        el-col(:span="6")
          el-form-item(
            prop="email"
            :label="$t('common.email_address')"
          )
            .status-wrapper.d-flex.align-items-center(
              style="margin-top: 14px"
              v-if="email_confirmation == false")
              span.oval.cancelled
            el-input(
              readonly="readonly"
              v-model="form.email"
              name="email"
            )

          el-row.text-right.text-underline.font-weight-bolder
            a(v-if="email_confirmation == false"
              :class="{disabled: generatePausedForSeconds}"
              @click="handleConfirmEmail"
              :loading="loadingConfirmEmail") {{ $t('settings.confirm_email') }}

      el-row.justify-content-right.mt-4.responsive(:gutter="20")
        el-col(:span="6" style="margin-top: 17px")
          el-button.dark-blue-btn.mt-3.settings-btn(
            @click="handleUpdateProfile('EditProfileSettings')"
            :loading="loadingUpdateProfile"
          ) {{ $t('settings.update_profile_settings') }}
</template>

<script>
import i18n from "@i18n";
import { mapActions, mapMutations, mapState } from "vuex";
import { settingsViewValidations } from "@/src/utils/formValidations/settingsViewValidations";
import { customValidations } from "@utils/customValidators";

export default {
  name: "ProfileDataForm",
  mixins: [settingsViewValidations, customValidations],

  data() {
    return {
      imageFile: null,

      form: {
        first_name: null,
        last_name: null,
        phone_number: null,
        // profile_image: null,
        email: null
      },

      loadingChangeEmail: false,
      loadingUpdateProfile: false,

      loadingConfirmPhoneNumber: false,
      loadingConfirmEmail: false,

      email_confirmation: false,
      phone_confirmation: false,
      phone_number_regex: RegExp(/^[+]*(?:[\d]\s*){8,15}$/),

      generatePausedForSeconds: false,

    };
  },

  computed: {
    ...mapState("auth", ["access_token", "refresh_token", "user"]),
  },

  watch: {
    user() {
      this.initForm();
      this.getUserData();
    },
  },

  async created() {
    await this.getUserData();
  },

  mounted() {
    this.initForm();
  },

  methods: {
    ...mapActions({
      generateChangeEmailLink: "user/generateChangeEmailLink",
      updateProfile: "user/updateProfile",
      sessionUser: "auth/sessionUser",
      uploadPictureCard: "user/uploadPicture",

      getUserProfile: "user/getUserProfile",
      resendConfirmationEmail: "user/resendConfirmationEmail"
    }),

    ...mapMutations("dialog", ["showConfirmPhoneNumberDialogVisible", "showConfirmEmailAddressDialogVisible"]),

    async getUserData(){
      const response = await this.getUserProfile()
      this.phone_confirmation = response.phone_confirmation;
      this.email_confirmation = response.email_confirmation;
    },

    async initForm() {
      if (!this.user) return false;
      const { email, first_name, last_name, phone_number, image_url } = this.user;

      this.email = email;
      this.form.first_name = first_name;
      this.form.last_name = last_name;
      this.form.phone_number = phone_number;
      // this.form.profile_image = image_url;
      this.form.email = email;
    },

    handleConfirmPhoneNumber(formName){
      this.showConfirmPhoneNumberDialogVisible();
    },

    handleConfirmEmail(){
      this.loadingConfirmEmail = true;
      this.generatePausedForSeconds = true;

      setTimeout(() => this.generatePausedForSeconds = false, 10000);
      this.resendConfirmationEmail(this.form.code)
        .then(async () => {
        })
        .finally(() => {
          this.loadingConfirmEmail = false;
        });
      this.loadingConfirmEmail = false;
    },
    
    async handlePictureCardPreview(file) {
      this.imageFile = file.url;
    },

    handleEditEmail() {
      this.loadingChangeEmail = true;

      this.generateChangeEmailLink().finally(() => {
        this.loadingChangeEmail = false;
      });
    },

    async handleUpdateProfile(formName) {
      this.loadingUpdateProfile = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
      if (valid)
      {
        const payload = { ...this.form};

        this.updateProfile(payload)
          .then(async () => {
            await this.sessionUser({
              access_token: $cookies.get("access_token"),
              refresh_token: $cookies.get("refresh_token")
            });
          })
          .finally(() => {
            this.loadingUpdateProfile = false;
          });
      } else {
        this.loadingUpdateProfile = false;
      }
      })
      
      location.reload();

    },
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .responsive {
    display: flex;
    flex-direction: column !important;

    .el-col {
      width: 100%;
    }
  }
}

.grid-buttons{
  display: inline-grid;
  grid-template-rows: 5px 5px;
  grid-template-columns: 120px 5px;
  grid-gap: 10px;
}

a.disabled {
  pointer-events: none;
  color: gray
}

</style>