<template lang="pug">
  .view-ride-details
    GoogleMaps(
      v-if="this.coordinates && this.coordinates.length > 0"
      :dialogName="'viewRideDetails'"
      :rideCoordinates="this.coordinates && this.coordinates"
    )
    span.gdpr-message(v-else) {{ $t('common.gdpr_message') }}
    .col
      el-row.center.details-bold(
        :gutter="40"
        type="flex"
        justify="center"
      )
        el-col(:span="5")
          span {{ $t('dialogs.customer.beginning_date') }}

        el-col(:span="5")
          span {{  $t('common.end_date') }}

        el-col(:span="5")
          span {{ $t('common.ride_price') }}

        el-col(:span="5")
          span {{ $t('dialogs.customer.paid') }}


      el-row.center(
        :gutter="40"
        type="flex"
        justify="center"
        style="margin-top: 10px"
      )

        el-col(:span="5")
          span.pre-line {{ computeDateFormat(ride.start_at) }}

        el-col(:span="5")
          span.pre-line {{ computeDateFormat(ride.end_at) }}

        el-col.pre-line(:span="5")
          span {{ $n(ride.price, 'currency') }}

        // TODO: dont' forget about paid
        el-col.pre-line(:span="5")
          span {{ $n(ride.already_paid_ammount, 'currency') }}

    .col
      el-row.center.details-bold(
        :gutter="40"
        type="flex"
        justify="center"
      )


        el-col(:span="5")
          span {{  $t('dialogs.customer.start_dock') }}

        el-col(:span="5")
          span {{  $t('dialogs.customer.finish_dock') }}

        el-col(:span="5")
          span {{ $t('common.ride_duration') }}

        el-col(:span="5")
          span {{  $t('common.ride_status') }}

      el-row.center(
        :gutter="40"
        type="flex"
        justify="center"
        style="margin-top: 10px"
      )
       

        el-col.pre-line.break-word(:span="5")
          span(
            v-if="ride.start_docking_station_name"
          ) {{ ride.start_docking_station_name }}
          span(v-else) -

        el-col.pre-line.break-word(:span="5")
          span(
            v-if="ride.end_docking_station_name"
          ) {{ ride.end_docking_station_name }}
          span(v-else) -

        el-col.pre-line(:span="5")
          span {{ computeRideDuration(ride.duration_seconds) }}

        el-col.pre-line.break-word(:span="5")
          .status-wrapper.text-underline(
            v-if="inBetween('ridesStatuses', ride.status, 21, 40)")
            span.oval.completed
            span {{ ridesStatuses[ride.status].message }} - {{ computePaidStatus(ride.price, ride.already_paid_ammount) }}

          .status-wrapper.text-underline(v-if="inBetween(ride.status, 41, 60)")
            span.oval.cancelled
            span {{ ridesStatuses[ride.status].message }} - {{ computePaidStatus(ride.price, ride.already_paid_ammount) }}

          .status-wrapper.text-underline(v-if="inBetween('ridesStatuses', ride.status, 1, 20)")
            span.oval.pending
            span {{ ridesStatuses[ride.status].message }}
    .col
      el-row.center.details-bold(
        :gutter="40"
        type="flex"
        justify="center"
      )


        el-col(:span="5")
          //- span {{ $t('dialogs.customer.ride_tariff') }}



        //- el-col(:span="5")
        //-   span {{  $t('common.flagged') }}

      el-row.center(
        :gutter="40"
        type="flex"
        justify="center"
        style="margin-top: 10px"
      )


        //- el-col.pre-line(:span="5")
        //-   .price-plans
        //-     // TODO: don't forget about timeblocks
        //-     span(v-if="ride.rent_tariff_name") {{ ride.rent_tariff_name }}
        //-     span(v-else) -
        //-     span {{ ride.rent_tariff_initial_fee }} {{$t('customers.first')}}
        //-     span {{ ride.rent_tariff_secondary_fee }} {{$t('customers.every_next')}}

        

        //- el-col.pre-line.break-word(:span="5")
        //-   span(v-if="ride.flag !== 'no_problems'") 🚩 {{ ride.flag }}
        //-   span(v-else) --

    br
    h4 Tranzacții:
    el-table(
      :data="transactions"
      v-loading="loading"
      :gutter="0"
      center
      style="width: 100%;"
    )
      el-table-column(
        prop="bike.status_object.label"
        :label="'Suma'"
        :span="8"
      )
        template(slot-scope='scope')
          span {{ scope.row.amount }} 
          
      el-table-column(
        prop="bike.status_object.label"
        :label="'Suma restituită'"
        :span="8"
      )
        template(slot-scope='scope')
          span {{ scope.row.refunded_amount }} 

      el-table-column(
        prop="bike.status_object.label"
        :label="'Status'"
        :span="8"
      )
        template(slot-scope='scope')
          span {{ scope.row.status_object.label }} 

</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { ridesStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import GoogleMaps from "@components/_shared/GoogleMaps";

export default {
  name: "ViewCustomerRideDetails",
  mixins: [filterUtils],
  components: { GoogleMaps },

  data() {
    return {
      loading: false,
      ridesStatuses,
      inBetween,
      transactions: [],
      coordinates: []
    };
  },

  computed: {
    ...mapState("ride", ["ride"]),
  },

  async created() {
    await this.handleRideData();
  },

  watch: {
    ride(){
      this.handleRideData();
    }
  },

  methods: {

    ...mapActions('ride', ["getTransactionsForRide", "getRide"]),

    async handleRideData(){
      this.loading = true;

      const responseCoord = await this.getRide(this.ride.id);
      if (responseCoord.ride_coordinates != []) {
        this.coordinates = responseCoord.ride_coordinates;
      }


      const responseTransactions = await this.getTransactionsForRide(this.ride.id);
      this.transactions = responseTransactions

      this.loading = false
    },

    ...mapMutations("dialog", [
      "hideViewRideDetailsDialog",
      "showViewTransactionDetailsDialog",
    ]),

    ...mapMutations("ride", ["setRide"]),
  },
};
</script>

<style lang="scss" scoped>
.gdpr-message {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapWrapper {
  height: 293px;
  margin: 23px auto 40px;
  width: 100%;
}

.view-ride-details {
  padding-bottom: 24px;

  .price-plans {
    span {
      display: block;
    }
  }

  .col {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}
</style>
