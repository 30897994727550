<template lang="pug">
  .view-password-validation-delete.d-flex
    el-form(
      :model="form"
      ref="ValidationForm"
      @submit.native.prevent
      @keyup.enter.native="handleConfirmEmail('ValidationForm')"
    )
      el-row.d-flex.justify-content-center
        el-col(:span="18")
          el-form-item(
            prop="code"
            :label="$t('label.confirm_email_code')"
          )
            el-input(
              v-model="form.code"
              type="code"
              name="code"
            )
      el-row.text-right.text-underline.font-weight-bolder
        a(@click.prevent="handleGenerateCode()"
          :class="{disabled: generatePausedForSeconds}"
          :loading="generatePausedForSeconds") {{ $t('common.generate_code') }}
      br
      el-row.d-flex.justify-content-center
        el-button.dark-blue-btn.pop-up-btn(
          @click="handleConfirmEmail('ValidationForm')"
          :disabled="confirmPausedForSeconds"
          :loading="loading"
        ) {{ $t('common.validate_code') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "ConfirmEmailAddress",
  props: ["dialogMessage"],

  data() {
    return {
      form: {
        code: null,
      },
      loading: false,
      generatePausedForSeconds: false,
      confirmPausedForSeconds: false,
      setObjectValues,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },


  mounted() {
    this.setObjectValues(this.form, "");
  },

  methods: {
    ...mapMutations( 'dialog', ["hideConfirmEmailAddressDialogVisible"]),
    ...mapActions({
      confirmEmailAddress: "user/confirmEmailAddress",
      resendConfirmationEmail: "user/resendConfirmationEmail"
    }),

    handleGenerateCode(){
      this.generatePausedForSeconds = true;
      setTimeout(() => this.generatePausedForSeconds = false, 10000);
      this.resendConfirmationEmail()
        .then(async () => {
        })
        .finally(() => {
        });
    },

    handleConfirmEmail(formName) {
      this.loading = true;

      this.confirmPausedForSeconds = true;
      setTimeout(() => this.confirmPausedForSeconds = false, 5000);
      this.confirmEmailAddress(this.form.code)
        .then(async () => {

        })
        .finally(() => {
          this.loading = false;
          this.hideConfirmEmailAddressDialogVisible();
        });
      this.loading = false;
      location.reload();
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.view-password-validation-delete {
  flex-direction: column;

  .delete-message {
    span {
      display: block;
      font-family: $avenir-next-regular;
      font-size: 15px;
      line-height: 25px;
      color: $secondary;
    }
  }

  .password-validation-delete-btn {
    box-shadow: 0 10px 20px 0 rgba(228, 30, 38, 0.21);
    color: white !important;
    background: #e41e26 !important;
  }
}

.disabledd {
  cursor: not-allowed;
  color: gray
}

a.disabled {
  pointer-events: none;
  color: gray
}
</style>
