import messageUtils from "@utils/messageUtils";
import axios from "axios";

import Jsona from "jsona";
import i18n from "@i18n";
const dataFormatter = new Jsona();

export const state = {
  card: null,
  shouldFetchCards: false
};

export const actions = {
  async getCards({ commit }, payload) {
    try {
      const response = await axios.get(`/api/customer_cms/v1/stripe_cards${payload}`);
      return {
        data: response,
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async deleteCard({ commit }, payload) {
    try {
      await axios.delete(`/api/customer_cms/v1/stripe_cards/${payload}`);
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async selectDefaultCard({ commit }, payload) {
    try {
      const response = await axios.post(`api/customer_cms/v1/stripe_cards/${payload}/select_default`);
      messageUtils.showSuccess(i18n.t("common.success"));
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async createSetupIntent({ commit }) {
    try {
      const response = await axios.post(`api/customer_cms/v1/stripe_cards/setup_intent`);
      messageUtils.showSuccess(i18n.t("common.success"));
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

};

export const mutations = {
  setCard(state, card) {
    state.card = card;
  },

  setShouldFetchCards(state, value) {
    state.shouldFetchCards = value;
  },
};
