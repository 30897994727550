export const bikesViewValidations = {
  data() {
    return {
      addBikeInventoryRules: {
        serial_number: [
          {
            required: true,
            validator: this.bikeSerialNumberValidator,
            trigger: "blur",
          },
        ],
        rfid: [
          {
            required: true,
            validator: this.bikeRfidValidator,
            trigger: "blur",
          },
        ],
      },

      editBikeRules: {
        rfid: [
          {
            required: true,
            validator: this.bikeRfidValidator,
            trigger: "blur",
          },
        ],
      },

      addBikeRules: {
        serial_number: [
          {
            required: true,
            validator: this.bikeSerialNumberValidator,
            trigger: "blur",
          },
        ],
        rfid: [
          {
            required: true,
            validator: this.bikeRfidValidator,
            trigger: "blur",
          },
        ],
        next_maintenance_at: [
          {
            required: true,
            message: this.$t("inventory.validations.maintenance_date"),
            trigger: "blur",
          },
        ],
        cost_center_id: [
          {
            required: true,
            message: this.$t("common.validations.cost_center"),
            trigger: "blur",
          },
        ],
      },

      assignBikesRules: {
        next_maintenance_at: [
          {
            required: true,
            message: this.$t("inventory.validations.maintenance_date"),
            trigger: "blur",
          },
        ],
        cost_center_id: [
          {
            required: true,
            message: this.$t("common.validations.cost_center"),
            trigger: "blur",
          },
        ],
      },

      viewBikeMaintenanceRules: {
        maintenanceDescription: [
          {
            required: true,
            message: this.$t("bikes.validations.enter_maintenance_description"),
            trigger: "blur",
          },
        ],
        maintenanceStatus: [
          {
            required: true,
            message: this.$t("bikes.validations.select_bike_status"),
            trigger: "blur",
          },
        ],
        maintenanceStatusDetails: [
          {
            required: true,
            message: this.$t("bikes.validations.select_bike_status"),
            trigger: "blur",
          },
        ],
        nextMaintenanceDate: [
          {
            required: true,
            message: this.$t("bikes.validations.select_bike_maintenance_date"),
            trigger: "blur",
          },
        ],
      },
    };
  },
};
