<template lang="pug">
  .add-docking-station
    GoogleMaps(
      @markerDragEnd="handleMarkerDragEnd"
      :dialogName="'AddDockingStation'"
    )
    el-form(
      :model="form"
      ref="AddDockingStationForm"
      :rules="addDockingStationRules"
      @submit.native.prevent
    )
      el-row(:gutter="24")
        el-col(:span="8")
          el-form-item(
            prop="name"
            :label="$t('label.docking_name')"
          )
            el-input(
              v-model="form.name"
              name="name"
            )
        el-col(:span="4")
          el-form-item(
            prop="address_long"
            :label="$t('label.longitude')"
            :blur="this.setMapCoordinates({lat: form.address_lat, lng: form.address_long})"
          )
            el-input(
              v-model.number="form.address_long"
              name="address_long"
            )
        el-col(:span="4")
          el-form-item(
            prop="address_lat"
            :label="$t('label.latitude')"
            :blur="this.setMapCoordinates({lat: form.address_lat, lng: form.address_long})"
          )
            el-input(
              v-model.number="form.address_lat"
              name="address_lat"
            )
        el-col.address(:span="8")
          el-form-item(
            prop="address"
            :label="$t('label.address')"
            :blur="updateMap"
          )
            el-autocomplete(
              ref="autocomplete"
              v-model="form.address"
              name="address"
              :trigger-on-focus="false"
              @select="handleSelectAddress"
              :fetch-suggestions="addressSearchResultsDebounce"
            )
          el-checkbox-group(
            v-model="updateAddress"
          )
            el-checkbox.mb-2.text-sm.text-primary(
              prop="update_address"
              name="update_address"
              :label="$t('dialogs.docking_stations_list.update_address')"
            )
      el-row(:gutter="24")
        el-col(:span="8")
          el-form-item(
            prop="serial_number"
            :label="$t('common.serial_number')"
          )
            el-input(
              v-model="form.serial_number"
              name="serial_number"
            )
        el-col(:span="8")
          el-form-item(
            prop="cost_center_id"
            :label="$t('common.cost_center')"
          )
            el-select(
              v-model='form.cost_center_id'
              filterable
              :placeholder="$t('placeholder.enter_cost_center_id')"
            )
              el-option(
                v-for='costCenter in costCenters'
                :key='costCenter.id'
                :label='costCenter.name'
                :value='costCenter.id'
              )
        el-col(
          :span="4"
        )
          el-form-item(
            prop="rent_tariff_id"
            :label="$t('common.price_plan')"
          )
            el-select.w-100(
              v-model='form.rent_tariff_id'
              filterable
              :placeholder="$t('placeholder.select_price_plan')"
            )
              el-option(
                v-for='tariff in rentTariffs'
                :key='tariff.id'
                :label='tariff.name'
                :value='tariff.id'
              )
        el-col(
          :span="4"
        )
          el-form-item(
            prop="bays_count"
            :label="$t('label.available_bays_number')"
          )
            el-select.w-100(
              v-model='form.bays_count'
              filterable
              :placeholder="$t('placeholder.select_bays')"
            )
              el-option(
                v-for='bay in numberOfExistingBays'
                :key='bay'
                :label='bay'
                :value='bay'
              )
      el-row
        el-col
          el-form-item(
            prop="status"
            :label="$t('label.dock_status')"
          )
        el-col
          el-radio(v-model='form.status' label="working") {{ $t('common.available_f') }}
          el-radio(v-model='form.status' label="under_maintenance") {{ $t('common.under_maintenance') }}

    el-row.text-center
      el-button.dark-blue-btn.mt-2.pop-up-btn(
        @click="handleAddNewDockingStation('AddDockingStationForm')"
        :loading="loading"
      ) {{ $t('dialogs.common.create') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GoogleMaps from "@components/_shared/GoogleMaps";
import _ from "lodash";
import { docksViewValidations } from "@utils/formValidations/docksViewValidations";
import { customValidations } from "@utils/customValidators";
import { setObjectValues } from "@utils/generalUtils";
import Vue from "vue";

export default {
  name: "AddDockingStation",
  mixins: [docksViewValidations, customValidations],

  components: {
    GoogleMaps,
  },

  data() {
    return {
      rentTariffs: [],
      costCenters: [],
      numberOfExistingBays: 100,
      selectedPlaceID: null,
      form: {
        name: null,
        address_long: null,
        address_lat: null,
        address: null,
        serial_number: null,
        bays_count: null,
        status: null,
        rent_tariff_id: null,
        cost_center_id: null,
      },
      loading: false,
      setObjectValues,
      updateAddress: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("map", ["coordinates"]),
  },

  async created() {
    // set empty map coordinates to reset the map
    this.setMapCoordinates({lat: null, lng: null})

    await this.handleGetTariffs();
    await this.handleGetCostCenters();
  },

  methods: {
    ...mapActions("map", ["getPlaceByKeyword", "getPlaceByID"]),

    ...mapMutations("dockingStation", ["setShouldFetchDocks"]),

    ...mapActions("rentTariff", ["getTariffs"]),

    ...mapActions("dockingStation", ["addDock"]),

    ...mapActions("costCenter", ["getCostCenters"]),

    ...mapMutations("dialog", ["hideAddDockingStationDialog"]),

    ...mapMutations("map", ["setMapCoordinates"]),


    async updateMap(){
      let addressObj = {
        address_line_1: this.form.address,
      };

      Vue.$geocoder.setDefaultCountryCode('RO');

      await Vue.$geocoder.send(addressObj, response => {
        this.handleSelectAddress(response.results[0]);
      });
    },

    clearForm() {
      this.setObjectValues(this.form, "");
    },

    async handleGetTariffs() {
      let params = "";
      const response = await this.getTariffs(params);
      this.rentTariffs = response.data;
    },

    async handleGetCostCenters() {
      let params = ""
      const response = await this.getCostCenters(params)
      this.costCenters = response.data
    },

    async handleGetPlaceData(address, autocompleteCallback) {
      let placesDescription = [];

      // on typing in the field
      if (address !== "") {
        let query = `?keyword=${address}`;
        const places = await this.getPlaceByKeyword(query);

        placesDescription = places.map((place) => {
          return { value: place.description, place_id: place.place_id };
        });

        autocompleteCallback(placesDescription);
      }
    },

    addressSearchResultsDebounce: _.debounce(function (
      address,
      autocompleteCallback
    ) {
      this.handleGetPlaceData(address, autocompleteCallback);
    },
    300),

    async handleSelectAddress(item) {
      this.selectedPlaceID = item.place_id;
      const placeDetails = await this.getPlaceByID(item.place_id);

      this.form.address_lat = placeDetails.lat;
      this.form.address_long = placeDetails.lng;
      let coordinates = {
        lat: placeDetails.lat,
        lng: placeDetails.lng,
      };

      await this.setMapCoordinates(coordinates);
    },

    async handleMarkerDragEnd(address, markerPosition) {
      await this.setMapCoordinates(markerPosition);
      if(this.updateAddress){
        this.form.address = address;
        this.$refs.autocomplete.focus();
      }
      this.form.address_lat = markerPosition.lat;
      this.form.address_long = markerPosition.lng;
    },

    handleAddNewDockingStation(formName) {
      this.loading = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let params = { ...this.form };

          this.addDock(params)
            .then(() => {
              this.hideAddDockingStationDialog();
              this.clearForm();
              this.setShouldFetchDocks(true)
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-docking-station {
  h4 {
    overflow: hidden;
    color: white;
    font-family: Montserat, sans-serif;
    font-weight: 600;
    letter-spacing: 0.12px;
    line-height: 19px;
  }
}
.address {
  .el-autocomplete {
    width: 100%;
  }
}

.el-select {
  width: 100%;
}
</style>
