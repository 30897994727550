<template lang="pug">
  .view-password-validation-delete.d-flex
    .delete-message
      span.pre-line.break-word.text-center {{ dialogData.dialogMessage }}
    el-form(
      :model="form"
      ref="DeleteValidationForm"
      :rules="validation_rules"
      @submit.native.prevent
      @keyup.enter.native="handleDelete('DeleteValidationForm')"
    )
      el-row.d-flex.justify-content-center
        el-col(:span="18")
          el-form-item(
            prop="password"
            :label="$t('label.password')"
          )
            el-input(
              v-model="form.password"
              @change="setPasswordConfirmation(form.password)"
              type="password"
              name="password"
            )
      el-row.d-flex.justify-content-center
        el-button.dark-blue-btn.pop-up-btn.delete-btn(
          @click="handleDelete('DeleteValidationForm')"
          :loading="loading"
        ) {{ $t('common.continue') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { passwordValidationDeleteViewValidations } from "@utils/formValidations/passwordValidationDeleteViewValidations";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "ConfirmOperation",
  mixins: [passwordValidationDeleteViewValidations],
  props: ["dialogMessage"],

  data() {
    return {
      form: {
        password: null,
      },
      setObjectValues,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "dialogData",
      "loading",
    ]),
  },

  watch: {
    dataToDelete() {
      this.setObjectValues(this.form, "");
    },

    allowDeletion() {
      this.setObjectValues(this.form, "");
    },
  },

  mounted() {
    this.setObjectValues(this.form, "");
  },

  methods: {
    ...mapActions({
      hideViewPasswordValidationDeleteDialog:
        "dialogs/hideViewPasswordValidationDeleteDialog",
    }),

    ...mapMutations("passwordValidationDelete", [
      "setAllowDeletion",
      "setPasswordConfirmation",
      "setLoading",
    ]),

    handleDelete(formName) {
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          this.setLoading(true);
          this.setAllowDeletion(true);
        } else {
          this.setLoading(false);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.view-password-validation-delete {
  flex-direction: column;

  .delete-message {
    span {
      display: block;
      font-family: $avenir-next-regular;
      font-size: 15px;
      line-height: 25px;
      color: $secondary;
    }
  }

  .password-validation-delete-btn {
    box-shadow: 0 10px 20px 0 rgba(228, 30, 38, 0.21);
    color: white !important;
    background: #e41e26 !important;
  }
}
</style>
