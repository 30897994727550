import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  ticketsSetting: {},
  shouldFetchTicketsSettings: false,
};

export const actions = {
  // TODO: to be updated when BE is ready
  // async getAllConfigSettings({ commit }) {
  //   try {
  //     const response = await axios.get("/v1/settings");
  //     return dataFormatter.deserialize(response.data);
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },
  //
  // async updateConfSettings({ commit }, payload) {
  //   try {
  //     await axios.patch(`/v1/settings/${payload.id}`, {
  //       ...payload,
  //     });
  //     messageUtils.showSuccess(i18n.t("common.success"));
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },
};

export const mutations = {
  // TODO: to be updated when BE is ready
  // setConfigSetting(state, payload) {
  //   state.configSetting = payload;
  // },
  //
  // setShouldFetchConfSettings(state, value) {
  //   state.shouldFetchConfSettings = value;
  // },
};
