import axios from "axios";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";
import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  selectedTabRole: null,

  loadedCostCenters: [],
  collapsesData: [],
  allPermissionsGrouped: {},

  newAddUserData: {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    user_role_cost_centers: [],
    user_cost_center_permissions: [],
  },
};

export const getters = {
  getCollapsesData: (state) => state.collapsesData,
};

export const actions = {
  async showCurrent({ commit }) {
    try {
      const response = await axios.get(`/api/customer_cms/v1/profiles/show_current`);

      return response.data;

    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async uploadPicture({commit}, image){
    try {
      await axios.post("api/customer_cms/v1/profiles/upload_picture", {
        image,
      });
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async generateChangeEmailLink({ commit }) {
    try {
      await axios.post("/v1/profiles/change_email_request");
      return messageUtils.showSuccess(
        i18n.t("settings.change_email_link_sent")
      );
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async changeUserEmail(
    { commit },
    { email, email_confirmation, access_token }
  ) {
    try {
      await axios.patch(`/v1/profiles/change_email?token=${access_token}`, {
        email,
        email_confirmation,
      });
      return messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async forgotPasswordRequest({ commit, dispatch }, email) {
    try {
      await axios.post('/api/mobile/v1/profiles/forgot_password_request', {
        email,
      });
      return messageUtils.showSuccess(i18n.t("auth.password_reset_email"));
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async changePassword(
    { commit },
    { password, password_confirmation, access_token }
  ) {
    try {
      await axios.patch(`/v1/profiles/change_password?token=${access_token}`, {
        password,
        password_confirmation,
      });
      messageUtils.showSuccess(i18n.t("common.password_update_success"));
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async resetUserPassword({commit}, id) {
    try {
      await axios.post(`/v1/users/${id}/reset_password`);
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async getUsers({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/users${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async getUser({ commit }, id) {
    try {
      const response = await axios.get(`/v1/users/${id}`);
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async getUserProfile({ commit }) {
    try {
      const response = await axios.get(`/api/mobile/v1/profiles/show_current`);
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async updateProfile({commit}, payload){
    try {
      const response = await axios.patch(`/api/customer_cms/v1/users/update_current`, {
        ...payload,
      });

      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async requestPhoneSms({ commit }) {
    try {
      const response = await axios.post(`/api/customer_cms/v1/users/confirm_phone_sms`);
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async confirmPhoneNumber({ commit }, payload) {
    try {
      const response = await axios.get(`/api/customer_cms/v1/users/confirm_phone/${payload}`);
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async resendConfirmationEmail({ commit }) {
    try {
      const response = await axios.post(`/api/customer_cms/v1/users/resend_confirmation_email`);
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async confirmEmailAddress({ commit }, payload) {
    try {
      const response = await axios.get(`/api/customer_cms/v1/users/confirm_email/${payload}`);
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  

  async signup({ commit }, payload) {
    try {
      await axios.post("/v1/users", {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("users.user_added"));
      return false;
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async updateUser({ commit }, payload) {
    try {
      await axios.patch(`/v1/users/${payload.id}`, {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("users.updated_user"));
      return false;
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async deleteUser({ commit }, payload) {
    try {
      await axios.delete(`/v1/users/${payload}`);
      messageUtils.showSuccess(i18n.t("common.users_was_deleted"));
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async updateUserProfile({ commit }, payload) {
    try {
      const response = await axios.post("/updateUser", payload);
      messageUtils.showSuccess(i18n.t("common.success"));
      return response.user;
    } catch (error) {
      messageUtils.showErrors(error.response.data.message);
      console.clear();
      return false;
    }
  },
};

export const mutations = {
  setSelectedTabRole(state, role) {
    state.selectedTabRole = role;
  },

  setNewAddUserDetails(state, payload) {
    state.newAddUserData.first_name = payload.first_name;
    state.newAddUserData.last_name = payload.last_name;
    state.newAddUserData.email = payload.email;
    state.newAddUserData.phone_number = payload.phone_number;
  },

  setNewAddUserCollapseData(state, payload) {
    state.newAddUserData.user_role_cost_centers =
      payload.mappedUserRoleCostCenters;
    state.newAddUserData.user_cost_center_permissions =
      payload.mappedUserCostCenterPermissions;
  },

  setLoadedCostCenters(state, payload) {
    state.loadedCostCenters = payload;
  },

  setCollapsesData(state, payload) {
    let ifExistsIndex = state.collapsesData.findIndex(
      (data) => data.collapse === payload.collapse
    );
    if (ifExistsIndex !== -1) {
      state.collapsesData[ifExistsIndex] = {
        collapse: payload.collapse,
        cost_center_id: payload.cost_center_id,
        role_ids: payload.role_ids,
        alreadyChecked: payload.alreadyChecked,
        loadedChecklists: payload.loadedChecklists,
        permissions: payload.permissions,
      };
    } else {
      state.collapsesData.push({
        collapse: payload.collapse,
        cost_center_id: payload.cost_center_id,
        role_ids: payload.role_ids,
        alreadyChecked: payload.alreadyChecked,
        loadedChecklists: payload.loadedChecklists,
        permissions: payload.permissions,
      });
    }
  },

  setDisabledCostCenter(state, payload) {
    let foundCostCenterIndex = state.loadedCostCenters.findIndex(
      (loadedCostCenter) => loadedCostCenter.id === payload.selectedCostCenterId
    );
    if (foundCostCenterIndex !== -1) {
      state.loadedCostCenters[foundCostCenterIndex].disabled = payload.disabled;
    }
  },

  removeCollapseData(state, collapse) {
    let foundCollapseDataIndex = state.collapsesData.findIndex(
      (data) => data.collapse === collapse
    );
    state.collapsesData.splice(foundCollapseDataIndex, 1);
  },

  removeAllCollapseData(state) {
    state.collapsesData = [];
  },

  setAllPermissionsGrouped(state, payload) {
    state.allPermissionsGrouped = payload;
  },
};
