import axios from "axios";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  billingAddress: null,
  shouldFetchBillingAddress: false,
};

export const actions = {
  async getBillingAddresses({ commit }) {
    try {
      const response = await axios.get(`/api/customer_cms/v1/billing_addresses`);
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async addBillingAddress({ commit }, payload) {
    try {
      await axios.post("/api/customer_cms/v1/billing_addresses", {
        ...payload,
      });
      return messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

};

export const mutations = {
  setBike(state, billingAddress) {
    state.billingAddress = billingAddress;
  },

  setShouldFetchBillingAddress(state, value) {
    state.shouldFetchBillingAddress = value;
  },
};
