<template lang="pug">
  .add-card
    el-form(
      :rules="checkAddCard"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="18")
          el-form-item(
            :label="$t('common.card.card_number')"
          )
            #card-number
            span(v-if="cardNumberError") {{ $t('common.card.card_number_error') }}
        el-col(:span="18")
          el-form-item(
            :label="$t('common.card.card_expiry')"
          )
            #card-expiry
            span(v-if="cardExpiryError") {{ $t('common.card.card_expiry_error') }}
        el-col(:span="18")
          el-form-item(
            :label="$t('common.card.card_cvc')"
          )
            #card-cvc
            span(v-if="cardCvcError") {{ $t('common.card.card_cvc_error') }}
      el-row.text-center
        el-button.dark-blue-btn.pop-up-btn.mt-4(
          id='add-card-button'
          v-on:click.prevent="submitFormToCreateToken()"
          :loading="loading"
          :disabled="disabled"
        ) {{ $t('common.card.add_card') }}
</template>

<script>
import { StripeElementCard  } from '@vue-stripe/vue-stripe';
import { cardViewValidations } from "@utils/formValidations/cardViewValidations";
import { mapActions, mapMutations } from "vuex";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "AddCardInfo",
  mixins: [cardViewValidations],

  data() {
    return {
      stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    
      stripe: '',
      elements: '',
      card: {
        cvc: '',
        number: '',
        expiry: ''
      },
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      // errors
      stripeError: '',
      cardCvcError: '',
      cardExpiryError: '',
      cardNumberError: '',

      intentToken: null,
      token: null,

      disabled: false,
      form: {
        first_name: "",
        last_name: "",
        country: "",
        county: "",
        city: "",
        address: "",
        postal_code: ""
      },
      loading: false,
      setObjectValues
    }
  },
  components: {
    StripeElementCard,
  },

  async mounted() {
    await this.includeStripe('js.stripe.com/v3/', async function(){
      await this.configureStripe();
    }.bind(this) );
  },

  methods: {
    ...mapMutations("dialog", ["hideAddCardDialog"]),
    ...mapMutations("card", ["setShouldFetchCards"]),
    ...mapActions("card", ["createSetupIntent"]),
    
    updateDisable: function(event) {
      this.disabled = !event.complete;
    },

    async includeStripe(url, callback) {
      const scriptTag = document.createElement('script');
      scriptTag.src = `//${url}`;
      scriptTag.onload = callback;
      document.head.appendChild(scriptTag);
    },

    async configureStripe(){
      this.stripe = Stripe( this.stripeAPIToken );

      this.elements = this.stripe.elements();

      this.cardCvc = this.elements.create('cardCvc');
      this.cardExpiry = this.elements.create('cardExpiry');
      this.cardNumber = this.elements.create('cardNumber');

      await Promise.all([
        this.mountElement(this.cardCvc, '#card-cvc'),
        this.mountElement(this.cardExpiry, '#card-expiry'),
        this.mountElement(this.cardNumber, '#card-number')
      ]);

      this.listenForErrors();
    },

    // Helper function to wrap element.mount in a Promise
    mountElement(element, selector) {
      return new Promise((resolve, reject) => {
        try {
          element.mount(selector);
          resolve(); // Mount was successful
        } catch (error) {
          reject(error); // Mount failed
        }
      });
    },

    listenForErrors() {
      const vm = this;

      this.cardNumber.addEventListener('change', (event) => {
        vm.toggleError(event);
        vm.cardNumberError = ''
        vm.card.number = event.complete ? true : false
      });
      
      this.cardExpiry.addEventListener('change', (event) => {
        vm.toggleError(event);
        vm.cardExpiryError = ''
        vm.card.expiry = event.complete ? true : false
      });
      
      this.cardCvc.addEventListener('change', (event) => {
        vm.toggleError(event);
        vm.cardCvcError = ''
        vm.card.cvc = event.complete ? true : false
      });
    },

    toggleError(event) {
      if (event.error) {
        this.stripeError = event.error.message;
      } else {
        this.stripeError = '';
      }
    },

    async submitFormToCreateToken() {
      this.loading=true;
      this.clearCardErrors();
      let valid = true;
      if (!this.card.number) {
        valid = false;
        this.cardNumberError = "Card Invalid";
      }
      if (!this.card.cvc) {
        valid = false;
        this.cardCvcError = "CVC Invalid";
      }
      if (!this.card.expiry) {
        valid = false;
        this.cardExpiryError = "Card expirat";
      }
      if (this.stripeError) {
        valid = false;
      }
      if (valid) {
        await this.loadIntent();
        await this.submitPaymentMethod();
        // this.setShouldFetchCards(true);
      }

      this.loading=false;

      location.reload();
    },

    clearCardErrors() {
      this.stripeError = ''
      this.cardCvcError = ''
      this.cardExpiryError = ''
      this.cardNumberError = ''
    },

    async loadIntent(){
      const response = await this.createSetupIntent();
      this.intentToken = response;
    },

    async submitPaymentMethod(){
      await this.stripe.confirmCardSetup(
        this.intentToken.client_secret, {
              payment_method: {
                card: this.cardNumber
              }
        }).then( function() {
          this.clearForm();
          this.hideAddCardDialog();

        }.bind(this));       
    },

    clearForm(){
      this.cardNumber.clear();
      this.cardExpiry.clear();
      this.cardCvc.clear();
    }

  },
};
</script>

<style lang="scss" scoped>

.add-card {
  padding: 24px 0;

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
