import i18n from "@i18n";

export const authenticationViewValidations = {
  data() {
    return {
      changePasswordViewValidations: {
        password: [
          {
            required: true,
            message: i18n.t("common.validations.enter_password"),
            trigger: "blur",
          },
        ],
        password_confirmation: [
          {
            required: true,
            validator: this.validateConfirmationPassword,
            trigger: "blur",
          },
        ],
      },

      forgotPasswordViewValidations: {
        email: [
          {
            required: true,
            validator: this.emailValidator,
            trigger: "blur",
          },
        ],
      },

      loginViewValidations: {
        email: [
          {
            required: true,
            validator: this.emailValidator,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: i18n.t("common.validations.enter_password"),
            trigger: "blur",
          },
        ],
      },

      signUpViewValidations: {
        last_name: [
          {
            required: true,
            message: i18n.t("common.validations.enter_last_name"),
            trigger: "blur",
          },
        ],
        first_name: [
          {
            required: true,
            message: i18n.t("common.validations.enter_first_name"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            validator: this.emailValidator,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: i18n.t("common.validations.enter_password"),
            trigger: "blur",
          },
        ],
      },

      changeEmailViewValidations: {
        email: [
          {
            required: true,
            validator: this.emailValidator,
            trigger: "blur",
          },
        ],
        email_confirmation: [
          {
            required: true,
            validator: this.emailValidator,
            trigger: "blur",
          },
        ],
      },
    };
  },
};
