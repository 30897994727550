import axios from "axios";
import Jsona from "jsona";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

const dataFormatter = new Jsona();

// TODO: search for each "franchise" occurrence before launching to production
export const state = {
  costCenter: null,
  shouldFetchCostCenters: false,
  shouldFetchCostCenterSettings: false
};

export const actions = {
  async getCostCenters({ commit }) {
    try {
      const response = await axios.get(`/api/customer_cms/v1/cost_centers`);
      return response.data;
      // {
      //   data: dataFormatter.deserialize(response.data),
      //   headers: response.headers,
      // };
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  async getCostCenter({ commit }, id) {
    try {
      const response = await axios.get(`/v1/cost_centers/${id}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getCostCenterSettings({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/settings${payload.params}&by_cost_center_id=${payload.id}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async addCostCenter({ commit }, payload) {
    try {
      await axios.post("/v1/cost_centers", {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("cost_centers.cost_center_added"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateCostCenter({ commit }, payload) {
    try {
      await axios.patch(`/v1/cost_centers/${payload.id}`, {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("cost_centers.cost_center_updated"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async deleteCostCenter({ commit }, payload) {
    try {
      await axios.delete(`/v1/cost_centers/${payload}`);
      messageUtils.showSuccess(i18n.t("cost_centers.cost_center_was_deleted"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  setCostCenter(state, costCenter) {
    state.costCenter = costCenter;
  },

  setShouldFetchCostCenters(state, value) {
    state.shouldFetchCostCenters = value;
  },

  setShouldFetchCostCenterSettings(state, value){
    state.shouldFetchCostCenterSettings = value;
  }
};
