import i18n from "@i18n";

export const smartCardViewValidations = {
  data() {
    return {
      checkAddSmartCard: {
        full_name: [
          {
            required: true,
            message: i18n.t("common.validations.shipping_address.enter_full_name"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: i18n.t("common.validations.shipping_address.enter_email"),
            trigger: "blur",
          },
        ],
        phone_number: [
          {
            required: true,
            message: i18n.t("common.validations.shipping_address.enter_phone_number"),
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: i18n.t("common.validations.shipping_address.enter_country"),
            trigger: "blur",
          },
        ],
        county: [
          {
            required: true,
            message: i18n.t("common.validations.shipping_address.enter_county"),
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: i18n.t("common.validations.shipping_address.enter_city"),
            trigger: "blur",
          },
        ],
        address_first_line: [
          {
            required: true,
            message: i18n.t("common.validations.shipping_address.enter_address_first_line"),
            trigger: "blur",
          },
        ],
        address_second_line: [
          {
            required: true,
            message: i18n.t("common.validations.shipping_address.enter_address_second_line"),
            trigger: "blur",
          },
        ],
        postal_code: [
          {
            required: true,
            message: i18n.t("common.validations.shipping_address.enter_postal_code"),
            trigger: "blur",
          },
        ],
        cost_center_id: [
          {
            required: true,
            message: i18n.t("common.validations.shipping_address.enter_cost_center"),
            trigger: "blur",
          }
        ]
      },

    };
  },
};
