<template lang="pug">
  div
    button.facebook-signup.cursor-pointer(@click="handleLogin")
      span.buttonText
        svg(xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#3578E5")
          path(d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z")
      span.buttonText Continue with Facebook
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FacebookLoginComponent",

  data() {
    return {
      fbLoaded: false
    };
  },

  async mounted() {
    this.loadFacebookSDK();
    await this.waitForFbSDK();
  },

  methods: {
    ...mapActions({
      loginWithFB: "auth/loginWithFB"
    }),

    async waitForFbSDK() {
      return new Promise((resolve) => {
        const checkFbLoad = () => {
          if (window.FB) {
            this.fbLoaded = true;
            resolve();
          } else {
            setTimeout(checkFbLoad, 300);
          }
        };
        checkFbLoad();
      });
    },

    async checkLoginState() {
      return new Promise((resolve, reject) => {
        window.FB.getLoginStatus((response) => {
          if (response.status === "connected") {
            resolve(response);
          } else {
            reject(response);
          }
        });
      });
    },

    handleLogin() {
      if (!this.fbLoaded) {
        console.error("Facebook SDK is not loaded.");
        return;
      }

      window.FB.login((response) => {
        try {
          this.checkLoginState();
          this.loginWithFB(response.authResponse.accessToken);
          this.$router.push("/docking_stations_map");
        } catch (error) {
          console.error(error);
        }
      }, { scope: "public_profile,email" });
    },

    loadFacebookSDK() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID,
          xfbml: true,
          version: "v3.2"
        });
        window.FB.AppEvents.logPageView();
      };

      const scriptId = "facebook-jssdk";
      if (!document.getElementById(scriptId)) {
        const js = document.createElement("script");
        js.id = scriptId;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        document.getElementsByTagName("head")[0].appendChild(js);
      }
    }
  }
};
</script>

<style scoped>
.facebook-signup {
  color: #031b4e;
  background: #f2f8ff;
  border: 1px solid rgba(0,105,255,.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-block;
  margin-top: 0;
  padding: 15px;
  position: inherit;
}

.buttonText {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  font-family: 'Roboto', sans-serif;
}

.facebook-signup:hover {
  background-color: #e8f0fe;
}
</style>
