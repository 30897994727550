<template lang="pug">
  .details
    el-form(
      :model="form"
      ref="ForceStopRideForm"
      :rules="forceStopRideRules"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center(:gutter="20")
        el-col(:span="16")
          el-form-item(
            prop="duration_in_minutes"
            :label="$t('label.duration_in_minutes')"
          )
            el-input-number(
              :max="8640000 / 60"
              :min="0"
              :controls="false"
              v-model="form.duration_in_minutes"
              name="duration_in_minutes"
            )
    el-row.text-center
      el-button.dark-blue-btn.pop-up-btn.mt-4(
        @click="handleForceStop('ForceStopRideForm')"
        :loading="loading"
      ) {{ $t('common.stop') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ViewForceStopRide",

  data() {
    return {
      loading: false,
      form: {
        duration_in_minutes: 0,
      },
      forceStopRideRules: {
        duration_in_minutes: [
          {
            required: true,
          }
        ]
      }
    }
  },

  watch: {
    allowDeletion() {
      this.handleForceStopRide()
    }
  },

  computed: {
    ...mapState('ride', ['ride']),
    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  created() {
    this.computeMinutesFromStartAt()
  },

  methods: {
    ...mapActions('ride', ['forceStopRide']),

    ...mapMutations("dialog", ["showViewPasswordValidationDeleteDialog", "hideViewPasswordValidationDeleteDialog", "hideForceStopRideDialog"]),
    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
    ]),
    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    computeMinutesFromStartAt() {
      if (this.ride.start_at) {
        let start_at = new Date(this.ride.start_at).getTime()
        let currentTime = new Date().getTime()
        let diffInMs = currentTime - start_at

        this.form.duration_in_minutes = Number(diffInMs / 60) // minutes conversion
      }
    },

    handleForceStop(formName) {
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          // set data for deletion
          this.setAllowDeletion(false);
          this.setDataToDelete(this.ride);

          this.showViewPasswordValidationDeleteDialog();
          this.setDialogData({
            dialogTitle: this.$t("rides.validations.stop_ride_title"),
            dialogMessage: this.$t("rides.validations.stop_ride_description"),
          });
        }
      });
    },

    handleForceStopRide() {
      if (this.allowDeletion) {
        let computedQueriesUrl = `${this.dataToDelete.id}/force_stop?password_confirmation=${this.passwordConfirmation}`;

        let payload = {
          computedQueriesUrl,
          duration_in_seconds: this.form.duration_in_minutes * 60
        }

        this.forceStopRide(payload)
            .then(() => {
              this.hideViewPasswordValidationDeleteDialog();
              this.hideForceStopRideDialog();
            })
            .catch((error) => {
              this.setAllowDeletion(false);
            })
            .finally(() => {
              this.setLoading(false);
            });
      }
    },
  },
};
</script>
