import axios from "axios";
import messageUtils from "@utils/messageUtils";
import Jsona from "jsona";
import i18n from "@i18n";

const dataFormatter = new Jsona();

export const state = {
  ride: null,
};

export const actions = {
  async getRides({ commit }, payload) {
    try {
      const response = await axios.get(`api/customer_cms/v1/rides${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getRide({ commit }, id) {
    try {
      const response = await axios.get(`api/customer_cms/v1/rides/${id}`);

      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getTransactionsForRide({ commit }, id) {
    try {
      const response = await axios.get(`/api/customer_cms/v1/rides/${id}/transactions`);
      return  dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      console.clear();
      return Promise.reject(error);
    }
  },

  // async forceStopRide({ commit }, payload) {
  //   try {
  //     await axios.post(`/v1/rides/${payload.computedQueriesUrl}`, {
  //       ...payload,
  //     });

  //     messageUtils.showSuccess(i18n.t("ride was stopped"));
  //   } catch (error) {
  //     messageUtils.showErrors(error);
  //     return Promise.reject(error);
  //   }
  // },

  async getAllRides({commit}) {
    try {
      const response = await axios.get('api/customer_cms/v1/rides');
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch(error){
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  }
};

export const mutations = {
  setRide(state, ride) {
    state.ride = ride;
  },
};
