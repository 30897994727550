<template lang="pug">
  el-table(
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='smartCards'
  )
    el-table-column(
      prop='status'
      :label="$t('common.status')"

    )
      template(slot-scope='scope')
        .status-wrapper.d-flex.align-items-center(v-if="scope.row.status == 'order_initiated'")
          span.oval.cancelled
          span {{ handleStatus(scope.row.status) }}
        .status-wrapper.d-flex.align-items-center(v-if="scope.row.status == 'order_received'")
          span.oval.pending
          span {{ handleStatus(scope.row.status) }}
        .status-wrapper.d-flex.align-items-center(v-if="scope.row.status == 'order_completed'")
          span.oval.completed
          span {{ handleStatus(scope.row.status) }}
    el-table-column(
      :label="'Persoana de contact'"
    )
      template(slot-scope='scope')
        span {{ scope.row.full_name }}
        br 
        span {{ scope.row.email }}
        br
        span {{ scope.row.phone_number }}
          
    el-table-column(
      :label="'Adresa de livrare'"
    )
      template(slot-scope='scope')
        span {{ scope.row.address_first_line + ', ' + scope.row.address_second_line }}
        br
        span {{scope.row.city + ', ' + scope.row.county + ', ' + scope.row.postal_code}}
        br
        span {{scope.row.country}}

    el-table-column(
      :label="'Centru de cost'"
    )
      template(slot-scope='scope')
        span {{ scope.row.cost_center_name }}
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
      width="360px"
    )
      template(slot-scope='scope')
        .d-flex(:key="actionsButtonKey")
          el-button.custom-table-btn-dark.mr-2(
            v-if="scope.row.status == 'order_initiated'"
            @click="handleDeleteSmartCardDialog(scope.row)"
          ) {{ $t('smart_cards.delete_order') }}
    
</template>

<script>
import { filterUtils, inBetween, MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { ticketsReports, ticketsStatuses } from "@utils/statuses";
import { routerUtils } from "@utils/router";
import { mapActions, mapMutations, mapState } from "vuex";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";

export default {
  name: "SmartCardsViewTable",
  mixins: [filterUtils, routerUtils, general],

  props: {
    smartCards: {
      type: Array
    },

    selectedStatus: {
      type: String,
    }
  },

  watch: {
    allowDeletion() {
      this.handleDeleteSmartCard();
    },
  },

  data() {
    return {
      actionsButtonKey: 0,
      loadingStartWorkBtn: [],
      loadingSolveTicketBtn: [],

      MAX_FIELD_LENGTH,
      ticketsStatuses,
      ticketsReports,
      inBetween,
      trimTableField,
      dynamicViewsConstants
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  methods: {
    ...mapActions("smartCard", ['deleteSmartCard']),

    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog"
    ]),

    ...mapMutations("smartCard", ["setSmartCard", 'setShouldFetchSmartCards']),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    handleDeleteSmartCard(){
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteSmartCard(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteSmartCardDialog(smartCard) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("smart_cards.validations.delete_title")}`,
        dialogMessage: this.$t("smart_cards.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(smartCard);
    },
    
    handleStatus(status){
      if(status == 'order_initiated')
        return 'Cerere inițiată';
      if(status == 'order_received')
        return 'Cerere înregistrată';
      if(status == 'order_completed')
        return 'Cerere închisă';
    },
  }

}
</script>
