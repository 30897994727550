export const getPermissionsByResourceName = (user_menu, name) => {
  let data;

  Object.entries(user_menu).forEach(menu => {
    if (menu[0] === name) {
      data = menu[1]
    }
  })
  return data
}
