import { getPermissionsByResourceName } from './utils'
import _ from 'lodash'

export const showItemDynamically = (user, resource_name, requiredPermissions) => {
  if (!user) return;

  const {user_menu} = user
  const permissions = getPermissionsByResourceName(user_menu, resource_name)

  if (permissions) {
    let actionsAndFiltersGrouped = () => {
      return permissions.filter !== undefined ? permissions.action.concat(permissions.filter) : permissions.action
    }

    let actionsAreaNames = actionsAndFiltersGrouped().map(perm => perm.area_name)

    /**
     * check if all requiredPermissions are present in the permissions coming from the API
     */
    return _.difference(requiredPermissions, actionsAreaNames).length === 0
  } else {
    return false;
  }
}

export const general = {
  methods: {
    showItemDynamically
  }
}
